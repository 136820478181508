<script>
  import { table_pos, nicks_list, live, units_list } from "./stores.js";
  import { onMount, onDestroy } from "svelte";

  let map = 0;
  let marquee;
  let control;
  onMount(() => {
    function initMap() {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 24.458489, lng: -102.217231 },
        zoom: 5,
      });
    }
    //initMap();
    lista_ = setInterval(lista, 2000);
    lista();

    marquee = new dynamicMarquee.Marquee(document.getElementById("marquee"), {
      rate: -100,
      upDown: false,
      startOnScreen: false,
    });
    control = dynamicMarquee.loop(marquee, [() => " Torre de control | "]);
  });
  let lista_;
  onDestroy(() => {
    clearInterval(lista_);
    control.update([]);
    control = null;
    marquee = null;
  });

  var table_pos_ = [];
  let last_pos_geo = {};
  let live_st = {};
  let loop_msg = {};
  let points_pos = {};
  let table_pos_p = [];
  function lista() {
    let table_pos_live = {};
    for (let x in $live) {
      let update_now = false;
      if (typeof last_pos_geo[$live[x].modid] == "undefined") {
        last_pos_geo[$live[x].modid] = [
          $live[x].lat,
          $live[x].lon,
          $live[x].lat + ", " + $live[x].lon,
        ];
        update_now = true;
      } else {
        if (
          get_km(
            last_pos_geo[$live[x].modid][0],
            last_pos_geo[$live[x].modid][1],
            $live[x].lat,
            $live[x].lon
          ) > 0.1
        ) {
          last_pos_geo[$live[x].modid] = [
            $live[x].lat,
            $live[x].lon,
            last_pos_geo[$live[x].modid][2],
          ];
          update_now = true;
        }
      }
      let dir_ = {
        display_name:
          typeof last_pos_geo[$live[x].modid][2] == "undefined"
            ? $live[x].lat + ", " + $live[x].lon
            : last_pos_geo[$live[x].modid][2],
      };
      let geo_name = { geo_name_: dir_.display_name };
      table_pos_live[x] = { ...$live[x], ...geo_name, level_c: 0 };
      if (update_now) {
        let mob1 = $live[x].modid;
        fetch(
          "https://geoc.omnitracs.online/reverse?lat=" +
            $live[x].lat +
            "&lon=" +
            $live[x].lon +
            "&format=json&polygon_text=1"
        ).then(async function (dir) {
          let dir_p = await dir.json();
          if (typeof dir_p.address != "undefined") {
            // road -> county -> state
            let dir_f =
              (typeof dir_p.address.suburb != "undefined" &&
              typeof dir_p.address.road == "undefined"
                ? dir_p.address.suburb + ", "
                : "") +
              (typeof dir_p.address.road != "undefined"
                ? dir_p.address.road + ", "
                : "") +
              (typeof dir_p.address.county != "undefined"
                ? dir_p.address.county + ", "
                : "") +
              (typeof dir_p.address.state != "undefined"
                ? dir_p.address.state
                : "");
            last_pos_geo[mob1][2] = dir_f;
          }
        });
      }
    }
    // Verificación de cambios
    for (let x in live_st) {
      if (typeof loop_msg[x] == "undefined") {
        loop_msg[x] = {};
      }
      if (typeof points_pos[x] == "undefined") {
        points_pos[x] = 0;
      }
      let ec1 =
        typeof $nicks_list[live_st[x].modid] != "undefined"
          ? $nicks_list[live_st[x].modid]
          : live_st[x].modid;

      if (live_st[x] != $live[x]) {
        points_pos[x] = points_pos[x] + 10;
      }
      if (live_st[x].ingnition != $live[x].ingnition) {
        points_pos[x] = points_pos[x] + 20;
        loop_msg[x]["ing"] = {
          msg:
            ec1 +
            " ignicion " +
            ($live[x].ingnition == 1 ? "encendida" : "apagada"),
          time: 60,
          nick: ec1,
        };
      }
      if (live_st[x].vel == 0 && $live[x].vel > 0) {
        points_pos[x] = points_pos[x] + 20;
        loop_msg[x]["mov"] = {
          msg: ec1 + " inicia movimiento ",
          time: 60,
          nick: ec1,
        };
      }
      if (live_st[x].vel > 0 && $live[x].vel == 0) {
        points_pos[x] = points_pos[x] + 20;
        loop_msg[x]["mov"] = {
          msg: ec1 + " sin movimiento ",
          time: 60,
          nick: ec1,
        };
      }
      if ($live[x].vel > 80) {
        points_pos[x] = points_pos[x] + 20;
        loop_msg[x]["mov"] = {
          msg: ec1 + " velocidad alta " + $live[x].vel + " Km/hr",
          time: 60,
          nick: ec1,
        };
      }
      if (
        (Date.now() / 1000 - live_st[x].last_comm > 60 * 15 &&
          live_st[x].ingnition == 1) ||
        (Date.now() / 1000 - live_st[x].last_comm > 60 * 60 &&
          live_st[x].ingnition == 0)
      ) {
        points_pos[x] = points_pos[x] + 30;
        loop_msg[x]["time"] = {
          msg:
            ec1 +
            " sin reportar por " +
            parseInt((Date.now() / 1000 - live_st[x].last_comm) / 60) +
            "min",
          time: 60,
          nick: ec1,
        };
      }
      if (points_pos[x] > 0) points_pos[x] = points_pos[x] - 1;
      if (points_pos[x] > 50) points_pos[x] = 50;
    }
    live_st = { ...$live };
    // Acomodar datos
    if (table_pos_p.length == 0) {
      for (let x in table_pos_live) {
        table_pos_p.push(table_pos_live[x]);
      }
    } else {
      for (let x in table_pos_p) {
        let last_level_c =
          points_pos[table_pos_p[x].modid] > 0
            ? points_pos[table_pos_p[x].modid] == 50
              ? 1
              : table_pos_p[x].level_c
            : 0;
        table_pos_p[x] = table_pos_live[table_pos_p[x].modid];
        table_pos_p[x].level_c = last_level_c;
      }
    }
    for (let y = 0; y < table_pos_p.length; y++) {
      for (let x = 0; x < table_pos_p.length - 1; x++) {
        if (
          points_pos[table_pos_p[x].modid] <
          points_pos[table_pos_p[x + 1].modid]
        ) {
          let par1 = table_pos_p[x];
          table_pos_p[x] = table_pos_p[x + 1];
          table_pos_p[x + 1] = par1;
          table_pos_p[x].level_c = 1;
          table_pos_p[x + 1].level_c = -1;
        }
      }
    }
    table_pos_ = [...table_pos_p];
    // Obtener mensajes y expiracion
    let msg_out_t = "";
    for (let z in loop_msg) {
      for (let w in loop_msg[z]) {
        loop_msg[z][w].time = loop_msg[z][w].time - 1;
        if (
          loop_msg[z][w].time > 0 &&
          table_pos_f.includes(loop_msg[z][w].nick)
        ) {
          msg_out_t = msg_out_t + loop_msg[z][w].msg + " | ";
        }
      }
    }
    // Update marquee
    if (control != null && msg_out_t != "") {
      control.update([() => msg_out_t]);
    } else if (control != null) {
      control.update([() => " Torre de control | "]);
    }
  }
  function compare_vel(a, b) {
    return b.vel - a.vel;
  }
  function compare_serial(a, b) {
    return b.modid - a.modid;
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  let table_pos_f = !window.localStorage.getItem("filtro_torre")
    ? []
    : JSON.parse(window.localStorage.getItem("filtro_torre"));
  let filtro_modal = false;
  let units_list_p;
  $: {
    window.localStorage.setItem("filtro_torre", JSON.stringify(table_pos_f));
  }
  $: {
    units_list_p = $units_list;
    $nicks_list;
    units_list_p.sort(units_order);
  }
  function units_order(a, b) {
    if ($nicks_list[b] < $nicks_list[a]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
</script>

{#if filtro_modal}
  <div
    class="modal fade {filtro_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {filtro_modal
      ? 'block'
      : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-sm modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content" style="min-height: 99%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">directions</i> Lista de
            unidades
            <strong />
          </h5>
        </div>
        <div class="modal-body h-100 p-0">
          <ul class="list-group mx-2">
            {#each units_list_p as item}
              <li class="list-group-item">
                <input
                  type="checkbox"
                  value={$nicks_list[item]}
                  bind:group={table_pos_f}
                />
                {typeof $nicks_list[item] != "undefined"
                  ? $nicks_list[item]
                  : item}
              </li>
            {/each}
          </ul>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-end">
            <div>
              <button
                on:click={() => {
                  filtro_modal = false;
                }}
                class="btn btn-danger big"
                type="button"
              >
                <i class="material-icons md-85">clear</i>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="d-flex flex-column w-100">
      <div class="card-header b-0 bg-dark text-white card-b">
        <button
          type="button"
          class="btn btn-info mini mx-2 my-1"
          on:click={() => {
            filtro_modal = true;
          }}
          ><i class="material-icons md-85">add_circle</i> Filtro de Unidades
        </button>
      </div>
      <div class="flex-grow-1">
        <div class="card h-100">
          <div class="card-body h-100 mb-4">
            <div class="tableFixHead h-100">
              <table class="table table-striped table-bordered">
                <thead class="thead-light">
                  <tr>
                    <!--<th scope="col">Nivel</th>-->
                    <th scope="col" />
                    <th scope="col">Económico</th>
                    <th scope="col">Velocidad</th>
                    <th scope="col">Ingnición</th>
                    <th scope="col">Última Posición</th>
                    <th scope="col">Última Posición</th>
                    <th scope="col">GPS</th>
                    <th scope="col">Red Celular</th>
                    <th scope="col">Geocerca/Zona</th>
                  </tr>
                </thead>
                <tbody>
                  {#each table_pos_ as item (item.modid)}
                    {#if typeof item.last_comm !== "undefined"}
                      {#if table_pos_f.includes($nicks_list[item.modid])}
                        <tr>
                          <!--<td>{points_pos[item.modid]}</td>-->
                          <td
                            ><i
                              style="font-weight: bold;"
                              class="material-icons md-24"
                              class:green={item.level_c > 0}
                              class:red={item.level_c < 0}
                              class:gray={item.level_c == 0}
                              >{item.level_c > 0
                                ? "arrow_upward"
                                : item.level_c < 0
                                ? "arrow_downward"
                                : "remove_circle_outline"}</i
                            ></td
                          >
                          <th scope="row"
                            >{typeof $nicks_list[item.modid] != "undefined"
                              ? $nicks_list[item.modid]
                              : item.modid}</th
                          >
                          <td
                            class:table-success={item.vel <= 80 &&
                              item.vel != 0}
                            class:table-secondary={item.vel == 0}
                            class:bg-warning={item.vel > 80 && item.vel < 100}
                            class:bg-danger={item.vel >= 100}
                            >{item.vel} Km/hr</td
                          >
                          <td
                            class:table-success={item.ingnition == 1}
                            class:table-secondary={item.ingnition == 0}
                            >{item.ingnition == "1" ? "ON" : "OFF"}</td
                          >
                          <td
                            class:table-danger={(Date.now() / 1000 -
                              item.last_comm >
                              60 * 15 &&
                              item.ingnition == 1) ||
                              (Date.now() / 1000 - item.last_comm > 60 * 60 &&
                                item.ingnition == 0)}
                            class:table-warning={Date.now() / 1000 -
                              item.last_comm <
                              60 * 15 &&
                              Date.now() / 1000 - item.last_comm > 60 * 10}
                            >{moment(new Date(item.last_comm * 1000)).format(
                              "DD/MM/YY HH:mm:ss"
                            )}</td
                          >
                          <td
                            class:table-danger={(Date.now() / 1000 -
                              item.last_comm >
                              60 * 15 &&
                              item.ingnition == 1) ||
                              (Date.now() / 1000 - item.last_comm > 60 * 60 &&
                                item.ingnition == 0)}
                            class:table-warning={Date.now() / 1000 -
                              item.last_comm <
                              60 * 15 &&
                              Date.now() / 1000 - item.last_comm > 60 * 10}
                            >{parseInt(
                              (Date.now() - item.last_comm * 1000) / 1000 / 60
                            )}min</td
                          >
                          <td
                            class:bg-danger={item.hdop > 1.5}
                            class:table-success={item.hdop <= 1.5}
                            >{item.hdop > 1.5 ? "Malo" : "OK"}</td
                          >
                          <td
                            class:bg-danger={item.rssi >= 100}
                            class:table-success={item.rssi <= 90}
                            class:bg-warning={item.rssi > 90 && item.rssi < 100}
                            >{item.rssi >= 100
                              ? "Malo"
                              : item.rssi > 90
                              ? "Regular"
                              : "Bueno"}</td
                          >
                          <td>{item.geo_name_}</td>
                        </tr>
                      {/if}
                    {/if}
                  {/each}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div id="marquee" />
      </div>
    </div>
  </div>
</main>

<style>
  .btn {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .card-b {
    border: 0px;
  }
  .tableFixHead {
    overflow-y: auto;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }
  .card-body {
    padding: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-header:first-child {
    border-radius: 0;
  }
  .h-10 {
    height: 10%;
  }
  .h-90 {
    height: 90%;
  }
  #marquee {
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 5px 0;
    font-size: 18px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #343a40;
    color: #ffffff;
    font-family: Verdana, Geneva, sans-serif;
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-95 {
    font-size: 1.8rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
