<script>
  export let socket_query;
  export let login_db;
  export let chats_db;
  export let routes_db;
  export let monitor_worker;
  export let protocol_db;
  export let user_alerts;
  import { onMount, afterUpdate } from "svelte";
  import {
    menu,
    nicks_list,
    travels,
    geos,
    geos_class,
    live,
    drivers,
    route_list_d,
    internal_com,
    recognition,
    map_ready,
    geos_map,
    poli,
    protocol,
    genesys_key,
    unit_list_ws_real,
  } from "./stores.js";

  let map,
    userAgent,
    audio,
    status_sip = false;
  let on_map,
    mount_complete = false;
  function initMap() {
    map = new google.maps.Map(document.getElementById("map_alert"), {
      center: { lat: 19.443406, lng: -99.190197 },
      zoom: 6,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    map.addListener("mouseover", function () {
      on_map = true;
    });
    map.addListener("mouseout", function () {
      on_map = false;
    });
  }
  onMount(() => {
    audio = document.getElementById("main-audio");
    userAgent = new SIP.UA({
      uri: "24291@omnitracs.tetravx.com",
      wsServers: ["wss://us1proxyd.tetravx.com:7443"],
      authorizationUser: "24291",
      password: "y%nX$g3hH",
      register: true,
      traceSip: true,
      allowLegacyNotifications: true,
      hackViaTcp: true,
      hackIpInContact: true,
      hackWssInTransport: true,
      log: {
        builtInEnabled: true,
        level: 0,
      },
      rel100: SIP.C.supported.SUPPORTED,
      userAgentString: "TetraVX Communicator",
      connectionRecoveryMaxInterval: 60,
      connectionRecoveryMinInterval: 4,
    });
    userAgent.on("registered", function (message) {
      console.log("EVENT registered", message);
      status_sip = true;
    });
    userAgent.on("unregistered", function (message) {
      console.log("EVENT unregistered", message);
      status_sip = false;
    });
    userAgent.on("registrationFailed", function (message) {
      console.log("EVENT registrationFailed", message);
      status_sip = false;
    });
    mount_complete = true;
  });

  $: {
    if ($map_ready == 1 && mount_complete) {
      initMap();
      console.log("mapa cargado");
    }
  }

  let chat_list_change = false;
  afterUpdate(() => {
    if (chat_list_change) {
      chat_list_change = false;
      chats_window.scrollTop = chats_window.scrollHeight;
    }
  });

  function time_code_s(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("s[s]");
    } else if (time < 60 * 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("m[min] s[s]");
    } else if (time < 60 * 60 * 24) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("H[h] m[min] s[s]");
    } else if (time < 60 * 60 * 24 * 90) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("D[d] H[h] m[min] s[s]");
    } else {
      return "+3 meses";
    }
  }
  function time_code(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("m[min]");
    } else if (time < 60 * 24) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("H[h] m[min]");
    } else {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("D[d] H[h] m[min]");
    }
  }
  let alert_state = false;
  var voices = window.speechSynthesis.getVoices();
  function populatevoicelist() {
    voices = window.speechSynthesis.getVoices();
  }
  speechSynthesis.onvoiceschanged = populatevoicelist;
  let unit = "Prueba",
    alert_name = "Alimentacion desconectada",
    date_on = Math.round(new Date().getTime() / 1000),
    counter_date = Math.round(new Date().getTime() / 1000) - 4,
    alert_id = "";
  let unit_id = "1363122469",
    alert_pending = 1;
  let key;
  let alerts_list = {};
  socket_query.on("alert_attended", async function (data) {
    delete alerts_list[data];
    if (unit_id == data && !alert_run) {
      cancel_alert();
    }
  });
  let alerts_scroll;
  socket_query.on("alerts_now", async function (data, data2, attended) {
    //socket_query.emit('monitor', data ,sha1(""));
    monitor_worker.postMessage({
      type: "emit2d",
      key: "monitor",
      message: data,
      sha: "",
    });
    if ($unit_list_ws_real.length > 0 && !$unit_list_ws_real.includes(data))
      return;
    if (data2 != "null" && user_alerts) {
      key = await login_db.getItem("key");
      if (attended == "") {
        alerts_list[data] = JSON.parse(data2);
        if (!alert_state && !alert_run) {
          alert_pending = JSON.parse(data2).length;
          local_alerts(data, JSON.parse(data2));
        }
      }
      if (key == attended && unit_id == data) {
        alert_pending = JSON.parse(data2).length;
        alert_runing = JSON.parse(data2).reverse();
        if (typeof alerts_scroll != "undefined") {
          alerts_scroll.scrollTop = 0;
        }
      }
      let last = JSON.parse(data2)[JSON.parse(data2).length - 1];
      if (typeof last["alerts"] != "undefined") {
        let message = last["alerts"]["message"].replace(
          "{unidad}",
          $nicks_list[data]
        );
        let fecha = moment(new Date(last.alerts_crtl.date_on * 1000)).format(
          "HH:mm"
        );
        if (call_status != 3) {
          var text_to = new SpeechSynthesisUtterance(
            message + " a las " + fecha
          );
          text_to.voice =
            voices.find((voice) => voice.name === "Google español") ||
            voices[0];
          window.speechSynthesis.speak(text_to);
        }
        console.log(message);
      }
    }
  });
  socket_query.on("alerts_now_", async function (data) {
    socket_query.emit("alerts_now", data);
  });
  let cron_reload, counter_date_ini;
  let travel = {
    id: 0,
    state: "OK",
    ign: 0,
    ruta: { name: "Desconocida", id: "" },
    org: { name: "", time: 0 },
    dest: { name: "Desconocido", id: "" },
    gps: { level: 1 },
    gsm: { level: 1, last: 1, net: "GSM" },
    monitor_crtl: 0,
    oper: "Desconocido",
    geo_stay: ["", 1, ""],
    mov: { speed: 0, time_stop: 0 },
  };
  function local_alerts(data, data2) {
    delete alerts_list[data];
    let last = data2[data2.length - 1];
    unit_id = data;
    unit = $nicks_list[unit_id];
    alert_name = last["alerts"]["name"];
    date_on = last["alerts_crtl"]["date_on"];
    counter_date = last["alerts_crtl"]["counter_date"];
    alert_id = last["alerts"]["alertid"];
    alert_runing = data2.reverse();
    counter_date_ini =
      alert_runing[alert_runing.length - 1].alerts_crtl.counter_date;

    let time_stop = 0;
    let org_time = "";
    let ign = 0;
    let geo_org = ["Desconocido", 1, ""];
    let lat_org, lng_org;
    let oper =
      typeof $travels[unit_id]["monitor_crtl"]["driver"] == "undefined"
        ? "Desconocido"
        : $travels[unit_id]["monitor_crtl"]["driver"];
    let destino =
      typeof $travels[unit_id]["monitor_crtl"]["destino"] == "undefined"
        ? "Desconocido"
        : $travels[unit_id]["monitor_crtl"]["destino"] == ""
          ? "Desconocido"
          : $travels[unit_id]["monitor_crtl"]["destino"];
    let ruta =
      typeof $travels[unit_id]["monitor_crtl"]["ruta"] == "undefined"
        ? "Desconocida"
        : $travels[unit_id]["monitor_crtl"]["ruta"] == ""
          ? "Desconocida"
          : $travels[unit_id]["monitor_crtl"]["ruta"];
    let report_tels =
      typeof last.alerts.report_tels != "undefined"
        ? last.alerts.report_tels
        : [];

    if (
      typeof $travels[unit_id]["travel"][
        $travels[unit_id]["travel"].length - 1
      ]["p"] != "undefined"
    ) {
      time_stop =
        $travels[unit_id]["travel"][$travels[unit_id]["travel"].length - 1][
          "p"
        ][
          $travels[unit_id]["travel"][$travels[unit_id]["travel"].length - 1][
            "p"
          ].length - 1
        ];
      ign = 1;
    }
    if (typeof $travels[unit_id]["travel"][0]["p"] != "undefined") {
      org_time = moment(
        new Date($travels[unit_id]["travel"][0]["ds"] * 1000)
      ).format("DD/MM/YY HH:mm");
      lat_org = $travels[unit_id]["travel"][0]["ps"][0] / 10000;
      lng_org = $travels[unit_id]["travel"][0]["ps"][1] / 10000;
      geo_org = geo_search(lat_org, lng_org);
    } else if (typeof $live[unit_id] != "undefined") {
      org_time = moment(new Date($live[unit_id]["last_comm"] * 1000)).format(
        "DD/MM/YY HH:mm"
      );
      lat_org = $live[unit_id]["lat"];
      lng_org = $live[unit_id]["lon"];
      geo_org = geo_search(lat_org, lng_org);
    }

    travel = {
      id: unit_id,
      state: "OK",
      ign: ign,
      ruta: { name: ruta, id: "" },
      org: {
        name: geo_org[0],
        id: geo_org[2],
        time: org_time,
      },
      dest: { name: destino, id: "" },
      gps: { level: 1 },
      gsm: { level: 1, last: 1, net: "GSM" },
      monitor_crtl: $travels[unit_id]["monitor_crtl"],
      oper: oper,
      report_tels: report_tels,
      geo_stay: ["", 1, ""],
      mov: {
        speed: 0,
        time_stop: time_stop.length > 4 ? time_stop[4] - time_stop[3] : 0,
      },
      protocol: last["alerts"]["protocol"],
    };
    proto_n = last["alerts"]["protocol"];
    protocolo_estado = protocolo2[proto_n]["init"];
    if (typeof last.proto_status != "undefined") {
      if (last.proto_status != "") protocolo_estado = last.proto_status;
    }

    cron();
    try {
      clearInterval(cron_reload);
    } catch (e) {
      console.log(e);
    }
    cron_reload = setInterval(cron, 1000);
    alert_state = true;
  }
  let date_on_, counter_date_, counter_date_ini_;
  function cron() {
    let now = Math.round(new Date().getTime() / 1000);
    date_on_ = now - date_on > 0 ? now - date_on : 0;
    counter_date_ = now - counter_date > 0 ? now - counter_date : 0;
    counter_date_ini_ = now - counter_date_ini > 0 ? now - counter_date_ini : 0;

    if (typeof $live[unit_id] != "undefined") {
      travel.gsm.last = $live[unit_id]["last_comm"];
      travel.mov.speed = $live[unit_id]["vel"];
      travel.gsm.level = $live[unit_id]["rssi"];
      travel.gps.level = $live[unit_id]["hdop"];
      travel.gsm.net = $live[unit_id]["network"];
      travel.ign = $live[unit_id]["ingnition"];
    }

    let time_stop = 0;
    if (
      $travels[unit_id]["travel"][$travels[unit_id]["travel"].length - 1][
        "last_status"
      ] == 1
    ) {
      time_stop =
        $travels[unit_id]["travel"][$travels[unit_id]["travel"].length - 1][
          "p"
        ][
          $travels[unit_id]["travel"][$travels[unit_id]["travel"].length - 1][
            "p"
          ].length - 1
        ];
    }
    travel.mov.time_stop =
      time_stop.length > 4 ? time_stop[4] - time_stop[3] : 0;
    travel["monitor_crtl"] = $travels[unit_id]["monitor_crtl"];
  }
  let alert_runing = [];
  let session,
    call_status = -1;
  async function accept_alert() {
    alert_state = false;
    socket_query.emit("alert_attended", unit_id, key, async (data) => {
      if (data == "ok") {
        reload = setInterval(alert_reload, 5000, unit_id, 1);
        alert_run = true;

        var text_to = new SpeechSynthesisUtterance(
          "Atendiendo alerta de unidad " + unit
        );
        text_to.voice =
          voices.find((voice) => voice.name === "Google español") || voices[0];
        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(text_to);

        if (typeof $drivers[travel.oper] != "undefined") {
          if (
            $drivers[travel.oper].chatId != "0" &&
            $drivers[travel.oper].chatId != ""
          ) {
            let chat1 = await chats_db.getItem($drivers[travel.oper].chatId);
            chat_list =
              chat1 != null ? JSON.parse(chat1)["chats"].slice(-30) : [];
            chat_list_change = true;
          }
        }
        //if (protocolo2[proto_n]["init"] == protocolo_estado) protocol_fn(0); //Accion inicial
        if (protocolo2[proto_n][protocolo_estado].run !== "") {
          protocol_fn(protocolo2[proto_n][protocolo_estado].run);
        }
        if (protocolo2[proto_n][protocolo_estado].init != "") protocol_init();
        maping(unit_id);
        geo_reverse($live[unit_id].lat, $live[unit_id].lon, unit_id);
      } else {
        var text_to = new SpeechSynthesisUtterance(
          "Unidad esta atendida por terminal " + data
        );
        text_to.voice =
          voices.find((voice) => voice.name === "Google español") || voices[0];
        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(text_to);
      }
    });
  }
  function llamar_(name, tel) {
    let text_to = new SpeechSynthesisUtterance(
      "Llamando a " + name + ", conectando, por favor espere."
    );
    text_to.voice =
      voices.find((voice) => voice.name === "Google español") || voices[0];
    window.speechSynthesis.speak(text_to);

    if (status_sip) {
      //progress Trying "Conectando con red..."
      //progress Session Progress "Sonando..."
      //accepted OK "Llamada establecida"
      //terminated "Llamada Terminó"
      session = userAgent.invite(
        "sip:" + tel + "@omnitracs.tetravx.com",
        audio,
        { media: { constraints: { audio: true, video: false } } }
      );
      call_status = 0;
      session.on("progress", function (data) {
        console.log("progress", data["reason_phrase"]);
        if (data["reason_phrase"] == "Trying") {
          call_status = 1;
          s_paso = "Cancelar Llamada";
        }
        if (data["reason_phrase"] == "Session Progress") {
          call_status = 2;
          document.getElementById("ringback").loop = true;
          document.getElementById("ringback").play();
        }
      });
      session.on("accepted", function (data) {
        console.log("accepted", data["reason_phrase"]);
        if (data["reason_phrase"] == "OK") {
          call_status = 3;
          document.getElementById("ringback").pause();
          s_paso = "Colgar";
          window.speechSynthesis.cancel();
        }
      });
      session.on("failed", function (data) {
        console.log("failed", data["reason_phrase"]);
      });
      session.on("rejected", function (data) {
        console.log("rejected", data["reason_phrase"]);
      });
      session.on("terminated", function (data) {
        console.log("terminated");
        document.getElementById("ringback").pause();
        //let text_to = new SpeechSynthesisUtterance("Llamada cancelada");window.speechSynthesis.cancel();window.speechSynthesis.speak(text_to);
        call_status = 4;
        s_paso = "Llamar de nuevo";
      });
    }
  }
  let genesys_sdk;
  loadScript("js/genesys.js");
  async function llamar(name, tel) {
    await getGenesysSipData(socket_query);
    let genesys_session;
    if (!GenesysCloudWebrtcSdk) return;

    let text_to = new SpeechSynthesisUtterance(
      "Llamando a " + name + ", conectando, por favor espere."
    );
    text_to.voice =
      voices.find((voice) => voice.name === "Google español") || voices[0];
    window.speechSynthesis.speak(text_to);

    genesys_sdk = new GenesysCloudWebrtcSdk.default({
      accessToken: $genesys_key,
      environment: "mypurecloud.com",
      allowedSessionTypes: ["softphone"],
      disableAutoAnswer: true,
    });
    genesys_sdk.on("pendingSession", (event) => {
      console.log("*** Pending session", event);
      if (!genesys_session) return;
      if (event.conversationId === genesys_session.id) {
        genesys_sdk.acceptPendingSession({
          conversationId: genesys_session.id,
        });
      }
    });
    genesys_sdk.on("sessionEnded", (event) => {
      console.log("*** Session ended", event);
    });
    genesys_sdk.on("conversationUpdate", (event) => {
      console.log("*** Conversation update", event);
      if (event.added.length == 1 && event.current.length == 1) {
        call_status = 2;
      }
      if (event.added.length == 0 && event.current.length == 1) {
        call_status = 3;
        s_paso = "Colgar";
        window.speechSynthesis.cancel();
      }
      if (event.removed.length > 0) {
        genesys_sdk.destroy();
      }
    });
    genesys_sdk.on("sessionStarted", (session) => {
      session.on("sessionState", (sessionState) => {
        console.log("*** Session state changed to", sessionState);
      });
      session.on("connectionState", (sessionState) => {
        console.log("*** Connection state changed to", sessionState);
      });
      session.on("terminated", (sessionState) => {
        console.log("*** Session terminated");
        call_status = 4;
        s_paso = "Llamar de nuevo";
      });
      call_status = 1;
      s_paso = "Cancelar Llamada";
    });

    genesys_sdk.initialize().then(async () => {
      genesys_session = await genesys_sdk.startSoftphoneSession({
        phoneNumber: "+52" + tel,
      });
      console.log("*** Session", session);
      call_status = 0;
    });
  }
  function getGenesysSipData(socket_query) {
    return new Promise((resolve, reject) => {
      socket_query.emit("genesys_sip", (data) => {
        genesys_key.set(data);
        console.log("Update gen", data);
        resolve(data);
      });
    });
  }
  async function cancel_alert() {
    alert_state = false;
    internal_com.set({ monitor_set: unit_id });
    if (Object.keys(alerts_list).length > 0) {
      local_alerts(
        Object.keys(alerts_list)[0],
        alerts_list[Object.keys(alerts_list)[0]]
      );
    }
  }
  function alert_reload(unit, state) {
    socket_query.emit("alert_reload", unit_id, state, (data) => {});
  }

  let alert_run = false;
  let reload;
  function cancel_run() {
    try {
      clearInterval(reload);
    } catch (e) {
      console.log(e);
    }
    socket_query.emit("alert_reload", unit_id, 2, (data) => {});
    alert_run = false;
    try {
      //session.terminate();
      if (genesys_sdk) genesys_sdk.destroy();
    } catch (e) {
      console.log(e);
    }
    s_paso = "Llamando...";
    call_status = -1;
    protocolo_estado = protocolo2[proto_n]["init"];
    cancel_alert();
  }

  function geo_search(lat, lng) {
    let box_size = 0.0001797 * 10; // 200 metros por celula
    let p = Math.floor((lat - 14.5408) / box_size);
    let q = Math.floor((lng + 118.2689) / box_size);
    let key_close_list = [
      p + "_" + q,
      p + "_" + (q + 1),
      p + "_" + (q - 1),
      p + 1 + "_" + q,
      p - 1 + "_" + q,
      p + 1 + "_" + (q + 1),
      p - 1 + "_" + (q - 1),
      p + 1 + "_" + (q - 1),
      p - 1 + "_" + (q + 1),
    ];
    let geos_arround = [];
    for (let w in key_close_list) {
      if ($geos_map[key_close_list[w]]) {
        for (let s in $geos_map[key_close_list[w]]) {
          if (!geos_arround.includes($geos_map[key_close_list[w]][s])) {
            geos_arround.push($geos_map[key_close_list[w]][s]);
          }
        }
      }
    }
    let found = false;
    for (let x in $poli) {
      if (geos_arround.includes(x)) {
        if (dentro([lat, lng], $poli[x][0])) {
          return [$poli[x][1], 2, x];
        }
      }
    }
    for (let x in $geos) {
      // filtro
      if (geos_arround.includes($geos[x][0])) {
        for (let y in $geos[x][1]) {
          let lat_ = $geos[x][1][y][0];
          let lng_ = $geos[x][1][y][1];
          if (get_km(lat, lng, lat_, lng_) <= 0.13) {
            if (typeof $geos_class[$geos[x][0]] == "undefined") {
              return [
                "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                1,
                $geos[x][0],
              ];
            } else {
              return [
                $geos_class[$geos[x][0]][0],
                $geos_class[$geos[x][0]][1],
                $geos[x][0],
              ];
            }
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    return ["Geocerca_automatica_", 1, ""];
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  function dentro(point, vs) {
    let x = point[0],
      y = point[1];
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0],
        yi = vs[i][1];
      let xj = vs[j][0],
        yj = vs[j][1];
      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }
  let chat_to_send = "";
  let chat_list = []; //[{message:"Hola soy el despacho",type:1,date:1583505182},{message:"Hola soy el chofer",type:0,date:1583506182},{message:"Ok",type:1,date:1583507182},{message:"Donde andas?",type:1,date:1583507182}];
  function send_chat() {
    if (typeof $drivers[travel.oper] != "undefined") {
      if (
        $drivers[travel.oper].chatId != "0" &&
        $drivers[travel.oper].chatId != ""
      ) {
        if (chat_to_send != "") {
          socket_query.emit(
            "chat_send",
            $drivers[travel.oper].chatId,
            chat_to_send,
            (data) => {
              chat_to_send = "";
              if (data == "ok") {
                console.log("chat OK");
              }
            }
          );
        }
      }
    }
  }
  let chats_window;
  let s_paso = "En espera...";
  socket_query.on("chats_", async function (data, data2) {
    if (data != null) {
      let chat1 = await chats_db.getItem(data.toString());
      if (chat1 != null) {
        chat1 = JSON.parse(chat1);
        chat1.chats.push(JSON.parse(data2));
        console.log(data);
        try {
          await chats_db.setItem(data.toString(), JSON.stringify(chat1));
        } catch (e) {
          console.log("chats", e);
        }
      }
      if (typeof $drivers[travel.oper] != "undefined") {
        if (data == $drivers[travel.oper].chatId) {
          chat_list.push(JSON.parse(data2));
          chat_list = [...chat_list];
          chat_list_change = true;
        }
      }
    }
  });
  let protocolo2 = [
    {
      name: "Alfa",
      init: "-1",
      "-1": {
        mensaje: "Llamando a operador",
        actions: ["0", "0", "0"],
        options: ["", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: 0,
        init: "",
      },
      "0": {
        mensaje:
          "Ejecuta protocolo Alfa, Llama al operador, pregunta por la clave amago y evalúa si es una <span style=''><strong>Respuesta correcta</strong></span>, <span style='color:#df1616'><strong>Respuesta incorrecta</strong></span> o <span style=''><strong>No responde</strong></span>, este es el <strong>Primer intento</strong>.",
        actions: ["99", "2", "1"],
        options: ["Respuesta correcta", "Respuesta incorrecta", "No responde"],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "1": {
        mensaje:
          "Llama al operador nuevamente, pregunta por la clave amago y evalúa, este es el <strong>Segundo intento</strong>.",
        actions: ["99", "2", "2"],
        options: ["Respuesta correcta", "Respuesta incorrecta", "No responde"],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2": {
        mensaje:
          "Bloquea la unidad para evitar su desplazamiento, o cierra la alerta en caso de que la unidad este segura.",
        actions: ["99", "3", "99"],
        options: ["Cerrar alerta", "Bloquear", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2a": {
        mensaje:
          "Unidad desbloqueada, confirma que la operación es segura y cierra la alerta.",
        actions: ["99", "3", "99"],
        options: ["Cerrar alerta", "Bloquear", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "3": {
        mensaje:
          "Unidad bloqueada, realiza un operativo para localizar la unidad.",
        actions: ["2", "3", "4"],
        options: ["Desbloquear", "", "Lanzar Operativo"],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "3a": {
        mensaje: "Unidad bloqueada, desbloquea la unidad para cerrar alerta.",
        actions: ["2a", "3", "4"],
        options: ["Desbloquear", "", "Lanzar nuevo Operativo"],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "4": {
        mensaje: "Operativo Iniciado, notifica cuando el operativo termine.",
        actions: ["3a", "4", "4"],
        options: ["Operativo terminado", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99": {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "100": {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
    {
      name: "Unidad detenida (Beta)",
      init: "0",
      "0": {
        mensaje: "",
        actions: ["1", "0", "0"],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "1": {
        mensaje:
          "Se envió un mensaje que avisa sobre su detención. <strong>¿El operador conestó el mensaje?</strong>",
        actions: ["2", "3", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2": {
        mensaje: "¿Que tipo de parada reporta?",
        actions: ["2x", "2y", "99"],
        options: ["Parada larga", "Parada corta", "Unidad en movimiento"],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2x": {
        mensaje: "Selecciona la razon",
        actions: ["2a", "2a", "2a", "2a"],
        options: [
          "Cliente",
          "Domicilio de operador",
          "Falla Mecanica",
          "Pernocta",
        ],
        function: ["", "", "", ""],
        btn_color: ["success", "success", "success", "success"],
        btn_size: ["sm", "sm", "sm", "sm"],
        run: "",
        init: "",
      },
      "2y": {
        mensaje: "Selecciona la razon",
        actions: ["2c", "2c", "2c", "2c", "2c"],
        options: [
          "Reten ",
          "Casetas",
          "Carga de combustible",
          "Trafico",
          "Alimentos",
        ],
        function: ["", "", "", "", ""],
        btn_color: ["success", "success", "success", "success", "success"],
        btn_size: ["sm", "sm", "sm", "sm", "sm"],
        run: "",
        init: "",
      },
      "2a": {
        mensaje: "Envío de paro de motor preventivo",
        actions: ["2b", "", ""],
        options: ["Enviar paro de motor", "", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2b": {
        mensaje: "Poner alerta en espera.",
        actions: ["101", "", ""],
        options: ["Alerta en espera", "", ""],
        function: ["pause_alert", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2c": {
        mensaje: "Poner alerta en espera.",
        actions: ["102", "", ""],
        options: ["Alerta en espera", "", ""],
        function: ["pause_alert", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "3": {
        mensaje: "Llama al operador.",
        actions: ["3a", "", ""],
        options: ["Llamar a Operador", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "3a": {
        mensaje:
          "Espere a que la llamada inicie y responda <strong>¿Operador conestó llamada?</strong>",
        actions: ["2", "4", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "resume_call_oper",
      },
      "4": {
        mensaje: "Llama a matriz",
        actions: ["4a", "", ""],
        options: ["Llamar a matriz", "", ""],
        function: ["call_matriz", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "4a": {
        mensaje: "¿Matriz conestó?",
        actions: ["2", "operativo", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "resume_call_matriz",
      },
      operativo: {
        mensaje: "Informar a matriz.",
        actions: ["operativo_inicio", "", ""],
        options: ["Informar a matriz(Se envía correo).", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      operativo_inicio: {
        mensaje:
          "Inicia operativo. Y envía la alerta a espera. <strong>¿Cual es el estado del operativo?</strong>",
        actions: ["robo", "operativo_inicio", ""],
        options: ["Operativo terminado.", "Operativo en proceso.", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      robo: {
        mensaje: "¿Robo real?",
        actions: ["99", "99", ""],
        options: ["Si", "No", ""],
        function: ["", "security_off", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99": {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "100": {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "101": {
        mensaje:
          "Alerta en espera. ¿Operador reporta que unidad quiere reanudad viaje?",
        actions: ["101a", "101", ""],
        options: ["Si", "No", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "101a": {
        mensaje: "Desbloquear Unidad",
        actions: ["99", "", ""],
        options: ["Desbloquear unidad", "", ""],
        function: ["security_off", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "102": {
        mensaje: "Alerta en espera. ¿La unidad ya esta en movimiento?",
        actions: ["99", "102", ""],
        options: ["Si", "No", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
    {
      name: "Fuera de Ruta (Beta)",
      init: "0",
      "0": {
        mensaje: "",
        actions: ["1a", "0", "0"],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "1a": {
        mensaje: "Llama al operador.",
        actions: ["2a", "", ""],
        options: ["Llamar a Operador", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2a": {
        mensaje:
          "Espere a que la llamada inicie y responda <strong>¿Operador conestó llamada?</strong>",
        actions: ["2", "2", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "resume_call_oper",
      },
      "1": {
        mensaje: "Envío de paro de motor preventivo",
        actions: ["operativo", "", ""],
        options: ["Enviar paro de motor", "", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2": {
        mensaje: "Llama a matriz",
        actions: ["3", "", ""],
        options: ["Llamar a matriz", "", ""],
        function: ["call_matriz", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "3": {
        mensaje: "¿Matriz conestó?",
        actions: ["3a", "1", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "resume_call_matriz",
      },
      "3a": {
        mensaje:
          "Informar a matriz sobre situación. <strong>¿Cual es la razon del desvío?</strong>",
        actions: ["3a", "1", "nueva_ruta"],
        options: [
          "En espera de respuesta",
          "Operador no responde/Situacion de riesgo",
          "Nuevo destino/ruta autorizada.",
        ],
        function: ["pause_alert", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      nueva_ruta: {
        mensaje:
          "Programa una nueva ruta/destino de forma temporal. <strong>¿Ya creaste la ruta?</strong>.",
        actions: ["nueva_ruta", "habilitar", ""],
        options: ["Alerta en espera", "Ruta Creada", ""],
        function: ["pause_alert", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      habilitar: {
        mensaje: "Habilita la unidad.",
        actions: ["99", "", ""],
        options: ["Habilitatar unidad.", "", ""],
        function: ["security_off", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      operativo: {
        mensaje: "Informar a matriz.",
        actions: ["operativo_inicio", "", ""],
        options: ["Informar a matriz (Se envía correo).", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      operativo_inicio: {
        mensaje:
          "Inicia operativo. Y envía la alerta a espera. <strong>¿Cual es el estado del operativo?</strong>",
        actions: ["robo", "operativo_inicio", ""],
        options: ["Operativo terminado.", "Operativo en proceso.", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      robo: {
        mensaje: "¿Robo real?",
        actions: ["99", "99", ""],
        options: ["Si", "No", ""],
        function: ["", "security_off", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99": {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "100": {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
    {
      name: "Unidad sin señal (Beta)",
      init: "0",
      "0": {
        mensaje: "Llama al operador.",
        actions: ["1", "", ""],
        options: ["Llamar a Operador", "", ""],
        function: ["call_oper", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "1": {
        mensaje:
          "Espere a que la llamada inicie y responda <strong>¿Operador conestó llamada?</strong>",
        actions: ["6", "3", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "resume_call_oper",
      },
      "3": {
        mensaje: "Envío de paro de motor preventivo",
        actions: ["4", "", ""],
        options: ["Enviar paro de motor", "", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "4": {
        mensaje: "Llama a matriz",
        actions: ["5", "", ""],
        options: ["Llamar a matriz", "", ""],
        function: ["call_matriz", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "5": {
        mensaje: "¿Matriz conestó?",
        actions: ["6", "operativo", ""],
        options: ["Si", "No", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "resume_call_matriz",
      },
      "6": {
        mensaje: "<strong>¿Cual es el estado de la unidad?</strong>",
        actions: ["operativo", "6", "99"],
        options: [
          "Unidad en situacion de riesgo",
          "Unidad en movimiento/estacionada en lugar seguro",
          "Unidad tiene señal",
        ],
        function: ["security_command", "pause_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      operativo: {
        mensaje: "Informar a matriz.",
        actions: ["operativo_inicio", "", ""],
        options: ["Informar a matriz(Se envía correo).", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      operativo_inicio: {
        mensaje:
          "Inicia operativo. Y envía la alerta a espera. <strong>¿Cual es el estado del operativo?</strong>",
        actions: ["robo", "operativo_inicio", ""],
        options: ["Operativo terminado.", "Operativo en proceso.", ""],
        function: ["", "pause_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      robo: {
        mensaje: "¿Robo real?",
        actions: ["99", "99", ""],
        options: ["Si", "No", ""],
        function: ["", "security_off", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99": {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "100": {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
    {
      name: "Alerta manual (Beta)",
      init: "0",
      "0": {
        mensaje: "",
        actions: ["1", "0", "0"],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "1": {
        mensaje: "Envío de paro de motor preventivo",
        actions: ["2", "", ""],
        options: ["Enviar paro de motor", "", ""],
        function: ["security_command", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "2": {
        mensaje: "Poner alerta en espera.",
        actions: ["3", "", ""],
        options: ["Alerta en espera", "", ""],
        function: ["pause_alert", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "3": {
        mensaje: "Habilita la unidad.",
        actions: ["99", "", ""],
        options: ["Habilitatar unidad.", "Alerta en espera", ""],
        function: ["security_off", "pause_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "99": {
        mensaje:
          "Alerta atendida, puedes enviar una notificación instantánea o cerrar la alerta sin notificación.",
        actions: ["100", "100", ""],
        options: ["Cerrar con notificacion", "Cerrar sin notificación", ""],
        function: ["close_alert_notif", "close_alert", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
      "100": {
        mensaje: "",
        actions: ["", "", ""],
        options: ["", "", ""],
        function: ["", "", ""],
        btn_color: ["success", "danger", "warning"],
        btn_size: ["lg", "lg", "lg"],
        run: "",
        init: "",
      },
    },
  ];

  let protocolo_estado = "0";
  let proto_n = 0;
  let last_call = ["", ""];
  function run_g(i) {
    protocol_fn(i);
  }
  async function protocol_fn(n) {
    if (protocolo2[proto_n][protocolo_estado].function[n] == "close_alert") {
      socket_query.emit("alert_cero", unit_id, (data) => {
        if (data == "ok") {
          send_mail = false;
          unit_to_comment = unit_id;
          date_to_comment = date_on;
          id_to_commnet = alert_id;
          close_alert_modal = true;

          console.log("alert_cero ok");
          protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];

          try {
            clearInterval(reload);
          } catch (e) {
            console.log(e);
          }
          socket_query.emit("alert_reload", unit_id, 2, (data) => {});
          alert_run = false;
          try {
            //session.terminate();
            if (genesys_sdk) genesys_sdk.destroy();
          } catch (e) {
            console.log(e);
          }
          s_paso = "Llamando...";
          call_status = -1;
          protocolo_estado = protocolo2[proto_n].init;
          selected = -1;
        }
      });
      cancel_alert();
    } else if (
      protocolo2[proto_n][protocolo_estado].function[n] == "close_alert_notif"
    ) {
      socket_query.emit("alert_cero", unit_id, (data) => {
        if (data == "ok") {
          send_mail = true;
          unit_to_comment = unit_id;
          date_to_comment = date_on;
          id_to_commnet = alert_id;
          close_alert_modal = true;

          console.log("alert_cero ok");
          protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];

          try {
            clearInterval(reload);
          } catch (e) {
            console.log(e);
          }
          socket_query.emit("alert_reload", unit_id, 2, (data) => {});
          alert_run = false;
          try {
            //session.terminate();
            if (genesys_sdk) genesys_sdk.destroy();
          } catch (e) {
            console.log(e);
          }
          s_paso = "Llamando...";
          call_status = -1;
          protocolo_estado = protocolo2[proto_n].init;
          selected = -1;
        }
      });
      cancel_alert();
    } else if (
      protocolo2[proto_n][protocolo_estado].function[n] == "call_oper"
    ) {
      if (typeof $drivers[travel.oper] != "undefined") {
        if (
          $drivers[travel.oper].chatId != "0" &&
          $drivers[travel.oper].chatId != ""
        ) {
          chat_to_send =
            "Su unidad, " +
            unit +
            " está bajo revisión de seguridad por incidencias presentadas. Pronto recibirá llamada.";
          send_chat();
          chat_list_change = true;
        }
      }
      if (typeof $drivers[travel.oper] != "undefined") {
        if ($drivers[travel.oper].tel != "") {
          llamar(
            "operador " + $drivers[travel.oper].name,
            $drivers[travel.oper].tel
          );
          last_call = [
            "operador " + $drivers[travel.oper].name,
            $drivers[travel.oper].tel,
          ];
          call_status = 0;
        } else {
          call_status = -1;
        }
      }
      protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];
      send_proto(protocolo_estado);
      if (protocolo2[proto_n][protocolo_estado].run !== "") {
        protocol_fn(protocolo2[proto_n][protocolo_estado].run);
      }
    } else if (
      protocolo2[proto_n][protocolo_estado].function[n] == "call_matriz"
    ) {
      if (typeof travel.report_tels != "undefined") {
        if (travel.report_tels != []) {
          llamar("matriz ", travel.report_tels[0]);
          last_call = ["matriz ", travel.report_tels[0]];
          call_status = 0;
        } else {
          call_status = -1;
        }
      }
      protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];
      send_proto(protocolo_estado);
      if (protocolo2[proto_n][protocolo_estado].run !== "") {
        protocol_fn(protocolo2[proto_n][protocolo_estado].run);
      }
    } else if (
      protocolo2[proto_n][protocolo_estado].function[n] == "msg_oper"
    ) {
      if (typeof $drivers[travel.oper] != "undefined") {
        if (
          $drivers[travel.oper].chatId != "0" &&
          $drivers[travel.oper].chatId != ""
        ) {
          chat_to_send =
            "Su unidad, " +
            unit +
            " está bajo revisión de seguridad por incidencias presentadas. Pronto recibirá llamada.";
          send_chat();
          let chat1 = await chats_db.getItem($drivers[travel.oper].chatId);
          chat_list = chat1 != null ? JSON.parse(chat1)["chats"] : [];
          chat_list_change = true;
        }
      }
      protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];
      send_proto(protocolo_estado);
      if (protocolo2[proto_n][protocolo_estado].run !== "") {
        protocol_fn(protocolo2[proto_n][protocolo_estado].run);
      }
    } else if (
      protocolo2[proto_n][protocolo_estado].function[n] == "security_command"
    ) {
      let text_to = new SpeechSynthesisUtterance("Unidad bloqueada.");
      text_to.voice =
        voices.find((voice) => voice.name === "Google español") || voices[0];
      window.speechSynthesis.speak(text_to);
      protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];
      send_proto(protocolo_estado);
      if (protocolo2[proto_n][protocolo_estado].run !== "") {
        protocol_fn(protocolo2[proto_n][protocolo_estado].run);
      }
    } else if (
      protocolo2[proto_n][protocolo_estado].function[n] == "security_off"
    ) {
      let text_to = new SpeechSynthesisUtterance("Unidad desbloqueada.");
      text_to.voice =
        voices.find((voice) => voice.name === "Google español") || voices[0];
      window.speechSynthesis.speak(text_to);
      protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];
      send_proto(protocolo_estado);
      if (protocolo2[proto_n][protocolo_estado].run !== "") {
        protocol_fn(protocolo2[proto_n][protocolo_estado].run);
      }
    } else if (
      protocolo2[proto_n][protocolo_estado].function[n] == "pause_alert"
    ) {
      let text_to = new SpeechSynthesisUtterance("Alerta en espera.");
      text_to.voice =
        voices.find((voice) => voice.name === "Google español") || voices[0];
      window.speechSynthesis.speak(text_to);

      alert_reload(unit_id, 0);
      try {
        clearInterval(reload);
      } catch (e) {
        console.log(e);
      }
      alert_run = false;
      try {
        //session.terminate();
        if (genesys_sdk) genesys_sdk.destroy();
      } catch (e) {
        console.log(e);
      }
      s_paso = "En espera";
      call_status = -1;
      selected = -1;

      protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];
      send_proto(protocolo_estado);
      if (protocolo2[proto_n][protocolo_estado].run !== "") {
        protocol_fn(protocolo2[proto_n][protocolo_estado].run);
      }
    } else if (protocolo2[proto_n][protocolo_estado].function[n] === "") {
      protocolo_estado = protocolo2[proto_n][protocolo_estado].actions[n];
      send_proto(protocolo_estado);
      if (protocolo2[proto_n][protocolo_estado].run !== "") {
        protocol_fn(protocolo2[proto_n][protocolo_estado].run);
      }
    }
    console.log("protocolo_estado ", protocolo_estado);
  }
  async function protocol_init() {
    if (protocolo2[proto_n][protocolo_estado].init == "resume_call_oper") {
      if (typeof $drivers[travel.oper] != "undefined") {
        if ($drivers[travel.oper].tel != "") {
          last_call = [
            "operador " + $drivers[travel.oper].name,
            $drivers[travel.oper].tel,
          ];
          call_status = 4;
          s_paso = "Llamar de nuevo";
        } else {
          call_status = -1;
        }
      }
    } else if (
      protocolo2[proto_n][protocolo_estado].init == "resume_call_matriz"
    ) {
      if (typeof travel.report_tels != "undefined") {
        if (travel.report_tels != []) {
          last_call = ["matriz ", travel.report_tels[0]];
          call_status = 4;
          s_paso = "Llamar de nuevo";
        } else {
          call_status = -1;
        }
      }
    }
  }
  function send_proto(estado) {
    socket_query.emit(
      "proto_status",
      unit_id,
      date_on,
      alert_id,
      estado,
      (data) => {
        if (data == "ok") {
          console.log("proto_status OK");
        } else {
          console.log("proto_status Error");
        }
      }
    );
  }
  function call_run() {
    if (s_paso == "Cancelar Llamada" && call_status > 0) {
      try {
        //session.terminate();
        if (genesys_sdk) genesys_sdk.destroy();
      } catch (e) {
        console.log(e);
      }
    } else if (s_paso == "Colgar") {
      try {
        //session.terminate();
        if (genesys_sdk) genesys_sdk.destroy();
      } catch (e) {
        console.log(e);
      }
    } else if (s_paso == "Llamar de nuevo") {
      llamar(last_call[0], last_call[1]);
      console.log("Llamando", last_call[0], last_call[1]);
      s_paso = "Llamando...";
    }
  }

  let m_inicio, m_fin, recorrido;
  let m_alerts = [];
  let selected = -1;
  let selected_ = -1;
  let live_ = {};

  $: {
    if (selected != -1) {
      if ($live[selected] != live_) {
        selected_ = selected;
        maping(selected);
        geo_reverse($live[selected].lat, $live[selected].lon, selected);
      }
    }
  }

  let show_drivers = -1;
  let recorrido_ruta, m_inicio_ruta, m_fin_ruta;
  async function maping(mobid) {
    let bounds = new google.maps.LatLngBounds();
    let zIndex = 0;
    //console.log($travels[mobid],$live[mobid]);

    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    if (typeof $travels[mobid].travel[0].p != "undefined") {
      m_inicio = new google.maps.Marker({
        position: {
          lat: $travels[mobid].travel[0]["ps"][0] / 10000,
          lng: $travels[mobid].travel[0]["ps"][1] / 10000,
        },
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-start.png",
        zIndex: zIndex,
      });
    }
    zIndex++;
    if (typeof m_fin != "undefined") {
      m_fin.setMap(null);
    }
    m_fin = new google.maps.Marker({
      position: { lat: $live[mobid].lat, lng: $live[mobid].lon },
      map: map,
      animation: google.maps.Animation.DROP,
      //icon: image,
      icon: {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 6, // Escala del icono (tamaño de la flecha)
        rotation: $live[mobid].heading, // Aplica el 'heading' como rotación
        fillColor:
          $live[mobid].ingnition == 0
            ? "#FAFBFA"
            : $live[mobid].vel == 0
              ? "#D580D5"
              : "#80C080",
        fillOpacity: 1,
        strokeWeight: 2,
        labelOrigin: new google.maps.Point(-3, -3),
      },
      zIndex: zIndex,
      label: {
        text:
          typeof $nicks_list[mobid] != "undefined" ? $nicks_list[mobid] : mobid,
        color: "#212121",
        fontSize: "13px",
        fontWeight: "bold",
        className: "custom-label",
      },
    });

    let coordenada_ruta = [];
    if (typeof $travels[mobid].monitor_crtl.ruta != "undefined") {
      if ($travels[mobid].monitor_crtl.ruta != "") {
        let route;
        try {
          route = await routes_db.getItem($travels[mobid].monitor_crtl.ruta);
        } catch (e) {
          console.log("Error leyendo routa", e);
        }
        if (route != null) {
          let lat_org = route["pos_start"][0];
          let lng_org = route["pos_start"][1];
          for (let x in route["pos"]) {
            let lat = (lat_org - route["pos"][x][0]) / 10000;
            let lng = (lng_org - route["pos"][x][1]) / 10000;
            coordenada_ruta.push({ lat: lat, lng: lng });
          }
        }
      }
    }
    zIndex++;
    if (typeof recorrido_ruta != "undefined") {
      recorrido_ruta.setMap(null);
    }
    if (coordenada_ruta.length > 0) {
      recorrido_ruta = new google.maps.Polyline({
        path: coordenada_ruta,
        geodesic: true,
        strokeColor: "#2196f3",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_inicio_ruta != "undefined") {
      m_inicio_ruta.setMap(null);
    }
    if (typeof $travels[mobid].travel[0].p != "undefined") {
      m_inicio_ruta = new google.maps.Marker({
        position: coordenada_ruta[0],
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerA.png",
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_fin_ruta != "undefined") {
      m_fin_ruta.setMap(null);
    } //http://www.google.com/mapfiles/
    if (typeof $travels[mobid].travel[0].p != "undefined") {
      m_fin_ruta = new google.maps.Marker({
        position: coordenada_ruta[coordenada_ruta.length - 1],
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerB.png",
        zIndex: zIndex,
      });
    }

    let coordenada = [];
    for (let x in $travels[mobid].travel) {
      if (typeof $travels[mobid].travel[x].p != "undefined") {
        for (let y in $travels[mobid].travel[x].p) {
          let lat =
            ($travels[mobid].travel[x]["ps"][0] -
              $travels[mobid].travel[x]["p"][y][0]) /
            10000;
          let lng =
            ($travels[mobid].travel[x]["ps"][1] -
              $travels[mobid].travel[x]["p"][y][1]) /
            10000;
          coordenada.push({ lat: lat, lng: lng });
          bounds.extend({ lat: lat, lng: lng });
        }
      }
    }
    coordenada.push({ lat: $live[mobid].lat, lng: $live[mobid].lon });
    zIndex++;
    if (typeof recorrido != "undefined") {
      recorrido.setMap(null);
    }
    if (coordenada.length > 0) {
      recorrido = new google.maps.Polyline({
        path: coordenada,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeColor: "#b71c1c",
            },
            offset: "100px",
            repeat: "100px",
          },
        ],
      });
    }
    if (!on_map) {
      map.setZoom(16);
      map.panTo({ lat: $live[mobid].lat, lng: $live[mobid].lon });
    }
    if (selected == mobid && selected_ != mobid) {
      if (coordenada.length > 1 && show_drivers != mobid) {
        map.fitBounds(bounds);
      }
      selected_ = mobid;
    } else if (selected == mobid && selected_ == mobid) {
      selected_ = -1;
    }
    selected = mobid;
    live_ = $live[mobid];

    // Map Alerts
    var image_icon = {
      url: "img/markerAlt.png",
      scaledSize: new google.maps.Size(40, 40),
      labelOrigin: new google.maps.Point(20, -10),
    };
    for (let x in m_alerts) {
      if (typeof m_alerts[x] != "undefined") {
        m_alerts[x].setMap(null);
      }
    }
    m_alerts = [];
    for (let x in alert_runing) {
      m_alerts[x] = new google.maps.Marker({
        position: {
          lat: alert_runing[x].pos_data.lat,
          lng: alert_runing[x].pos_data.lon,
        },
        map: map,
        animation: google.maps.Animation.DROP,
        icon: image_icon,
        zIndex: 0,
        label: {
          text: alert_runing[x].alerts.name,
          color: "#212121",
          fontSize: "10px",
          fontWeight: "bold",
        },
      });
    }
  }
  let rev_lat = 0,
    rev_lon = 0,
    rev_last = 0,
    rev_dir = [];
  async function geo_reverse(lat, lon, revid) {
    let now = Math.round(new Date().getTime() / 1000);
    if (get_km(lat, lon, rev_lat, rev_lon) > 0.3 && now - rev_last > 3) {
      rev_lat = lat;
      rev_lon = lon;
      rev_last = now;
      let dir = await fetch(
        "https://nominatim.openstreetmap.org/reverse?lat=" +
          lat +
          "&lon=" +
          lon +
          "&format=json&polygon_text=1"
      );
      let dir_ = await dir.json();
      console.log(dir_);
      if (typeof dir_.display_name != "undefined") {
        rev_dir[revid] = dir_.display_name;
      } else rev_dir[revid] = "";
    } else if (get_km(lat, lon, rev_lat, rev_lon) > 0.3) {
      rev_dir[revid] = "";
    }
  }
  let close_alert_modal = false;
  let alert_end_reason = 0,
    alert_end_comment = "";
  let date_to_comment = 0;
  let id_to_commnet = "";
  let unit_to_comment = "";
  let send_mail = false;
  function send_alert_comment() {
    socket_query.emit(
      "alert_comment",
      unit_to_comment,
      date_to_comment,
      id_to_commnet,
      alert_end_comment,
      parseInt(alert_end_reason),
      send_mail,
      (data) => {
        if (data == "ok") {
          close_alert_modal = false;
          alert_end_reason = 0;
          alert_end_comment = "";
        }
      }
    );
  }
  $: {
    if ($recognition == "atender") {
      if (alert_state) accept_alert();
    }
    if ($recognition == "omitir") {
      if (alert_state) cancel_alert();
    }
    if ($recognition == "dejar de atender") {
      if (alert_run) cancel_run();
    }
  }
  socket_query.on("protocol", async function (data) {
    if (data != null) {
      protocolo2 = JSON.parse(data);
      protocol.set(protocolo2);
      try {
        protocol_db.setItem("protocol", data);
      } catch (e) {
        console.log("protocol", e);
      }
    }
  });
  protocol_db.getItem("protocol", function (err, data) {
    if (data != null) {
      protocolo2 = JSON.parse(data);
      protocol.set(protocolo2);
    }
  });

  function fielSender(e) {
    let file = e.target.files[0];
    document.getElementById("fileInput").value = "";
    if (file) {
      const reader = new FileReader();

      // Cuando el archivo se haya leído
      reader.onload = () => {
        const base64Data = reader.result; // Obtener solo la parte Base64
        const fileType = file.type; // Tipo de archivo
        const fileName = file.name; // Nombre del archivo

        // Crear un objeto con la información
        const payload = {
          fileName,
          fileType,
          base64Data,
        };
        if (
          fileType.search("image/") == -1 &&
          fileType.search("application/pdf") == -1
        ) {
          saved_type = "alert-danger";
          saved_text = "Solo se permiten imágenes o PDF";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
          return;
        }
        socket_query.emit(
          "chat_send",
          $drivers[travel.oper].chatId,
          base64Data,
          (data) => {
            //chat_to_send="";
            if (data == "ok") {
              console.log("chat OK");
            }
          }
        );
        console.log("Archivo enviado:", payload);
      };

      // Leer el archivo como Data URL (Base64)
      reader.readAsDataURL(file);
    } else {
      alert("Por favor selecciona un archivo primero");
    }
  }
  let isRecording = false;
  let mediaRecorder;
  let audioChunks = [];
  let audioStream;
  function run_record() {
    if (!mediaRecorder) {
      // Solicitar permisos para el micrófono al hacer clic
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          audioStream = stream;
          mediaRecorder = new MediaRecorder(stream);

          // Manejar datos de audio
          mediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
          };

          // Cuando la grabación se detenga
          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
            audioChunks = [];

            audioStream.getTracks().forEach((track) => track.stop());

            // Leer el archivo como Base64
            const reader = new FileReader();
            reader.onload = () => {
              const base64Data = reader.result;
              const payload = {
                fileName: `audio-${Date.now()}.webm`, // Nombre único para el archivo
                fileType: "audio/webm",
                base64Data,
              };
              socket_query.emit(
                "chat_send",
                $drivers[travel.oper].chatId,
                base64Data,
                (data) => {
                  //chat_to_send="";
                  if (data == "ok") {
                    console.log("chat OK");
                  }
                }
              );
              console.log("Audio enviado:", payload);
            };
            reader.readAsDataURL(audioBlob);
            mediaRecorder = null;
            audioStream = null;
          };

          // Iniciar grabación al primer clic después de obtener permisos
          toggleRecording();
        })
        .catch((error) => {
          console.error("Error al acceder al micrófono:", error);
          alert(
            "No se pudo acceder al micrófono. Por favor, verifica los permisos."
          );
        });
    } else {
      toggleRecording();
    }
  }
  function toggleRecording() {
    if (!isRecording) {
      mediaRecorder.start();
      isRecording = true;
      console.log("Grabación iniciada");
    } else {
      mediaRecorder.stop();
      isRecording = false;
      console.log("Grabación detenida");
    }
  }
  function loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Error loading script: ${url}`));
      document.body.appendChild(script);
    });
  }
</script>

{#if close_alert_modal}
  <div
    class="modal fade {close_alert_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    style="padding-right: 17px; display: {close_alert_modal
      ? 'block'
      : 'none'}; z-index:1052;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
      style="min-width: 20%"
    >
      <div class="modal-content" style="min-height: 30%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">directions</i> Comentario de
            alerta<strong />
          </h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="d-flex flex-column">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Razon</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                bind:value={alert_end_reason}
              >
                <option value="0">Alerta válida</option>
                <option value="1">Alerta no válida</option>
                <option value="2">Alerta generada por falta de datos</option>
              </select>
            </div>
            <textarea
              class="form-control"
              placeholder="Escribe un comentario..."
              style="width:100%;"
              bind:value={alert_end_comment}
            />
          </div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-end">
            <div>
              <button
                on:click={send_alert_comment}
                class="btn btn-success big"
                type="button"
              >
                <i class="material-icons md-85">save</i>
                Enviar Comentario
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1051" />
{/if}

<audio id="main-audio" />
<div
  class="mdc-typography modal fade {alert_state ? 'show' : ''}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modaltitle"
  style="padding-right: 17px; display: {alert_state ? 'block' : 'none'};"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="modaltitle">
          <i class="material-icons red md-2">error</i> Unidad
          <strong>{unit}</strong>
          <span style="color:#e53935">{alert_name}</span>
        </h5>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column">
          <div>
            <i class="material-icons md-1" style="color:#004c40">access_time</i>
            Generada hace <strong>{time_code_s(counter_date_)}</strong>
          </div>
          <div>
            <i class="material-icons yei md-1">warning</i> Recibida hace
            <strong>{time_code_s(date_on_)}</strong>
          </div>
          <div>
            <i class="material-icons md-1" style="color:#373737"
              >error_outline</i
            >
            <span style="color:#df1616"><strong>{alert_pending}</strong></span> Alertas
            pendientes
          </div>
        </div>
      </div>
      <div class="modal-footer" style="display: block;">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column">
            <button
              type="button"
              class="btn btn-success btn-lg big"
              on:click={accept_alert}>Atender</button
            >
          </div>
          <div class="d-flex flex-column">
            <button
              type="button"
              class="btn btn-danger btn-lg big"
              on:click={cancel_alert}>Omitir alerta</button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="mdc-typography modal fade {alert_run ? 'show' : ''}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modaltitle"
  style="display:{alert_run ? 'block' : 'none'};"
>
  <div
    class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
    role="document"
    style="min-width: 99%;min-height: 90%"
  >
    <div class="modal-content h-100">
      <div
        class="modal-header"
        style="padding-top:0.1rem;padding-bottom:0.1rem;align-items: center;"
      >
        <h5 class="modal-title" id="modaltitle">
          <i class="material-icons md-2" style="color:#d32f2f">feedback</i>
          Unidad <strong>{unit}</strong>
          <span style="color:#e53935">en atención</span>
          por
          <span style="color:#e53935">{time_code_s(counter_date_ini_)}</span>
          {#if typeof rev_dir[selected] != "undefined"}
            en <span tooltip={rev_dir[selected]} style="font-size:1rem"
              >{rev_dir[selected].length > 75
                ? rev_dir[selected].slice(0, 75) + "..."
                : rev_dir[selected]}</span
            >
          {/if}
        </h5>
        <h5 style="margin-bottom:0rem">
          <i
            tooltip="Operador"
            class="material-icons md-90"
            style="color:#26a69a">person</i
          >
          <span
            style={travel.oper == "Desconocido" || travel.oper == ""
              ? "color:#2196f3"
              : ""}
            ><strong id="change_driver"
              >{typeof $drivers[travel.oper] != "undefined"
                ? $drivers[travel.oper].name
                : travel.oper}</strong
            ></span
          >
        </h5>
      </div>
      <div class="modal-body h-100" style="padding:0.3rem">
        <div class="container-fluid h-100 px-1">
          <div class="row h-100 no-gutters">
            <div class="col-6 h-100 pr-1">
              {#if typeof $drivers[travel.oper] != "undefined" ? ($drivers[travel.oper].chatId != "" ? true : false) : false}
                <div class="row h-50 no-gutters pb-1">
                  <div class="col-12 h-100">
                    <div class="d-flex flex-column h-100">
                      <div class="flex-grow-1 overflow-auto">
                        <div class="card h-100">
                          <div class="d-flex flex-column h-100">
                            <div class="card-header b-0">
                              <i
                                class="material-icons md-1"
                                style="color:#009688">chat</i
                              > Chat
                            </div>
                            <div
                              class="card-body flex-grow-1 overflow-auto p-1"
                              bind:this={chats_window}
                            >
                              {#if chat_list.length > 0}
                                {#each chat_list as item, i}
                                  {#if item.message.search("data:audio/") != -1}
                                    <div
                                      class="mt1 d-flex my-1 {item.type == 0
                                        ? 'flex-row'
                                        : 'flex-row-reverse'}"
                                      chat_date={item.date}
                                    >
                                      <div
                                        class=" card {item.type == 0
                                          ? 'text-white bg-dark2'
                                          : ' bg-secondary2'}"
                                        style="border-radius:1rem;border:2px solid #009184; max-width: 60%; padding-top: 10px; padding-bottom: 10px;"
                                      >
                                        <div
                                          class="card-header b-0"
                                          style="background-color: unset; border-bottom:unset;"
                                        >
                                          <i
                                            class="material-icons md-12"
                                            style="color:{item.type == 0
                                              ? '#fff'
                                              : '#212121'}"
                                            >{item.type == 1
                                              ? "home"
                                              : "person_pin"}</i
                                          >
                                          <audio
                                            src={item.message}
                                            controls="controls"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style="font-size:0.7rem"
                                        class="d-flex align-items-end"
                                      >
                                        <strong
                                          >{moment(
                                            new Date(item.date * 1000)
                                          ).format("DD/MM/YY HH:mm")}</strong
                                        >
                                      </div>
                                    </div>
                                  {:else if item.message.search("data:image/") != -1}
                                    <div
                                      class="mt1 d-flex my-1 {item.type == 0
                                        ? 'flex-row'
                                        : 'flex-row-reverse'}"
                                      chat_date={item.date}
                                    >
                                      <div
                                        class=" card {item.type == 0
                                          ? 'text-white bg-dark2'
                                          : ' bg-secondary2'}"
                                        style="border-radius:1rem;border:2px solid #009184; max-width: 60%; padding-top: 10px; padding-bottom: 10px;"
                                      >
                                        <div
                                          class="card-header b-0"
                                          style="background-color: unset; border-bottom:unset;"
                                        >
                                          <i
                                            class="material-icons md-12"
                                            style="color:{item.type == 0
                                              ? '#fff'
                                              : '#212121'}"
                                            >{item.type == 1
                                              ? "home"
                                              : "person_pin"}</i
                                          >
                                          <a href={item.message} download
                                            ><img
                                              src={item.message}
                                              alt=""
                                              height="200"
                                            /></a
                                          >
                                        </div>
                                      </div>
                                      <div
                                        style="font-size:0.7rem"
                                        class="d-flex align-items-end"
                                      >
                                        <strong
                                          >{moment(
                                            new Date(item.date * 1000)
                                          ).format("DD/MM/YY HH:mm")}</strong
                                        >
                                      </div>
                                    </div>
                                  {:else if item.message.search("data:application/pdf") != -1}
                                    <div
                                      class="mt1 d-flex my-1 {item.type == 0
                                        ? 'flex-row'
                                        : 'flex-row-reverse'}"
                                      chat_date={item.date}
                                    >
                                      <div
                                        class=" card {item.type == 0
                                          ? 'text-white bg-dark2'
                                          : ' bg-secondary2'}"
                                        style="border-radius:1rem;border:2px solid #009184;max-width: 60%; padding-top: 10px; padding-bottom: 10px;"
                                      >
                                        <div
                                          class="card-header b-0"
                                          style="background-color: unset; border-bottom:unset;"
                                        >
                                          <i
                                            class="material-icons md-12"
                                            style="color:{item.type == 0
                                              ? '#fff'
                                              : '#212121'}"
                                            >{item.type == 1
                                              ? "home"
                                              : "person_pin"}</i
                                          >
                                          <a href={item.message} download>
                                            <!-- icon PDF-->
                                            <i class="material-icons"
                                              >picture_as_pdf</i
                                            >
                                            Documento PDF
                                          </a>
                                        </div>
                                      </div>
                                      <div
                                        style="font-size:0.7rem"
                                        class="d-flex align-items-end"
                                      >
                                        <strong
                                          >{moment(
                                            new Date(item.date * 1000)
                                          ).format("DD/MM/YY HH:mm")}</strong
                                        >
                                      </div>
                                    </div>
                                  {:else if item.type != -1}
                                    <div
                                      class="mt1 d-flex my-1 {item.type == 0
                                        ? 'flex-row'
                                        : 'flex-row-reverse'}"
                                      chat_date={item.date}
                                    >
                                      <div
                                        class=" card {item.type == 0
                                          ? 'text-white bg-dark2'
                                          : ' bg-secondary2'}"
                                        style="border-radius:1rem;border:2px solid #009184; max-width: 60%; padding-top: 10px; padding-bottom: 10px;"
                                      >
                                        <div
                                          class="card-header b-0"
                                          style="border-bottom:0px; background-color: unset; border-bottom:unset;"
                                        >
                                          <i
                                            class="material-icons md-12"
                                            style="color:{item.type == 0
                                              ? '#fff'
                                              : '#212121'}"
                                            >{item.type == 1
                                              ? "home"
                                              : "person_pin"}</i
                                          >
                                          {item.message}
                                        </div>
                                      </div>
                                      <div
                                        style="font-size:0.7rem; margin: 0px 5px 0px 5px;"
                                        class="d-flex align-items-end"
                                      >
                                        <strong
                                          >{moment(
                                            new Date(item.date * 1000)
                                          ).format("DD/MM/YY HH:mm")}</strong
                                        >
                                      </div>
                                    </div>
                                  {:else}
                                    <div
                                      class="d-flex justify-content-center"
                                      style="text-align: center; margin: 10px;"
                                      id="last_read"
                                    >
                                      <div
                                        class="text-white"
                                        style="width: 100%;"
                                      >
                                        <div
                                          class=""
                                          style="display: flex;color: black;flex-direction: row;justify-content: center;align-items: center;"
                                        >
                                          <div
                                            style="display: inline-block;background:black; height:2px; width:10%;"
                                          ></div>
                                          <span
                                            style="margin-left: 10px;margin-right: 10px;"
                                            ><i class="material-icons md-12"
                                              >info</i
                                            >
                                            <strong>{item.message}</strong
                                            ></span
                                          >
                                          <div
                                            style="display: inline-block;background:black; height:2px; width:10%;"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  {/if}
                                {/each}
                              {:else}
                                <div class="d-flex justify-content-center">
                                  <div class="card bg-secondary2 text-white">
                                    <div
                                      class="card-header b-0"
                                      style="color: black;"
                                    >
                                      <i class="material-icons md-12">info</i> No
                                      hay mensajes
                                    </div>
                                  </div>
                                </div>
                              {/if}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex mt-1">
                        <form
                          on:submit|preventDefault={send_chat}
                          class="d-flex w-100"
                        >
                          <input
                            name="chat"
                            class="w-100 mr-2"
                            autocomplete="off"
                            bind:value={chat_to_send}
                            style="font-size:1.1rem; margin: 0.5rem; border-radius: 15px; padding: 5px;"
                            placeholder="Escribe un mensaje"
                          />
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*,application/pdf"
                            style="display: none;"
                            on:change={(e) => fielSender(e)}
                          />
                          <button
                            on:click|preventDefault={() => {
                              document.getElementById("fileInput").click();
                            }}
                            style="padding: 0.1rem 0.5rem; z-index:1000; margin: 0.1rem"
                            class="btn btn-light"
                            type="button"
                          >
                            <i class="material-icons">attach_file</i>
                          </button>
                          <button
                            on:click|preventDefault={() => run_record()}
                            style="padding: 0.1rem 0.5rem; z-index:1000; margin: 0.1rem"
                            class="btn btn-light"
                            type="button"
                          >
                            <!-- Audio icon -->
                            <i class="material-icons"
                              >{isRecording ? "stop" : "mic"}</i
                            >
                          </button>
                          <button
                            type="submit"
                            style="padding: 0.1rem 2rem; z-index:1000; margin: 0.5rem"
                            class="btn btn-success"
                            ><i class="material-icons">send</i></button
                          >
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              {/if}
              <div
                class="row h-{typeof $drivers[travel.oper] != 'undefined'
                  ? $drivers[travel.oper].chatId != ''
                    ? '50'
                    : '100'
                  : '100'} no-gutters"
              >
                <div class="col-12 h-100">
                  <div class="d-flex flex-column h-100">
                    <div class="card flex-grow-1" style="font-size:0.78rem">
                      <div class="card-header d-flex b-0">
                        <div class="d-flex flex-column flex-grow-1">
                          <div>
                            {#if travel.org.time != "" || travel.oper != "Desconocido"}
                              <i
                                tooltip="Origen"
                                class="material-icons md-90"
                                style="color:#26a69a"
                                >{travel.org.time != ""
                                  ? "play_circle_outline"
                                  : "change_history"}</i
                              > <strong>{travel.org.time} | </strong><span
                                style={false ? "color:#2196f3" : ""}
                                ><strong
                                  >{travel.org.name != "Desconocido"
                                    ? travel.org.name
                                    : travel.geo_stay[0]}</strong
                                ></span
                              >
                            {:else}
                              <i
                                class="material-icons md-90"
                                style="color:#26a69a">change_history</i
                              >
                              <strong> Estacionada, sin viaje asignado</strong>
                            {/if}
                          </div>
                          <div>
                            {#if travel.org.time != "" || travel.oper != "Desconocido"}
                              <i
                                tooltip="Destino"
                                class="material-icons md-90"
                                style="color:#26a69a">stop</i
                              >
                              <span
                                style={travel.dest.name == "Desconocido"
                                  ? "color:#2196f3"
                                  : ""}
                                ><strong id="change_destino"
                                  >{typeof $geos_class[travel.dest.name] !=
                                  "undefined"
                                    ? $geos_class[travel.dest.name][0]
                                    : travel.dest.name}</strong
                                ></span
                              >
                            {/if}
                          </div>
                          <div>
                            {#if travel.org.time != "" || travel.oper != "Desconocido"}
                              <i
                                tooltip="Ruta"
                                class="material-icons md-90"
                                style="color:#26a69a">directions</i
                              >
                              <span
                                style={travel.ruta.name == "Desconocida"
                                  ? "color:#2196f3"
                                  : ""}
                                ><strong id="change_ruta"
                                  >{typeof $route_list_d[travel.ruta.name] !=
                                  "undefined"
                                    ? $route_list_d[travel.ruta.name]
                                    : "Nombre no disponible"}</strong
                                ></span
                              >
                            {/if}
                          </div>
                        </div>

                        <div class="d-flex flex-column">
                          <div class="d-flex justify-content-end mb-2">
                            <div class="p-1">
                              <strong>GPS</strong>
                              <i
                                tooltip={travel.gps.level}
                                class="material-icons {travel.gps.level < 1.5
                                  ? 'green'
                                  : 'red'}"
                                >{travel.gps.level < 1.5
                                  ? "location_on"
                                  : "location_off"}</i
                              >
                            </div>
                            <div class="p-1">
                              <strong
                                >{typeof travel.gsm.net != "undefined"
                                  ? travel.gsm.net
                                  : "GSM"}</strong
                              ><i
                                tooltip="-{travel.gsm.level}"
                                class="material-icons {travel.gsm.level < 90
                                  ? 'green'
                                  : 'red'}"
                                >{travel.gsm.level < 90
                                  ? "signal_cellular_alt"
                                  : "signal_cellular_connected_no_internet_4_bar"}</i
                              >
                            </div>
                            <div class="p-1">
                              <i
                                tooltip="Última comunicación"
                                class="material-icons {Math.round(
                                  new Date().getTime() / 1000
                                ) -
                                  travel.gsm.last <
                                120
                                  ? 'green'
                                  : 'red'}"
                                >{Math.round(new Date().getTime() / 1000) -
                                  travel.gsm.last <
                                120
                                  ? "sync"
                                  : "sync_problem"}</i
                              >
                              <strong
                                >{time_code_s(
                                  Math.round(new Date().getTime() / 1000) -
                                    travel.gsm.last +
                                    1
                                )}<strong /></strong
                              >
                            </div>
                          </div>
                          <div class="d-flex justify-content-end">
                            <div class="p-1">
                              <div>
                                <i
                                  tooltip="Velocidad"
                                  class="material-icons green">speed</i
                                > <strong>{travel.mov.speed} Km/hr</strong>
                              </div>
                            </div>
                            <div class="p-1">
                              <div>
                                <i
                                  tooltip="Tiempo detenida {travel.mov
                                    .time_stop}min"
                                  class="material-icons {travel.ign == 0
                                    ? 'gray'
                                    : travel.mov.time_stop > 3
                                      ? 'yei'
                                      : 'green'}">pause_presentation</i
                                >
                                <strong
                                  >{time_code(travel.mov.time_stop)}</strong
                                >
                              </div>
                            </div>
                            <div class="p-1">
                              <div>
                                <i
                                  tooltip="Ignición {travel.ign == 1
                                    ? 'On'
                                    : 'Off'}"
                                  class="material-icons {travel.ign == 1
                                    ? 'green'
                                    : 'gray'}">vpn_key</i
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card h-100 mb-1">
                      <div
                        class="d-flex flex-column h-100 big-border border-danger"
                      >
                        <div
                          class="card-header b-0 d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <i class="material-icons md-1" style="color:#009688"
                              >donut_small</i
                            >Protocolo
                            <span style="color:#d32f2f"
                              >{protocolo2[proto_n]
                                ? protocolo2[proto_n].name
                                : ""}</span
                            >
                          </div>
                          <div>
                            <i
                              class="material-icons"
                              style="color:{call_status != -1
                                ? 'rgb(24, 179, 45)'
                                : '#df1616'}"
                              >{call_status == -1
                                ? "phone_disabled"
                                : call_status == 0
                                  ? "phone_disabled"
                                  : call_status == 1
                                    ? "settings_phone"
                                    : call_status == 2
                                      ? "phone_forwarded"
                                      : call_status == 3
                                        ? "phone_in_talk"
                                        : "phone_disabled"}</i
                            ><strong>
                              {call_status == -1
                                ? ""
                                : call_status == 0
                                  ? "En espera"
                                  : call_status == 1
                                    ? "Conectando con red..."
                                    : call_status == 2
                                      ? "Sonando..."
                                      : call_status == 3
                                        ? "Llamada establecida"
                                        : call_status == 4
                                          ? "Llamada Terminó"
                                          : ""}</strong
                            >
                            {#if call_status != -1}
                              <button
                                type="button"
                                class="btn btn-{call_status < 1
                                  ? 'secondary'
                                  : 'primary'} btn-lg mini"
                                on:click={call_run}
                                disabled={call_status < 1 ? "disabled" : ""}
                                >{s_paso}</button
                              >
                            {/if}
                          </div>
                        </div>
                        <div class="card-body flex-grow-1 overflow-auto p-1">
                          {@html protocolo2[proto_n]
                            ? protocolo2[proto_n][protocolo_estado]
                              ? protocolo2[proto_n][protocolo_estado].mensaje
                              : ""
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 h-100">
              <div class="row h-75 no-gutters">
                <div class="col-12 h-100">
                  <div class="h-100 w-100" id="map_alert" />
                </div>
              </div>
              <div class="row h-25 no-gutters">
                <div class="col-12 h-100">
                  <div class="card h-100">
                    <div class="d-flex flex-column h-100">
                      <div class="card-header b-0">
                        <i class="material-icons md-1 yei">warning</i> Eventos
                        <span class="badge badge-pill badge-danger"
                          >{alert_pending}</span
                        >
                      </div>
                      <div
                        class="card-body flex-grow-1 overflow-auto"
                        bind:this={alerts_scroll}
                      >
                        <table
                          class="table table-sm table-striped"
                          style="font-size:0.75rem"
                        >
                          <tbody>
                            {#each alert_runing as item, i}
                              <tr
                                ><td
                                  ><i class="material-icons red md-1"
                                    >priority_high</i
                                  >
                                  <span class="blue"
                                    >{moment(
                                      new Date(item.alerts_crtl.date_on * 1000)
                                    ).format("DD/MM/YY HH:mm:ss")}</span
                                  > <strong>{item.alerts.name}</strong></td
                                ></tr
                              >
                            {/each}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal-footer"
        style="display: block;padding-top:0.1rem;padding-bottom:0.1rem;"
      >
        <div class="row no-gutters">
          <div class="col-6">
            <div class="d-flex justify-content-between">
              {#each protocolo2[proto_n] ? (protocolo2[proto_n][protocolo_estado] ? protocolo2[proto_n][protocolo_estado].options : []) : [] as item, i}
                {#if item != ""}
                  <div class="d-flex flex-column mr-2">
                    <button
                      on:click={() => {
                        run_g(i);
                      }}
                      type="button"
                      class="btn btn-{protocolo2[proto_n][protocolo_estado]
                        .btn_color[i]} btn-{protocolo2[proto_n][
                        protocolo_estado
                      ].btn_size[i]} big">{item}</button
                    >
                  </div>
                {/if}
              {/each}
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-danger btn-lg big"
                on:click={cancel_run}>Dejar de Atender</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{#if alert_run || alert_state}<div class="modal-backdrop fade show" />{/if}

<style>
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  .navbar-brand {
    font-size: 1rem;
  }
  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .btn {
    font-size: 0.75rem;
  }
  .btn.big {
    font-size: 1rem;
  }
  .big-border {
    border: 3px solid;
  }
  .card-header.b-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0.75rem;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-12 {
    font-size: 1.2rem;
  }
  .material-icons.md-1 {
    font-size: 1.5rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1rem;
  }
  .material-icons.md-2 {
    font-size: 2rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  .timeline-Tweet-text {
    color: red;
  }
</style>
