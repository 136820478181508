import { writable } from "svelte/store";

export const portal_version = writable("7.21");
export const menu = writable(0);
export const table_pos = writable([]);
export const live = writable([]);
export const units_list = writable([]);
export const nicks_list = writable([]);
export const geos_class = writable([]);
export const geos = writable([]);
export const geos_list_par = writable([]);
export const geos_class_list = writable([]);
export const travels = writable([]);
export const route_list = writable([]);
export const route_list_d = writable([]);
export const drivers_list = writable([]);
export const drivers = writable([]);
export const driver_name = writable("");
export const driver_id = writable("");
export const travel_crtl = writable([]);
export const zones_list = writable([]);
export const zones = writable([]);
export const poli_list = writable([]);
export const poli = writable([]);
export const poli_full = writable([]);
export const geos_joined = writable([]);
export const geos_class_join = writable([]);
export const internal_com = writable([]);
export const map_ready = writable(0);
export const monitor_extra_map = writable(0);
export const travels_list = writable([]);
export const recognition = writable("");
export const unit_groups = writable([]);
export const geos_full = writable([]);
export const geo_class_full = writable([]);
export const zone_full = writable([]);
export const unit_config = writable({});
export const unit_types = writable([]);
export const reports_cron = writable([]);
export const route_list_nosig = writable([]);
export const route_list_nosig_d = writable({});
export const dtc_count = writable({});
export const geos_map = writable({});
export const users_list = writable([]);
export const protocol = writable([]);
export const alerts_list = writable([]);
export const reports = writable({});
export const group = writable("");
export const driver_reports = writable({});

export const cc_monitor = writable([]);
export const speaking_list = writable([]);