<script>
  import { onMount, onDestroy } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    geos_map,
    poli,
  } from "./stores.js";

  let monitor_;
  onDestroy(() => {
    clearInterval(monitor_);
  });

  let travels_fw = [];
  monitor_ = setInterval(monitor, 1000);
  monitor();
  function monitor() {
    for (let x in Object.keys($travels)) {
      let mobid = Object.keys($travels)[x];
      let t_found = false;
      let t_found_p;
      for (let y in travels_fw) {
        if (travels_fw[y]["id"] == mobid) {
          t_found = true;
          t_found_p = y;
          let time_stop = 0;
          if (
            $travels[mobid]["travel"][$travels[mobid]["travel"].length - 1][
              "last_status"
            ] == 1
          ) {
            time_stop =
              $travels[mobid]["travel"][$travels[mobid]["travel"].length - 1][
                "pos"
              ][
                $travels[mobid]["travel"][$travels[mobid]["travel"].length - 1][
                  "pos"
                ].length - 1
              ];
          }
          travels_fw[t_found_p].mov.time_stop =
            time_stop.length > 4 ? time_stop[4] - time_stop[3] : 0;
          travels_fw[t_found_p]["monitor_crtl"] =
            $travels[mobid]["monitor_crtl"];
          travels_fw[t_found_p]["oper"] =
            typeof $travels[mobid]["monitor_crtl"]["driver"] == "undefined"
              ? "Desconocido"
              : $travels[mobid]["monitor_crtl"]["driver"] == ""
                ? "Desconocido"
                : $travels[mobid]["monitor_crtl"]["driver"];
          break;
        }
      }
      if (!t_found) {
        let time_stop = 0;
        let org_time = "";
        let ign = 0;
        let geo_org = ["Desconocido", 1, ""];
        let lat_org, lng_org;
        let oper =
          typeof $travels[mobid]["monitor_crtl"]["driver"] == "undefined"
            ? "Desconocido"
            : $travels[mobid]["monitor_crtl"]["driver"];
        if (
          $travels[mobid]["travel"][$travels[mobid]["travel"].length - 1][
            "last_status"
          ] == 1
        ) {
          time_stop =
            $travels[mobid]["travel"][$travels[mobid]["travel"].length - 1][
              "pos"
            ][
              $travels[mobid]["travel"][$travels[mobid]["travel"].length - 1][
                "pos"
              ].length - 1
            ];
          org_time = moment(
            new Date($travels[mobid]["travel"][0]["date_start"] * 1000)
          ).format("DD/MM/YY HH:mm");
          lat_org = $travels[mobid]["travel"][0]["pos_start"][0] / 10000;
          lng_org = $travels[mobid]["travel"][0]["pos_start"][1] / 10000;
          geo_org = geo_search(lat_org, lng_org);
          ign = 1;
        }
        travels_fw.push({
          id: mobid,
          state: "OK",
          ign: ign,
          ruta: { name: "Desconocida", id: "" },
          org: {
            name: geo_org[0],
            id: geo_org[2],
            time: org_time,
          },
          dest: { name: "Desconocido", id: "" },
          gps: { level: 1 },
          gsm: { level: 1, last: 1, net: "GSM" },
          monitor_crtl: $travels[mobid]["monitor_crtl"],
          oper: oper,
          geo_stay: ["", 1, ""],
          mov: {
            speed: 0,
            time_stop: time_stop.length > 4 ? time_stop[4] - time_stop[3] : 0,
          },
        });
        t_found_p = travels_fw.length - 1;
      }
      if (typeof $live[mobid] != "undefined") {
        travels_fw[t_found_p].gsm.last = $live[mobid]["last_comm"];
        travels_fw[t_found_p].mov.speed = $live[mobid]["vel"];
        travels_fw[t_found_p].gsm.level = $live[mobid]["rssi"];
        travels_fw[t_found_p].gps.level = $live[mobid]["hdop"];
        travels_fw[t_found_p].gsm.net = $live[mobid]["network"];
        travels_fw[t_found_p].ign = $live[mobid]["ingnition"];
        if ($live[mobid]["ingnition"] == 0) {
          travels_fw[t_found_p].geo_stay = geo_search(
            $live[mobid]["lat"],
            $live[mobid]["lon"]
          );
        } else {
          travels_fw[t_found_p].geo_stay = ["", 1, ""];
        }
      }
    }
    travels_fw.sort(travels_order);
  }
  function geo_search(lat, lng) {
    let box_size = 0.0001797 * 10; // 200 metros por celula
    let p = Math.floor((lat - 14.5408) / box_size);
    let q = Math.floor((lng + 118.2689) / box_size);
    let key_close_list = [
      p + "_" + q,
      p + "_" + (q + 1),
      p + "_" + (q - 1),
      p + 1 + "_" + q,
      p - 1 + "_" + q,
      p + 1 + "_" + (q + 1),
      p - 1 + "_" + (q - 1),
      p + 1 + "_" + (q - 1),
      p - 1 + "_" + (q + 1),
    ];
    let geos_arround = [];
    for (let w in key_close_list) {
      if ($geos_map[key_close_list[w]]) {
        for (let s in $geos_map[key_close_list[w]]) {
          if (!geos_arround.includes($geos_map[key_close_list[w]][s])) {
            geos_arround.push($geos_map[key_close_list[w]][s]);
          }
        }
      }
    }
    let found = false;
    for (let x in $poli) {
      if (geos_arround.includes(x)) {
        if (dentro([lat, lng], $poli[x][0])) {
          return [$poli[x][1], 2, x];
        }
      }
    }
    for (let x in $geos) {
      // filtro
      if (geos_arround.includes($geos[x][0])) {
        for (let y in $geos[x][1]) {
          let lat_ = $geos[x][1][y][0];
          let lng_ = $geos[x][1][y][1];
          if (get_km(lat, lng, lat_, lng_) <= 0.13) {
            if (typeof $geos_class[$geos[x][0]] == "undefined") {
              return [
                "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                1,
                $geos[x][0],
              ];
            } else {
              return [
                $geos_class[$geos[x][0]][0],
                $geos_class[$geos[x][0]][1],
                $geos[x][0],
              ];
            }
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    return ["Geocerca_automatica_", 1, ""];
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  function dentro(point, vs) {
    let x = point[0],
      y = point[1];
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0],
        yi = vs[i][1];
      let xj = vs[j][0],
        yj = vs[j][1];
      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }
  function travels_order(a, b) {
    if ($nicks_list[b.id] < $nicks_list[a.id]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
  function time_code_s(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("ss[s]");
    } else if (time < 60 * 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("mm[m]");
    } else if (time < 60 * 60 * 24) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("HH[h]");
    } else if (time < 60 * 60 * 24 * 90) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ seconds: time })
        .format("DD[d]");
    } else {
      return "+3 meses";
    }
  }
</script>

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-12 h-100">
      <div class="card-columns">
        {#each travels_fw as item, i}
          {#if Math.round(new Date().getTime() / 1000) - item.gsm.last + 1 < 60 * 60 * 24 * 30}
            <div
              class="card {Math.round(new Date().getTime() / 1000) -
                item.gsm.last >=
                300 || item.gps.level >= 1.5
                ? ' bg-dark2'
                : ''}"
              style="font-size:1.1vw"
            >
              <div class="card-body">
                <div class="d-flex flex-row flex-wrap">
                  <div class="px-1">
                    <strong
                      >{typeof $nicks_list[item.id] != "undefined"
                        ? $nicks_list[item.id]
                        : item.id}</strong
                    >
                  </div>
                  <div class="px-1">
                    <i
                      tooltip={item.gps.level}
                      class="material-icons md-1 {item.gps.level < 1.5
                        ? 'green'
                        : 'red'}"
                      >{item.gps.level < 1.5
                        ? "location_on"
                        : "location_off"}</i
                    >
                  </div>
                  <div class="px-1">
                    <strong
                      >{typeof item.gsm.net != "undefined"
                        ? item.gsm.net
                        : "2G"}</strong
                    ><i
                      tooltip="-{item.gsm.level}"
                      class="material-icons md-1 {item.gsm.level < 90
                        ? 'green'
                        : 'red'}"
                      >{item.gsm.level < 90
                        ? "signal_cellular_alt"
                        : "signal_cellular_connected_no_internet_4_bar"}</i
                    >
                  </div>
                  <div class="px-1">
                    <i
                      tooltip="Ignición {item.ign == 1 ? 'On' : 'Off'}"
                      class="material-icons md-1 {item.ign == 1
                        ? 'green'
                        : 'gray'}">vpn_key</i
                    >
                  </div>
                  <div class="px-1">
                    <i
                      tooltip="Última comunicación"
                      class="material-icons md-1 {Math.round(
                        new Date().getTime() / 1000
                      ) -
                        item.gsm.last <
                      300
                        ? 'green'
                        : 'red'}"
                      >{Math.round(new Date().getTime() / 1000) -
                        item.gsm.last <
                      300
                        ? "sync"
                        : "sync_problem"}</i
                    >
                    <strong
                      >{time_code_s(
                        Math.round(new Date().getTime() / 1000) -
                          item.gsm.last +
                          1
                      )}<strong /></strong
                    >
                  </div>
                </div>
              </div>
            </div>
          {/if}
        {/each}
      </div>
    </div>
  </div>
</main>

<style>
  .card-columns {
    column-count: 6;
    column-gap: 0rem;
    widows: 1;
    orphans: 1;
  }
  .card-body {
    padding: 0.2rem 0.05rem 0.2rem 0.05rem;
  }
  .card {
    margin-bottom: 0rem;
  }
  .card.big-border {
    border: 2px solid;
  }
  .bg-dark2 {
    background-color: #ffecb3;
  }
  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.3vw;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
