<script>
  import { onMount, onDestroy, afterUpdate } from "svelte";
  onMount(() => {
    Plotly.d3.csv(
      "https://raw.githubusercontent.com/plotly/datasets/master/api_docs/mt_bruno_elevation.csv",
      function (err, rows) {
        function unpack(rows, key) {
          return rows.map(function (row) {
            return row[key];
          });
        }

        var z_data = [];
        for (let i = 0; i < 24; i++) {
          z_data.push(unpack(rows, i));
        }

        var data = [{ z: z_data, type: "surface" }];

        var layout = {
          title: "Estado de alertas",
          autosize: false,
          width: 400,
          height: 400,
          margin: {
            l: 65,
            r: 50,
            b: 65,
            t: 90,
          },
        };
        var config = { responsive: true };
        Plotly.newPlot("myDiv", data, layout, config);
      }
    );

    Plotly.d3.json(
      "https://raw.githubusercontent.com/plotly/datasets/master/3d-ribbon.json",
      function (figure) {
        var trace1 = {
          x: figure.data[0].x,
          y: figure.data[0].y,
          z: figure.data[0].z,
          name: "",
          colorscale: figure.data[0].colorscale,
          type: "surface",
          showscale: false,
        };
        var trace2 = {
          x: figure.data[1].x,
          y: figure.data[1].y,
          z: figure.data[1].z,
          name: "",
          colorscale: figure.data[1].colorscale,
          type: "surface",
          showscale: false,
        };
        var trace3 = {
          x: figure.data[2].x,
          y: figure.data[2].y,
          z: figure.data[2].z,
          colorscale: figure.data[2].colorscale,
          type: "surface",
          showscale: false,
        };
        var trace4 = {
          x: figure.data[3].x,
          y: figure.data[3].y,
          z: figure.data[3].z,
          colorscale: figure.data[3].colorscale,
          type: "surface",
          showscale: false,
        };
        var trace5 = {
          x: figure.data[4].x,
          y: figure.data[4].y,
          z: figure.data[4].z,
          colorscale: figure.data[4].colorscale,
          type: "surface",
          showscale: false,
        };
        var trace6 = {
          x: figure.data[5].x,
          y: figure.data[5].y,
          z: figure.data[5].z,
          colorscale: figure.data[5].colorscale,
          type: "surface",
          showscale: false,
        };
        var trace7 = {
          x: figure.data[6].x,
          y: figure.data[6].y,
          z: figure.data[6].z,
          name: "",
          colorscale: figure.data[6].colorscale,
          type: "surface",
          showscale: false,
        };

        var data = [trace1, trace2, trace3, trace4, trace5, trace6, trace7];

        var layout = {
          title: "Estado de alertas",
          showlegend: false,
          autosize: true,
          width: 400,
          height: 400,
          scene: {
            xaxis: { title: "Sample #" },
            yaxis: { title: "Wavelength" },
            zaxis: { title: "OD" },
          },
        };
        Plotly.newPlot("myDiv2", data, layout);
      }
    );

    var intensity = [
      0,
      0.14285714285714285,
      0.2857142857142857,
      0.42857142857142855,
      0.5714285714285714,
      0.7142857142857143,
      0.8571428571428571,
      1,
    ];

    var data = [
      {
        type: "mesh3d",
        x: [0, 0, 1, 1, 0, 0, 1, 1],
        y: [0, 1, 1, 0, 0, 1, 1, 0],
        z: [0, 0, 0, 0, 1, 1, 1, 1],
        i: [7, 0, 0, 0, 4, 4, 6, 6, 4, 0, 3, 2],
        j: [3, 4, 1, 2, 5, 6, 5, 2, 0, 1, 6, 3],
        k: [0, 7, 2, 3, 6, 7, 1, 1, 5, 5, 7, 6],
        intensity: intensity,
        colorscale: [
          [0, "rgb(255, 0, 255)"],
          [0.5, "rgb(0, 255, 0)"],
          [1, "rgb(0, 0, 255)"],
        ],
      },
    ];
    var layout = {
      title: "Estado de alertas",
      autosize: true,
      width: 400,
      height: 400,
    };

    Plotly.newPlot("myDiv3", data, layout);
  });
</script>

<main role="main" class="container-fluid h-100 pl-0 pr-0">
  <div class="row h-100 no-gutters">
    <div class="col-12 h-100">
      <div class="card-columns">
        <div class="card" style="font-size:0.8rem;">
          <div class="card-header b-0 bg-dark text-white">
            Estado de alertas
          </div>
          <div class="card-body">
            <div id="myDiv" style="" />
          </div>
        </div>
        <div class="card" style="font-size:0.8rem;">
          <div class="card-header b-0 bg-dark text-white">
            Estado de alertas
          </div>
          <div class="card-body">
            <div id="myDiv2" style="" />
          </div>
        </div>
        <div class="card" style="font-size:0.8rem;">
          <div class="card-header b-0 bg-dark text-white">
            Estado de alertas
          </div>
          <div class="card-body">
            <div id="myDiv3" style="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
</style>
