<script>
  import { onMount } from "svelte";
  import { map_ready } from "./stores.js";
  let map;
  let on_map = false;
  let suspended = false;
  let bounds;
  const style = document.createElement("style");
  style.innerHTML = `
    .custom-label {
      background-color: white; /* Fondo blanco */
      padding: 2px 5px; /* Espaciado interno */
      border-radius: 3px; /* Bordes redondeados */
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); /* Sombra */
      white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    }
  `;
  document.head.appendChild(style);
  function initMap() {
    bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      streetViewControl: false, // Deshabilita Street View
      fullscreenControl: false, // Deshabilita el botón de pantalla completa
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    map.addListener("mouseover", function () {
      on_map = true;
    });
    map.addListener("mouseout", function () {
      on_map = false;
    });
    map.fitBounds(bounds);
  }
  $: {
    if ($map_ready == 1) {
      initMap();
      console.log("mapa cargado");
    }
  }

  let m_inicio,
    m_fin,
    recorrido_ruta,
    m_inicio_ruta,
    m_fin_ruta,
    recorrido = [],
    l_ruta_estimada,
    circles = [],
    poligons = [],
    driver_markers,
    driver_name = "",
    destino_name = "",
    speed = 0,
    ignition = 0,
    eta = -1;
  const bc = new BroadcastChannel("map_external");
  const bc_route = new BroadcastChannel("map_external_route");
  const bc_coordenadas = new BroadcastChannel("map_external_coordenadas");
  const bc_control = new BroadcastChannel("map_external_control");

  bc_control.onmessage = function (ev) {
    if (ev.data.close) window.close();

    if (ev.data.suspended) {
      suspended = true;
    } else {
      if (suspended) {
        map.setZoom(5);
        map.fitBounds(bounds);
        if (typeof m_inicio != "undefined") {
          m_inicio.setMap(null);
        }
        if (typeof m_fin != "undefined") {
          m_fin.setMap(null);
        }
        for (let x in recorrido) {
          if (typeof recorrido[x] != "undefined") {
            recorrido[x].setMap(null);
          }
        }
        recorrido = [];
        if (typeof recorrido_ruta != "undefined") {
          recorrido_ruta.setMap(null);
        }
        if (typeof m_inicio_ruta != "undefined") {
          m_inicio_ruta.setMap(null);
        }
        if (typeof m_fin_ruta != "undefined") {
          m_fin_ruta.setMap(null);
        }
        if (typeof l_ruta_estimada != "undefined") {
          l_ruta_estimada.setMap(null);
        }
        for (let x in circles) {
          circles[x].setMap(null);
        }
        circles = [];
        for (let x in poligons) {
          poligons[x].setMap(null);
        }
        poligons = [];
        if (driver_markers) {
          driver_markers.setMap(null);
        }
      }
      suspended = false;
    }
  };

  bc.onmessage = function (ev) {
    console.log(ev.data);

    let bounds = new google.maps.LatLngBounds();
    let zIndex = 0;

    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    if (ev.data.m_inicio != "") {
      m_inicio = new google.maps.Marker({
        position: ev.data.m_inicio,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-start.png",
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_fin != "undefined") {
      m_fin.setMap(null);
    }
    m_fin = new google.maps.Marker({
      position: ev.data.m_fin,
      map: map,
      animation: google.maps.Animation.DROP,
      //icon: image,
      icon: {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 6, // Escala del icono (tamaño de la flecha)
        rotation: ev.data.m_fin.heading, // Aplica el 'heading' como rotación
        fillColor:
          ev.data.m_fin.ingnition == 0
            ? "#FAFBFA"
            : ev.data.m_fin.vel == 0
              ? "#D580D5"
              : "#80C080",
        fillOpacity: 1,
        strokeWeight: 2,
        labelOrigin: new google.maps.Point(-3, -3),
      },
      zIndex: zIndex,
      label: {
        text: ev.data.eco,
        color: "#212121",
        fontSize: "13px",
        fontWeight: "bold",
        className: "custom-label",
      },
    });

    zIndex++;
    zIndex++;
    zIndex++;
    zIndex++;
    for (let x in recorrido) {
      if (typeof recorrido[x] != "undefined") {
        recorrido[x].setMap(null);
      }
    }
    recorrido = [];
    if (ev.data.recorrido.length > 0) {
      recorrido.push(
        new google.maps.Polyline({
          path: ev.data.recorrido,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#b71c1c",
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        })
      );
    }

    for (let x in ev.data.recorrido) {
      bounds.extend(ev.data.recorrido[x]);
    }

    if (!on_map) {
      map.setZoom(15);
      map.panTo(ev.data.panTo);
    }

    if (ev.data.selected_ != -1) {
      if (ev.data.recorrido.length > 1) {
        map.fitBounds(bounds);
      }
    }

    driver_name = ev.data.driver_name;
    destino_name = ev.data.destino_name;
    speed = ev.data.speed;
    ignition = ev.data.ign;
  };
  bc_route.onmessage = function (ev) {
    //console.log(ev.data);

    if (ev.data.clean) {
      if (typeof recorrido_ruta != "undefined") {
        recorrido_ruta.setMap(null);
      }
      if (typeof m_inicio_ruta != "undefined") {
        m_inicio_ruta.setMap(null);
      }
      if (typeof m_fin_ruta != "undefined") {
        m_fin_ruta.setMap(null);
      }
      if (typeof l_ruta_estimada != "undefined") {
        l_ruta_estimada.setMap(null);
      }
      for (let x in circles) {
        circles[x].setMap(null);
      }
      circles = [];
      for (let x in poligons) {
        poligons[x].setMap(null);
      }
      poligons = [];
      if (driver_markers) {
        driver_markers.setMap(null);
      }
      return;
    }

    if (ev.data.center) {
      map.setCenter({ lat: ev.data.lat, lng: ev.data.lng });
      return;
    }

    if (ev.data.ruta_estimada) {
      if (typeof l_ruta_estimada != "undefined") {
        l_ruta_estimada.setMap(null);
      }
      let lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
      };
      l_ruta_estimada = new google.maps.Polyline({
        path: ev.data.ruta_estimada,
        geodesic: true,
        strokeColor: "#2f21f3",
        strokeOpacity: 0,
        strokeWeight: 2.5,
        map: map,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
      });
      return;
    }

    if (ev.data.circles) {
      for (let x in circles) {
        circles[x].setMap(null);
      }
      circles = [];
      for (let y in ev.data.circles) {
        let lat = ev.data.circles[y][0];
        let lng = ev.data.circles[y][1];
        circles.push(
          new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map,
            center: { lat: lat, lng: lng },
            radius: 100,
            zIndex: 3,
          })
        );
      }
      return;
    }

    if (ev.data.poligons) {
      for (let x in poligons) {
        poligons[x].setMap(null);
      }
      poligons = [];
      poligons.push(
        new google.maps.Polygon({
          paths: ev.data.poligons,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map: map,
          zIndex: 5,
        })
      );
      return;
    }

    if (ev.data.driver_markers) {
      if (driver_markers) {
        driver_markers.setMap(null);
      }
      driver_markers = new google.maps.Marker({
        position: ev.data.driver_markers,
        map: map,
        title: "Posición del conductor",
        zIndex: 1,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: ev.data.fillColor,
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "#FFFFFF",
          labelOrigin: new google.maps.Point(-3, -3),
        },
        label: {
          text: ev.data.text,
          color: "#212121",
          fontSize: "13px",
          fontWeight: "bold",
          className: "custom-label",
        },
      });
      return;
    }

    let bounds = new google.maps.LatLngBounds();
    let zIndex = 0;

    zIndex++;
    if (typeof recorrido_ruta != "undefined") {
      recorrido_ruta.setMap(null);
    }
    if (ev.data.recorrido_ruta != "") {
      recorrido_ruta = new google.maps.Polyline({
        path: ev.data.recorrido_ruta,
        geodesic: true,
        strokeColor: "#2196f3",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_inicio_ruta != "undefined") {
      m_inicio_ruta.setMap(null);
    }
    if (ev.data.m_inicio_ruta != "") {
      m_inicio_ruta = new google.maps.Marker({
        position: ev.data.m_inicio_ruta,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerA.png",
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_fin_ruta != "undefined") {
      m_fin_ruta.setMap(null);
    } //http://www.google.com/mapfiles/
    if (ev.data.m_fin_ruta != "") {
      m_fin_ruta = new google.maps.Marker({
        position: ev.data.m_fin_ruta,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerB.png",
        zIndex: zIndex,
      });
      // ETA
      console.log(ev.data.m_fin);
      eta = -1;
      osrm_destino(
        ev.data.m_fin.lat,
        ev.data.m_fin.lng,
        ev.data.m_fin_ruta.lat,
        ev.data.m_fin_ruta.lng
      ).then((r) => {
        eta = r;
      });
    }
  };
  bc_coordenadas.onmessage = function (ev) {
    let zIndex = 5;
    let coordenada = ev.data.recorrido;
    let color_change = ev.data.color_change;
    let colors_line = [
      "#2196f3",
      "#e91e63",
      "#03a9f4",
      "#9c27b0",
      "#00bcd4",
      "#673ab7",
      "#009688",
      "#3f51b5",
      "#4caf50",
      "#ff5722",
      "#8bc34a",
      "#ff9800",
      "#cddc39",
      "#ffc107",
      "#607d8b",
      "#ffeb3b",
      "#9e9e9e",
      "#212121",
      "#f44336",
    ];
    for (let x in recorrido) {
      if (typeof recorrido[x] != "undefined") {
        recorrido[x].setMap(null);
      }
    }
    recorrido = [];
    let color_change_c = 0;
    if (ev.data.recorrido.length > 0) {
      recorrido.push(
        new google.maps.Polyline({
          path: [coordenada[0]],
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#b71c1c",
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        })
      );
      for (let x in coordenada) {
        if (color_change.includes(parseInt(x))) {
          recorrido.push(
            new google.maps.Polyline({
              path: [
                new google.maps.LatLng(
                  coordenada[x - 1].lat,
                  coordenada[x - 1].lng
                ),
                new google.maps.LatLng(coordenada[x].lat, coordenada[x].lng),
              ],
              geodesic: true,
              strokeColor:
                color_change_c < colors_line.length
                  ? colors_line[color_change_c]
                  : colors_line[color_change_c % colors_line.length],
              strokeOpacity: 1.0,
              strokeWeight: 2.5,
              map: map,
              zIndex: zIndex,
              icons: [
                {
                  icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeColor:
                      color_change_c < colors_line.length
                        ? colors_line[color_change_c]
                        : colors_line[color_change_c % colors_line.length],
                  },
                  offset: "100px",
                  repeat: "100px",
                },
              ],
            })
          );
          color_change_c++;
          zIndex++;
        } else {
          recorrido[recorrido.length - 1]
            .getPath()
            .push(new google.maps.LatLng(coordenada[x].lat, coordenada[x].lng));
        }
      }
    }
  };
  async function osrm_destino(lat, lon, lat2, lon2, print = true) {
    // OSRM
    let coordenada_osrm = "";
    coordenada_osrm = lon + "," + lat + ";" + lon2 + "," + lat2;

    let osrm_r = await fetch(
      "https://route.omnitracs.online/route/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full"
    );
    osrm_r = await osrm_r.json();
    if (osrm_r.code == "Ok") {
      return osrm_r.routes[0].duration;
    } else {
      return 0;
    }
  }
</script>

<main role="main" class="container-fluid h-100 w-100 pl-0 pr-0">
  <div
    class="row h-100 no-gutters"
    style="position: relative;overflow: hidden;"
  >
    <div
      class="w-100"
      id="map"
      style="bottom: -64px;height: calc(100vh + 64px);position: absolute;"
    />
  </div>
  {#if driver_name != "" && driver_name != null}
    <div class="trip-info">
      <p>
        <span class="material-icons">person</span><strong> Conductor </strong>
        {driver_name}
      </p>
      <p>
        <span class="material-icons">flag</span><strong> Destino </strong>
        {destino_name}
      </p>
      <p>
        <span class="material-icons">speed</span><strong> Velocidad </strong>
        {speed}
        km/h
      </p>
      <p>
        <span class="material-icons">power</span><strong> Ignición </strong>
        {ignition == 0 ? "Apagada" : "Encendida"}
      </p>
      <!--ETA-->
      {#if eta >= 0}
        <p>
          <span class="material-icons">schedule</span><strong> ETA </strong>
          {Math.round(eta / 60 / 60) + "h " + Math.round((eta / 60) % 60) + "m"}
        </p>
      {/if}
    </div>
  {/if}

  {#if suspended}
    <div class="overlay">
      <div class="message">
        <h2>⚠️ Actualizaciones Suspendidas ⚠️</h2>
        <p>
          Vuelva a Monitor para reactivar las actualizaciones en tiempo real.
        </p>
      </div>
    </div>
  {/if}
</main>

<style>
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(128, 128, 128, 0.7); /* Gris transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    text-align: center;
  }
  .message {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  .trip-info {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  .trip-info p {
    display: flex;
    align-items: center;
    gap: 3px;
    margin: 2px 0;
  }
  .material-icons {
    vertical-align: middle;
    color: #555;
    font-size: 16px;
  }
</style>
