<script>
  import { onMount } from "svelte";
  import { map_ready } from "./stores.js";
  let map;
  let on_map = false;
  function initMap() {
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    map.addListener("mouseover", function () {
      on_map = true;
    });
    map.addListener("mouseout", function () {
      on_map = false;
    });
    map.fitBounds(bounds);
  }
  $: {
    if ($map_ready == 1) {
      initMap();
      console.log("mapa cargado");
    }
  }

  let m_inicio, m_fin, recorrido_ruta, m_inicio_ruta, m_fin_ruta, recorrido;
  const bc = new BroadcastChannel("map_external");
  const bc_route = new BroadcastChannel("map_external_route");
  const bc_coordenadas = new BroadcastChannel("map_external_coordenadas");

  bc.onmessage = function (ev) {
    //console.log(ev.data);

    let bounds = new google.maps.LatLngBounds();
    let zIndex = 0;

    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    if (ev.data.m_inicio != "") {
      m_inicio = new google.maps.Marker({
        position: ev.data.m_inicio,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-start.png",
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_fin != "undefined") {
      m_fin.setMap(null);
    }
    m_fin = new google.maps.Marker({
      position: ev.data.m_fin,
      map: map,
      animation: google.maps.Animation.DROP,
      icon: ev.data.image_end,
      zIndex: zIndex,
      label: {
        text: ev.data.eco,
        color: "#212121",
        fontSize: "13px",
        fontWeight: "bold",
      },
    });

    zIndex++;
    /*
        if(typeof recorrido_ruta != 'undefined'){recorrido_ruta.setMap(null);}
        if(ev.data.recorrido_ruta!="")
        {
            recorrido_ruta = new google.maps.Polyline({
                path: ev.data.recorrido_ruta,
                geodesic: true,
                strokeColor: '#2196f3',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                map: map,
                zIndex:zIndex
            });
        }

        zIndex++;
        if(typeof m_inicio_ruta != 'undefined'){m_inicio_ruta.setMap(null);}
        if(ev.data.m_inicio_ruta!="")
        {
            m_inicio_ruta = new google.maps.Marker({
                position: ev.data.m_inicio_ruta,
                map: map,
                animation: google.maps.Animation.DROP,
                icon: 'img/markerA.png',
                zIndex:zIndex
            });
        } 

        zIndex++;
        if(typeof m_fin_ruta != 'undefined'){m_fin_ruta.setMap(null);} //http://www.google.com/mapfiles/
        if(ev.data.m_fin_ruta!="")
        {
            m_fin_ruta = new google.maps.Marker({
                position: ev.data.m_fin_ruta,
                map: map,
                animation: google.maps.Animation.DROP,
                icon: 'img/markerB.png',
                zIndex:zIndex
            });
        } 
        */
    zIndex++;
    zIndex++;

    zIndex++;
    if (typeof recorrido != "undefined") {
      recorrido.setMap(null);
    }
    if (ev.data.recorrido.length > 0) {
      recorrido = new google.maps.Polyline({
        path: ev.data.recorrido,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeColor: "#b71c1c",
            },
            offset: "100px",
            repeat: "100px",
          },
        ],
      });
    }

    for (let x in ev.data.recorrido) {
      bounds.extend(ev.data.recorrido[x]);
    }

    if (!on_map) {
      map.setZoom(16);
      map.panTo(ev.data.panTo);
    }

    if (ev.data.selected_ != -1) {
      if (ev.data.recorrido.length > 1) {
        map.fitBounds(bounds);
      }
    }
  };
  bc_route.onmessage = function (ev) {
    //console.log(ev.data);

    let bounds = new google.maps.LatLngBounds();
    let zIndex = 0;

    zIndex++;
    if (typeof recorrido_ruta != "undefined") {
      recorrido_ruta.setMap(null);
    }
    if (ev.data.recorrido_ruta != "") {
      recorrido_ruta = new google.maps.Polyline({
        path: ev.data.recorrido_ruta,
        geodesic: true,
        strokeColor: "#2196f3",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_inicio_ruta != "undefined") {
      m_inicio_ruta.setMap(null);
    }
    if (ev.data.m_inicio_ruta != "") {
      m_inicio_ruta = new google.maps.Marker({
        position: ev.data.m_inicio_ruta,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerA.png",
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_fin_ruta != "undefined") {
      m_fin_ruta.setMap(null);
    } //http://www.google.com/mapfiles/
    if (ev.data.m_fin_ruta != "") {
      m_fin_ruta = new google.maps.Marker({
        position: ev.data.m_fin_ruta,
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerB.png",
        zIndex: zIndex,
      });
    }
  };
  bc_coordenadas.onmessage = function (ev) {
    let zIndex = 5;
    if (typeof recorrido != "undefined") {
      recorrido.setMap(null);
    }
    if (ev.data.recorrido.length > 0) {
      recorrido = new google.maps.Polyline({
        path: ev.data.recorrido,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeColor: "#b71c1c",
            },
            offset: "100px",
            repeat: "100px",
          },
        ],
      });
    }
  };
</script>

<main role="main" class="container-fluid h-100 w-100 pl-0 pr-0">
  <div class="row h-100 no-gutters">
    <div class="h-100 w-100" id="map" />
  </div>
</main>
