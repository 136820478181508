<script>
  export let socket_query;
  import {
    drivers_list,
    nicks_list,
    units_list,
    geos_class_list,
    route_list_d,
    geos_class,
    geos,
    route_list,
    geos_class_join,
    geos_joined,
    geos_map,
  } from "./stores.js";

  let oper_selected = "";
  let eco_selected = "";
  let org_selected = "";
  let dest_selected = "";
  let route_f_selected = "";
  let fecha_v, hora_v;
  let fechas = [],
    horas = [];
  let units_list_p;
  $: {
    units_list_p = $units_list;
    $nicks_list;
    units_list_p.sort(units_order);
  }
  function units_order(a, b) {
    if ($nicks_list[b] < $nicks_list[a]) {
      return 1;
    } else {
      return -1;
    }
  }
  fechas = [];
  horas = [];
  for (let x = 0; x < 3; x++) {
    fechas.push([
      moment().startOf("day").add(x, "days").valueOf() / 1000,
      moment().startOf("day").add(x, "days").format("DD-MM-YY"),
    ]);
  }
  for (let x = 0; x < 48; x++) {
    horas.push([
      x * 30 * 60,
      moment()
        .startOf("day")
        .add(x * 30, "minutes")
        .format("HH:mm"),
    ]);
  }
  let route_list_ = [];
  $: {
    let route_list_p = [];
    $geos_class;
    $geos;
    for (let x in $route_list) {
      route_list_p.push($route_list[x]);
      let geo = geo_search(
        $route_list[x][2][0] / 10000,
        $route_list[x][2][1] / 10000
      );
      route_list_p[x][2][2] = geo[2];
      geo = geo_search(
        $route_list[x][3][0] / 10000,
        $route_list[x][3][1] / 10000
      );
      route_list_p[x][3][2] = geo[2];
    }
    route_list_ = [...route_list_p];
  }
  function geo_search(lat, lng) {
    let box_size = 0.0001797 * 10; // 200 metros por celula
    let p = Math.floor((lat - 14.5408) / box_size);
    let q = Math.floor((lng + 118.2689) / box_size);
    let key_close_list = [
      p + "_" + q,
      p + "_" + (q + 1),
      p + "_" + (q - 1),
      p + 1 + "_" + q,
      p - 1 + "_" + q,
      p + 1 + "_" + (q + 1),
      p - 1 + "_" + (q - 1),
      p + 1 + "_" + (q - 1),
      p - 1 + "_" + (q + 1),
    ];
    let geos_arround = [];
    for (let w in key_close_list) {
      if ($geos_map[key_close_list[w]]) {
        for (let s in $geos_map[key_close_list[w]]) {
          if (!geos_arround.includes($geos_map[key_close_list[w]][s])) {
            geos_arround.push($geos_map[key_close_list[w]][s]);
          }
        }
      }
    }
    let found = false;
    for (let x in $geos) {
      // filtro
      if (geos_arround.includes($geos[x][0])) {
        for (let y in $geos[x][1]) {
          let lat_ = $geos[x][1][y][0];
          let lng_ = $geos[x][1][y][1];
          if (get_km(lat, lng, lat_, lng_) <= 0.13) {
            if (typeof $geos_class[$geos[x][0]] != "undefined") {
              return [
                $geos_class[$geos[x][0]][0],
                $geos_class[$geos[x][0]][1],
                $geos[x][0],
              ];
            } else {
              if (typeof $geos_joined[$geos[x][0]] == "undefined") {
                return [
                  "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                  1,
                  $geos[x][0],
                ];
              } else {
                for (let h in $geos_joined[$geos[x][0]]) {
                  if (
                    typeof $geos_class[$geos_joined[$geos[x][0]][h]] !=
                    "undefined"
                  ) {
                    return [
                      $geos_class[$geos_joined[$geos[x][0]][h]][0],
                      $geos_class[$geos_joined[$geos[x][0]][h]][1],
                      $geos_joined[$geos[x][0]][h],
                    ];
                  }
                }
                return [
                  "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                  1,
                  $geos[x][0],
                ];
              }
            }
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    return ["Geocerca_automatica_", 1, ""];
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  let eco_add_selected = [];
  let saved_ok = false;
  let saved_type, saved_text;
  function save_travel() {
    let future_travel_ex = true;
    let sec_dests = [];
    let sec_routes = [];
    if (
      eco_selected != "" &&
      org_selected != "" &&
      dest_selected != "" &&
      oper_selected != "" &&
      fecha_v != "" &&
      hora_v !== "" &&
      future_travel_ex &&
      eco_add_selected[eco_add_selected.length - 1] != ""
    ) {
      let fecha_total = fecha_v + hora_v;
      socket_query.emit(
        "travel_save",
        eco_selected,
        org_selected,
        dest_selected,
        oper_selected,
        route_f_selected,
        fecha_total,
        sec_dests,
        sec_routes,
        eco_add_selected,
        (data) => {
          if (data == "ok") {
            saved_ok = true;
            saved_text = "Viaje guardado";
            saved_type = "alert-success";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          } else {
            saved_ok = true;
            saved_text = "Error en cargar viaje";
            saved_type = "alert-danger";
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    } else {
      saved_ok = true;
      saved_text = "Completa todos los datos";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    }
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<div class="container-fluid" style="padding:0%;">
  <nav class="navbar fixed-top navbar-dark omni_new">
    <a class="navbar-brand" href="/" on:click|preventDefault
      ><i class="material-icons md-85" style="vertical-align:middle">menu</i> Vector
      agendar viajes</a
    >
  </nav>
  <div
    class="row justify-content-center px-4"
    style="padding-top:65px;font-size: 1.3rem;margin:0px;"
  >
    <div class="col-12">
      {#if !saved_ok}
        <form>
          <div class="mb-3">
            <p>Selecciona Unidad</p>
          </div>
          <div class="mb-3">
            <input
              list="lista_unidades"
              placeholder="Unidad"
              size="8"
              on:change={(event) => {
                eco_selected = "";
                for (let w in $nicks_list) {
                  if ($nicks_list[w] == event.target.value) {
                    eco_selected = w;
                  }
                }
              }}
            />
            <datalist id="lista_unidades">
              {#each units_list_p as item, i}
                <option
                  value={typeof $nicks_list[item] != "undefined"
                    ? $nicks_list[item]
                    : item}
                />{/each}
            </datalist>
          </div>
          <div class="mb-3">
            <p>Selecciona Operador</p>
          </div>
          <div class="mb-3">
            <input
              list="driver_list"
              placeholder="Operador"
              size="20"
              on:change={(event) => {
                oper_selected = "";
                for (let x in $drivers_list) {
                  if ($drivers_list[x][1]["name"] == event.target.value) {
                    oper_selected = $drivers_list[x][0];
                  }
                }
              }}
            />
            <datalist id="driver_list">
              {#each $drivers_list as item, i}
                <option value={item[1]["name"]} />{/each}
            </datalist>
          </div>
          <div class="mb-3">
            <p>Selecciona fecha</p>
          </div>
          <div class="mb-3">
            <input
              list="fecha"
              placeholder="Fecha"
              size="8"
              on:change={(event) => {
                fecha_v = "";
                for (let w in fechas) {
                  if (fechas[w][1] == event.target.value) {
                    fecha_v = fechas[w][0];
                  }
                }
              }}
            />
            <datalist id="fecha">
              {#each fechas as item, i}
                <option value={item[1]} />{/each}
            </datalist>
            <input
              list="hora"
              placeholder="Hora"
              size="5"
              on:change={(event) => {
                hora_v = "";
                for (let w in horas) {
                  if (horas[w][1] == event.target.value) {
                    hora_v = horas[w][0];
                  }
                }
              }}
            />
            <datalist id="hora">
              {#each horas as item, i}
                <option value={item[1]} />{/each}
            </datalist>
          </div>
          <div class="mb-3">
            <p>Selecciona origen</p>
          </div>
          <div class="mb-3">
            <input
              list="org_list"
              placeholder="Origen"
              size="20"
              on:change={(event) => {
                org_selected = "";
                for (let x in $geos_class_list) {
                  if ($geos_class_list[x][1] == event.target.value) {
                    org_selected = $geos_class_list[x][0];
                  }
                }
              }}
            />
            <datalist id="org_list">
              {#each $geos_class_list as item, i}
                <option value={item[1]} />{/each}
            </datalist>
          </div>
          <div class="mb-3">
            <p>Selecciona destino</p>
          </div>
          <div class="mb-3">
            <input
              list="dest_list"
              placeholder="Destino"
              size="20"
              on:change={(event) => {
                dest_selected = "";
                for (let x in $geos_class_list) {
                  if ($geos_class_list[x][1] == event.target.value) {
                    dest_selected = $geos_class_list[x][0];
                  }
                }
              }}
            />
            <datalist id="dest_list">
              {#each $geos_class_list as item, i}
                <option value={item[1]} />{/each}
            </datalist>
          </div>
          <div class="mb-3">
            <p>Selecciona ruta</p>
          </div>
          <div class="mb-3">
            <input
              list="ruta_list"
              placeholder="Ruta"
              size="20"
              on:change={(event) => {
                route_f_selected = "";
                for (let x in route_list_) {
                  if (route_list_[x][1] == event.target.value) {
                    route_f_selected = route_list_[x][0];
                  }
                }
              }}
            />
            <datalist id="ruta_list">
              {#each route_list_ as item, i}
                {#if (org_selected == "" || org_selected == item[2][2] || $geos_class_join[org_selected][2] == item[2][2]) && (dest_selected == "" || dest_selected == item[3][2] || $geos_class_join[dest_selected][2] == item[3][2])}
                  <option value={item[1]} />{/if}
              {/each}
            </datalist>
          </div>
          <div class="mb-3" style="text-align:end">
            <button
              on:click={() => {
                save_travel();
              }}
              type="button"
              class="btn btn-success"
            >
              Guardar
            </button>
          </div>
        </form>
      {/if}
    </div>
  </div>
</div>

<style>
  .alert-top {
    position: fixed;
    top: 5rem;
    left: 25%;
    width: 50%;
    z-index: 1050;
  }
  .omni_new {
    background-color: #3b1353;
    color: white;
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.8rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
</style>
