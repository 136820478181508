<script>
  export let travel_db;
  export let routes_db;
  export let units_travel_db;
  export let socket_query;
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    travels_list,
    route_list_d,
    geos_class_list,
    geos_full,
    poli,
    poli_list,
  } from "./stores.js";

  const style = document.createElement("style");
  style.innerHTML = `
    .custom-label {
      background-color: white; /* Fondo blanco */
      padding: 2px 5px; /* Espaciado interno */
      border-radius: 3px; /* Bordes redondeados */
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); /* Sombra */
      white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    }
  `;
  document.head.appendChild(style);

  let map;
  function initMap() {
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    map.fitBounds(bounds);
  }

  onDestroy(() => {
    socket_query.off("gaia_line");
    window.removeEventListener("message", handleMessage);
  });

  let selected = !localStorage.getItem("selected_report")
    ? 0
    : parseInt(localStorage.getItem("selected_report"));
  $: {
    localStorage.setItem("selected_report", selected);
  }
  let reportes = [
    "Viajes monitoreados",
    "Alertas monitoreadas",
    "Viajes históricos",
    "Actividad de Operadores",
    "Telemetría de flota",
    "Estimador de viaje",
    "Reporte de uso de unidades",
  ];

  function maping(report_id) {
    selected = report_id;
  }
  function data_table_on() {
    const dataTable = mdc.dataTable.MDCDataTable.attachTo(
      document.querySelector(".mdc-data-table")
    );
    const chipSet = mdc.chips.MDCChipSet.attachTo(
      document.querySelector(".mdc-chip-set")
    );
    //document.querySelectorAll('.lista-a').forEach(button => {mdc.ripple.MDCRipple.attachTo(button);button.unbounded = true;})
  }
  function data_table_on2() {
    const dataTable = mdc.dataTable.MDCDataTable.attachTo(
      document.querySelector(".mdc-data-table")
    );
  }
  let travel_report = [
    {
      date: "07/07/20 02:30",
      id: "M-003",
      oper: "Pedro Sanchez",
      status: 0,
      org: "Plaza de Cobro Atlacomulco",
      dest: "Talachera del Bravo",
      alerts: [
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
      ],
    },
    {
      date: "07/07/20 02:30",
      id: "M-003",
      oper: "Pedro Sanchez",
      status: 1,
      org: "Plaza de Cobro Atlacomulco",
      dest: "Talachera del Bravo",
      alerts: [
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
      ],
    },
    {
      date: "07/07/20 02:30",
      id: "M-003",
      oper: "Pedro Sanchez",
      status: 2,
      org: "Plaza de Cobro Atlacomulco",
      dest: "Talachera del Bravo",
      alerts: [],
    },
    {
      date: "07/07/20 02:30",
      id: "M-003",
      oper: "Pedro Sanchez",
      status: 3,
      org: "Plaza de Cobro Atlacomulco",
      dest: "Talachera del Bravo",
      alerts: [
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
        {
          name: "Unidad fuera de ruta",
          date_on: "07/07/20 01:30",
          lat: 28.471885,
          lon: -105.8336195,
          date_off: "07/07/20 01:35",
        },
      ],
    },
  ];
  let expaned = -1;
  async function load_travels() {
    travel_report = [];
    let travel;
    if ($travels_list != null) {
      let travels_list_ = [...$travels_list];
      for (let x in travels_list_.reverse()) {
        try {
          travel = await travel_db.getItem(travels_list_[x]);
        } catch (e) {
          console.log("Error leyendo travel", e);
        }
        if (travel != null) {
          travel_report.push(JSON.parse(travel));
        }
      }
      travel_report = [...travel_report];
    }
  }
  $: {
    $travels_list;
    load_travels();
  }
  let f_concluido = true,
    f_en_proceso = true,
    f_borrado = false,
    f_parcial = true;
  document.body.addEventListener("MDCChip:selection", (chip) => {
    if (chip.detail.chipId == "f_concluido") {
      f_concluido = chip.detail.selected;
    }
    if (chip.detail.chipId == "f_en_proceso") {
      f_en_proceso = chip.detail.selected;
    }
    if (chip.detail.chipId == "f_borrado") {
      f_borrado = chip.detail.selected;
    }
    if (chip.detail.chipId == "f_parcial") {
      f_parcial = chip.detail.selected;
    }
  });
  let detalle_modal = false;
  let map_routes;
  let alert_list_modal = [];
  let recorrido_ruta = [];
  let route_marks = [];
  let alert_marks = [];
  let ruta_real = [];
  let m_inicio;
  let m_fin;
  let m_stops = [];
  let colors_line = [
    "#2196f3",
    "#e91e63",
    "#03a9f4",
    "#9c27b0",
    "#00bcd4",
    "#673ab7",
    "#009688",
    "#3f51b5",
    "#4caf50",
    "#ff5722",
    "#8bc34a",
    "#ff9800",
    "#cddc39",
    "#ffc107",
    "#607d8b",
    "#ffeb3b",
    "#9e9e9e",
    "#212121",
    "#f44336",
  ];
  let letras = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  let coordenada_real = [];
  let total_distance = 0;
  let inicio_real;
  let fin_real;
  let idle_time = 0;
  let avg_speed = 0;
  let avg_speed_c = 0;
  let max_speed = 0;
  let charts_modal;
  let current_chart = 0;
  let driver_name = "";
  let inicio_programado;
  let unit_name = "";
  let coordenada_alertas = [];
  let chat_list = [];
  let changes_history = [];
  let id_travel;
  async function show_detalle_map() {
    selected_pos = -1;
    selected_alert = -1;
    alert_list_modal = [];
    simulacion_history = false;
    chats_expanded = false;
    travel_changes_expanded = false;
    let travel;
    coordenada_alertas = [];
    let bounds = new google.maps.LatLngBounds();
    try {
      travel = await travel_db.getItem(expaned);
    } catch (e) {
      console.log("Error leyendo travel", e);
    }
    driver_name = "Conductor";
    inicio_programado = null;
    unit_name = "Unidad";
    if (travel != null) {
      travel = JSON.parse(travel);
      if (travel.changes) changes_history = travel.changes;
      else changes_history = [];
      if (travel.id_travel) id_travel = travel.id_travel;
      else id_travel = null;
      driver_name = $drivers[travel.oper]
        ? $drivers[travel.oper].name
        : "Conductor";
      inicio_programado = travel.date_ini;
      unit_name = $nicks_list[travel.id] ? $nicks_list[travel.id] : "Unidad";
      chat_list = travel.chat ? travel.chat : [];
      for (let x in travel.alerts) {
        alert_list_modal.push([
          [
            moment(
              new Date(travel.alerts[x].alerts_crtl.date_on * 1000)
            ).format("DD/MM/YY HH:mm"),
          ],
          [travel.alerts[x].alerts.name],
          {
            lat: travel.alerts[x].pos_data.lat,
            lng: travel.alerts[x].pos_data.lon,
          },
        ]);
        coordenada_alertas.push({
          lat: travel.alerts[x].pos_data.lat,
          lng: travel.alerts[x].pos_data.lon,
          date: travel.alerts[x].alerts_crtl.counter_date,
        });
        bounds.extend({
          lat: travel.alerts[x].pos_data.lat,
          lng: travel.alerts[x].pos_data.lon,
        });
      }
      alert_list_modal = [...alert_list_modal];
    }

    let coordenada_ruta = [];
    let route;
    if (travel.route != "") {
      try {
        route = await routes_db.getItem(travel.route);
      } catch (e) {
        console.log("Error leyendo routa", e);
      }
      if (route != null) {
        let lat_org = route["pos_start"][0];
        let lng_org = route["pos_start"][1];
        coordenada_ruta.push([]);
        for (let x in route["pos"]) {
          let lat = (lat_org - route["pos"][x][0]) / 10000;
          let lng = (lng_org - route["pos"][x][1]) / 10000;
          coordenada_ruta[coordenada_ruta.length - 1].push({
            lat: lat,
            lng: lng,
          });
          bounds.extend({ lat: lat, lng: lng });
        }
      }
    }
    if (travel.sec_routes.length > 0) {
      for (let x in travel.sec_routes) {
        try {
          route = await routes_db.getItem(travel.sec_routes[x]);
        } catch (e) {
          console.log("Error leyendo routa", e);
        }
        if (route != null) {
          let lat_org = route["pos_start"][0];
          let lng_org = route["pos_start"][1];
          coordenada_ruta.push([]);
          for (let x in route["pos"]) {
            let lat = (lat_org - route["pos"][x][0]) / 10000;
            let lng = (lng_org - route["pos"][x][1]) / 10000;
            coordenada_ruta[coordenada_ruta.length - 1].push({
              lat: lat,
              lng: lng,
            });
            bounds.extend({ lat: lat, lng: lng });
          }
        }
      }
    }

    coordenada_real = [];
    coordenada_real_ = [];
    total_distance = 0;
    let last_lat;
    let last_lng;
    let last_coordenada_real = 0;
    inicio_real = null;
    fin_real = null;
    avg_speed = 0;
    avg_speed_c = 0;
    max_speed = 0;
    idle_time = 0;
    if (typeof travel.start != "undefined") {
      last_coordenada_real = travel.start;
      inicio_real = travel.start;
      fin_real = travel.end ? travel.end : travel.start;
      //total_distance = travel.distance;
      let rec_p = JSON.parse(await units_travel_db.getItem(travel.id));
      for (var x in rec_p) {
        if (typeof rec_p[x]["p"] != "undefined") {
          for (var w in rec_p[x]["p"]) {
            if (
              rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60 > travel.start &&
              (typeof travel.end == "undefined" ||
                rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60 <= travel.end)
            ) {
              if (!last_lat) {
                last_lat = (rec_p[x]["ps"][0] - rec_p[x]["p"][w][0]) / 10000;
                last_lng = (rec_p[x]["ps"][1] - rec_p[x]["p"][w][1]) / 10000;
              }
              let lat = (rec_p[x]["ps"][0] - rec_p[x]["p"][w][0]) / 10000;
              let lng = (rec_p[x]["ps"][1] - rec_p[x]["p"][w][1]) / 10000;
              total_distance =
                total_distance + get_km(last_lat, last_lng, lat, lng);
              coordenada_real.push({
                lat: lat,
                lng: lng,
                date: rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60,
                stay: rec_p[x]["p"][w][4]
                  ? rec_p[x]["p"][w][4] - rec_p[x]["p"][w][3]
                  : 0,
                speed: rec_p[x]["p"][w][2],
                distance: total_distance,
              });
              if (rec_p[x]["p"][w][2] > 15) {
                avg_speed = avg_speed + rec_p[x]["p"][w][2];
                avg_speed_c++;
              }
              max_speed =
                rec_p[x]["p"][w][2] > max_speed
                  ? rec_p[x]["p"][w][2]
                  : max_speed;
              last_coordenada_real = rec_p[x]["ds"] + rec_p[x]["p"][w][3] * 60;
              bounds.extend({ lat: lat, lng: lng });
              last_lat = lat;
              last_lng = lng;
            }
          }
        }
      }
    }
    console.log("total_distance", total_distance);
    for (let x in $travels[travel.id].travel) {
      if (typeof $travels[travel.id].travel[x].p != "undefined") {
        for (let y in $travels[travel.id].travel[x].p) {
          if (
            $travels[travel.id].travel[x]["ds"] +
              $travels[travel.id].travel[x]["p"][y][3] * 60 >
              last_coordenada_real &&
            (typeof travel.end == "undefined" ||
              $travels[travel.id].travel[x]["ds"] +
                $travels[travel.id].travel[x]["p"][y][3] * 60 <=
                travel.end)
          ) {
            let lat =
              ($travels[travel.id].travel[x]["ps"][0] -
                $travels[travel.id].travel[x]["p"][y][0]) /
              10000;
            let lng =
              ($travels[travel.id].travel[x]["ps"][1] -
                $travels[travel.id].travel[x]["p"][y][1]) /
              10000;
            coordenada_real.push({
              lat: lat,
              lng: lng,
              date:
                $travels[travel.id].travel[x]["ds"] +
                $travels[travel.id].travel[x]["p"][y][3] * 60,
              stay: $travels[travel.id].travel[x]["p"][y][4]
                ? $travels[travel.id].travel[x]["p"][y][4]
                : 0,
              speed: $travels[travel.id].travel[x]["p"][y][2],
            });
            bounds.extend({ lat: lat, lng: lng });
          }
        }
      }
    }

    if (coordenada_real.length == 0 && travel.end_pos != null) {
      coordenada_real.push({
        lat: travel.end_pos[0],
        lng: travel.end_pos[1],
        date: travel.end,
        stay: 0,
        speed: 0,
      });
      bounds.extend({ lat: travel.end_pos[0], lng: travel.end_pos[1] });
    }
    coordenada_real = [...coordenada_real];
    idle_time = coordenada_real.reduce(
      (sum, item) => sum + (item["stay"] || 0),
      0
    );
    function initMap() {
      map_routes = new google.maps.Map(document.getElementById("map_routes"), {
        center: { lat: 24.458489, lng: -102.217231 },
        zoom: 5,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.natural",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
        ],
      });

      // check if bounds are set if not set bounds to default
      if (bounds.isEmpty()) {
        bounds.extend({ lat: 32.534353, lng: -117.123783 });
        bounds.extend({ lat: 21.137926, lng: -86.740844 });
        bounds.extend({ lat: 14.534659, lng: -92.231633 });
      }
      map_routes.fitBounds(bounds);

      let zIndex = 0;

      for (let x in ruta_real) {
        if (typeof ruta_real[x] != "undefined") {
          ruta_real[x].setMap(null);
        }
      }
      ruta_real.push(
        new google.maps.Polyline({
          path: coordenada_real,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map_routes,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#FF0000",
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        })
      );

      if (coordenada_real.length > 1) osrm_ruta_real(coordenada_real, zIndex);

      zIndex++;
      for (let x in recorrido_ruta) {
        if (typeof recorrido_ruta[x] != "undefined") {
          recorrido_ruta[x].setMap(null);
        }
      }
      for (let z in route_marks) {
        if (typeof route_marks[z] != "undefined") {
          route_marks[z].setMap(null);
        }
      }
      for (let x in coordenada_ruta) {
        recorrido_ruta[x] = new google.maps.Polyline({
          path: coordenada_ruta[x],
          geodesic: true,
          strokeColor: colors_line[x],
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map_routes,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: colors_line[x],
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        });

        zIndex++;
        route_marks[x] = new google.maps.Marker({
          position: coordenada_ruta[x][0],
          map: map_routes,
          animation: google.maps.Animation.DROP,
          icon: "img/marker" + (x < 26 ? letras[x] : letras[x - 26]) + ".png",
          //label: (x<26?letras[x]:letras[x-26]),
          zIndex: zIndex,
        });
        if (x == coordenada_ruta.length - 1) {
          zIndex++;
          route_marks[parseInt(x) + 1] = new google.maps.Marker({
            position: coordenada_ruta[x][coordenada_ruta[x].length - 1],
            map: map_routes,
            animation: google.maps.Animation.DROP,
            icon:
              "img/marker" +
              (parseInt(x) + 1 < 26
                ? letras[parseInt(x) + 1]
                : letras[parseInt(x) + 1 - 26]) +
              ".png",
            //label: ((parseInt(x)+1)<26?letras[parseInt(x)+1]:letras[(parseInt(x)+1)-26]),
            zIndex: zIndex,
          });
        }
      }

      zIndex++;
      for (let z in alert_marks) {
        if (typeof alert_marks[z] != "undefined") {
          alert_marks[z].setMap(null);
        }
      }
      for (let x in coordenada_alertas) {
        route_marks[x] = new google.maps.Marker({
          position: coordenada_alertas[x],
          map: map_routes,
          animation: google.maps.Animation.DROP,
          icon: "img/markerAlt.png",
          zIndex: zIndex,
        });
      }

      zIndex++;
      if (typeof m_inicio != "undefined") {
        m_inicio.setMap(null);
      }
      m_inicio = new google.maps.Marker({
        position: coordenada_real[0],
        map: map_routes,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-start.png",
        zIndex: zIndex,
      });

      // Stops
      zIndex++;
      for (let x in m_stops) {
        if (typeof m_stops[x] != "undefined") {
          m_stops[x].setMap(null);
        }
      }
      for (let x in coordenada_real) {
        if (coordenada_real[x].stay > 0) {
          m_stops[x] = new google.maps.Marker({
            position: coordenada_real[x],
            map: map_routes,
            animation: google.maps.Animation.DROP,
            icon: "img/dd-pause.png",
            zIndex: zIndex,
          });
        }
      }

      zIndex++;
      if (typeof m_fin != "undefined") {
        m_fin.setMap(null);
      }
      m_fin = new google.maps.Marker({
        position: coordenada_real[coordenada_real.length - 1],
        map: map_routes,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-end.png",
        zIndex: zIndex,
      });
    }
    initMap();

    time_chart();
  }
  let selected_alert = -1;
  function maping_alert(i) {
    selected_pos = -1;
    selected_alert = i;
    map_routes.panTo(alert_list_modal[i][2]);
    map_routes.setZoom(16);
  }
  function reporte_csv() {
    var csv = "";
    var regex = /( |<([^>]+)>)/gi;
    regex = /<[^>]*>?/gm;
    regex = /(<([^>]+)>)/gi;

    let no_word = [
      "verified_user",
      "autorenew",
      "delete",
      "rule",
      "star_rate",
      "more_vert",
      "done_outline",
      "report_problem",
    ];

    var table = document.getElementById(
      selected == 0 ? "travels_table" : selected == 1 ? "alerts_table" : ""
    );
    var rowLength = table.rows.length;

    let j_ini = selected == 0 ? 1 : 0;

    for (let i = 0; i < rowLength; i++) {
      var oCells = table.rows.item(i).cells;
      var cellLength = oCells.length;
      for (var j = j_ini; j < cellLength; j++) {
        var cellVal = oCells.item(j).innerHTML;
        cellVal = cellVal.replace(regex, "");
        for (let z in no_word) {
          cellVal = cellVal.replace(no_word[z], "");
        }
        csv = csv + cellVal + ",";
      }
      csv = csv.slice(0, -1);
      csv = csv + "\n";
    }

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download =
      selected == 0
        ? "Reporte_viajes.csv"
        : selected == 1
          ? "Reporte_alerts.csv"
          : "";
    hiddenElement.click();
  }
  let org_selected,
    dest_selected,
    tunidad_selected,
    ejes_selected,
    ejes2_selected,
    rendimiento = 2;
  let org_selected_,
    dest_selected_,
    tunidad_selected_,
    ejes_selected_,
    ejes2_selected_,
    rendimiento_ = 2;
  let costo_caseta = 0,
    long_km = 0,
    tiempo_min = 0,
    costo_diesel = 0;
  let gaia_reportes;
  let gaia_inicio, gaia_fin;
  let gaia_runing = false;
  let gaia_free = true;
  $: {
    org_selected,
      dest_selected,
      tunidad_selected,
      ejes_selected,
      ejes2_selected;
    if (
      rendimiento != null &&
      org_selected != null &&
      dest_selected != null &&
      tunidad_selected != null &&
      ejes_selected != null &&
      ejes2_selected != null &&
      gaia_free
    ) {
      (costo_caseta = 0), (long_km = 0), (tiempo_min = 0);
      costo_diesel = 0;
      if (typeof gaia_casetas != "undefined") {
        for (let r in gaia_casetas) {
          gaia_casetas[r].setMap(null);
        }
        gaia_casetas = [];
      }
      if (typeof gaia_reportes != "undefined") {
        gaia_reportes.setMap(null);
      }
      if (typeof gaia_line != "undefined") {
        for (let x in gaia_line) {
          gaia_line[x].setMap(null);
        }
        gaia_line = [];
      }
      if (typeof gaia_inicio != "undefined") {
        gaia_inicio.setMap(null);
      }
      if (typeof gaia_fin != "undefined") {
        gaia_fin.setMap(null);
      }
      let bounds = new google.maps.LatLngBounds();
      bounds.extend({ lat: 32.534353, lng: -117.123783 });
      bounds.extend({ lat: 21.137926, lng: -86.740844 });
      bounds.extend({ lat: 14.534659, lng: -92.231633 });
      map.fitBounds(bounds);
      gaia_free = false;
      gaia();
    }
  }

  function time_code(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("m[min]");
    } else {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("H[h] m[min]");
    }
  }

  let gaia_casetas = [];
  let gaia_casetas_l = [];
  function gaia() {
    if (gaia_runing) return;
    gaia_runing = true;
    let org_ = get_org_geo(org_selected);
    let dest_ = get_org_geo(dest_selected);
    if (org_.length > 0 && dest_.length > 0) {
      let v =
        tunidad_selected == "Motocicleta"
          ? 0
          : tunidad_selected == "Automóvil"
            ? 1
            : tunidad_selected == "Autobús"
              ? parseInt(ejes_selected)
              : tunidad_selected == "Camión"
                ? parseInt(ejes_selected) + 3
                : 1;
      let e = parseInt(ejes2_selected);
      socket_query.emit(
        "gaia2",
        org_[0][0],
        org_[0][1],
        dest_[0][0],
        dest_[0][1],
        v,
        e,
        tipo_ruta,
        (data) => {
          if (data != "error") {
            gaia_runing = false;
            console.log(data);

            costo_caseta = 0;
            long_km = 0;
            tiempo_min = 0;
            gaia_casetas_l = [];
            if (typeof gaia_casetas != "undefined") {
              for (let r in gaia_casetas) {
                gaia_casetas[r].setMap(null);
              }
              gaia_casetas = [];
            }
            let geojson = [];
            let bounds = new google.maps.LatLngBounds();
            costo_diesel = data.gas[2].costo;
            for (let x in data.data) {
              costo_caseta =
                costo_caseta +
                data.data[x].costo_caseta +
                data.data[x].eje_excedente;
              long_km = long_km + data.data[x].long_m;
              tiempo_min = tiempo_min + data.data[x].tiempo_min;

              geojson.push({
                lat: data.data[x].geojson[1],
                lng: data.data[x].geojson[0],
              });
              bounds.extend({
                lat: data.data[x].geojson[1],
                lng: data.data[x].geojson[0],
              });

              if (data.data[x].costo_caseta > 0) {
                gaia_casetas.push(
                  new google.maps.Marker({
                    position: {
                      lat: data.data[x].punto_caseta[1],
                      lng: data.data[x].punto_caseta[0],
                    },
                    animation: google.maps.Animation.DROP,
                    map: map,
                    icon: "img/markerAlt.png",
                    zIndex: 0,
                  })
                );
                gaia_casetas_l.push([
                  data.data[x].direccion,
                  data.data[x].costo_caseta + data.data[x].eje_excedente,
                  data.data[x].punto_caseta[1],
                  data.data[x].punto_caseta[0],
                ]);
              }
            }
            long_km = Math.round(long_km / 1000);
            tiempo_min = time_code(tiempo_min);

            let zIndex = 0;
            gaia_reportes = new google.maps.Polyline({
              path: geojson,
              geodesic: true,
              strokeColor: "#FF0000",
              strokeOpacity: 1.0,
              strokeWeight: 2.5,
              map: map,
              zIndex: zIndex,
            });

            zIndex++;
            if (typeof gaia_inicio != "undefined") {
              gaia_inicio.setMap(null);
            }
            gaia_inicio = new google.maps.Marker({
              position: { lat: org_[0][0], lng: org_[0][1] },
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/markerA.png",
              zIndex: zIndex,
            });

            if (typeof gaia_fin != "undefined") {
              gaia_fin.setMap(null);
            }
            gaia_fin = new google.maps.Marker({
              position: { lat: dest_[0][0], lng: dest_[0][1] },
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/markerB.png",
              zIndex: zIndex,
            });

            map.fitBounds(bounds);
            //org_selected=null;dest_selected=null;tunidad_selected=null;ejes_selected=null;ejes2_selected=null;
            if (tipo_ruta == "o") osrm(geojson);
          } else {
            gaia_runing = false;
            new_travel();
            saved_ok = true;
            saved_text = "Ruta no encontrada, intente de nuevo.";
            saved_type = "alert-danger";
            setTimeout(function () {
              saved_ok = false;
            }, 8000);
          }
        }
      );
    } else {
      gaia_runing = false;
      new_travel();
    }
  }

  function get_org_geo(geo) {
    let anti_f = 10;
    let last_join = "";
    let res = [];
    if ($geos_full[geo] != null) {
      geo = $geos_full[geo];
      while (typeof geo.join != "undefined" || anti_f == 0) {
        last_join = geo.join;
        geo = $geos_full[geo.join];
        anti_f--;
      }
      for (let y in geo["pos"]) {
        let lat = geo["pos"][y][0];
        let lng = geo["pos"][y][1];
        res.push([lat, lng]);
      }
    }
    return res;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  function new_travel() {
    org_selected = null;
    dest_selected = null;
    tunidad_selected = null;
    ejes_selected = null;
    ejes2_selected = null;
    org_selected_ = "";
    dest_selected_ = "";
    tunidad_selected_ = "";
    ejes_selected_ = "";
    ejes2_selected_ = "";
    rendimiento_ = "2";
    (costo_caseta = 0), (long_km = 0), (tiempo_min = 0);
    costo_diesel = 0;
    if (typeof gaia_casetas != "undefined") {
      for (let r in gaia_casetas) {
        gaia_casetas[r].setMap(null);
      }
      gaia_casetas = [];
    }
    if (typeof gaia_reportes != "undefined") {
      gaia_reportes.setMap(null);
    }
    if (typeof gaia_line != "undefined") {
      for (let x in gaia_line) {
        gaia_line[x].setMap(null);
      }
      gaia_line = [];
    }
    if (typeof gaia_inicio != "undefined") {
      gaia_inicio.setMap(null);
    }
    if (typeof gaia_fin != "undefined") {
      gaia_fin.setMap(null);
    }
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map.fitBounds(bounds);
    gaia_casetas_l = [];
    gaia_free = true;
  }
  async function osrm(coordenada) {
    // OSRM
    let coordenada_osrm = "";
    let radius_osrm = "";
    for (let t in coordenada) {
      coordenada_osrm =
        coordenada_osrm + coordenada[t].lng + "," + coordenada[t].lat + ";";
    }
    for (let t in coordenada) {
      radius_osrm = radius_osrm + "20%3B";
    }
    let coordenada_first = coordenada[0];
    let coordenada_last = coordenada[coordenada.length - 1];

    coordenada_osrm = coordenada_osrm.slice(0, -1);
    radius_osrm = radius_osrm.slice(0, -3);
    let osrm_r = await fetch(
      "https://route.omnitracs.online/match/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full&radiuses=" +
        radius_osrm
    );
    osrm_r = await osrm_r.json();
    let okok = false;
    if (osrm_r.code == "Ok") {
      if (osrm_r.matchings[0].confidence > 0.1) {
        osrm_r.matchings.push({
          geometry: {
            coordinates: [[coordenada_last.lng, coordenada_last.lat]],
          },
        });
        okok = true;
        coordenada = [];
        for (let t in osrm_r.matchings) {
          let last_lat, last_lon;
          for (let y in osrm_r.matchings[t].geometry.coordinates) {
            coordenada.push({
              lat: osrm_r.matchings[t].geometry.coordinates[y][1],
              lng: osrm_r.matchings[t].geometry.coordinates[y][0],
            });
            last_lat = osrm_r.matchings[t].geometry.coordinates[y][1];
            last_lon = osrm_r.matchings[t].geometry.coordinates[y][0];
          }
          if (typeof osrm_r.matchings[parseInt(t) + 1] != "undefined") {
            let coordenada_osrm_c =
              last_lon +
              "," +
              last_lat +
              ";" +
              osrm_r.matchings[parseInt(t) + 1].geometry.coordinates[0][0] +
              "," +
              osrm_r.matchings[parseInt(t) + 1].geometry.coordinates[0][1];
            let osrm_reco = await fetch(
              "https://route.omnitracs.online/route/v1/driving/" +
                coordenada_osrm_c +
                "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full"
            );
            osrm_reco = await osrm_reco.json();
            if (osrm_reco.code == "Ok") {
              for (let q in osrm_reco.routes[0].geometry.coordinates) {
                coordenada.push({
                  lat: osrm_reco.routes[0].geometry.coordinates[q][1],
                  lng: osrm_reco.routes[0].geometry.coordinates[q][0],
                });
              }
            }
          } else {
            console.log(last_lat, last_lon);
          }
        }
        coordenada.unshift(coordenada_first);
        coordenada.push(coordenada_last);
      }
      console.log("confidence", osrm_r.matchings[0].confidence);
    }
    //---------
    if (okok) {
      if (typeof gaia_reportes != "undefined") {
        gaia_reportes.setMap(null);
      }
      gaia_reportes = new google.maps.Polyline({
        path: coordenada,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: 0,
      });
    }
  }
  let saved_ok = false;
  let saved_type, saved_text;
  let tipo_ruta = "o";
  let gaia_line = [];
  socket_query.on("gaia_line", async function (data) {
    if (typeof gaia_reportes != "undefined") {
      gaia_reportes.setMap(null);
    }
    if (typeof gaia_line != "undefined") {
      for (let x in gaia_line) {
        gaia_line[x].setMap(null);
      }
      gaia_line = [];
    }
    for (let x in data) {
      let coordenada = [];
      for (let y in data[x]) {
        coordenada.push({ lat: data[x][y][1], lng: data[x][y][0] });
      }
      gaia_line.push(
        new google.maps.Polyline({
          path: coordenada,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map,
          zIndex: 0,
        })
      );
    }
  });

  let formulario_modal = false;
  let reporte_selected = [];
  let formats_driver_url =
    "https://vector-trip-report-creator.omnitracs.online";
  let iframe_reports;
  let units_usage_url = "";
  let ifram_unit_usage;
  async function iframe_reports_on() {
    iframe_reports = document.getElementById("iframe_reports");
  }
  function handleMessage(event) {
    console.log(
      "Mensaje recibido del iframe:",
      event.data,
      event.origin,
      event.data.type
    );
    switch (event.origin) {
      case formats_driver_url:
        switch (event.data.type) {
          case "command":
            iframe_reports = document.getElementById("iframe_reports");
            iframe_reports.contentWindow.postMessage(
              {
                type: "reports",
                body: reporte_selected.forms
                  ? {
                      ...reporte_selected.forms,
                      oper:
                        typeof $drivers[reporte_selected.oper] != "undefined"
                          ? $drivers[reporte_selected.oper].name
                          : reporte_selected.oper,
                      date_ini: moment(
                        new Date(reporte_selected.date_ini * 1000)
                      ).format("DD/MM/YY HH:mm"),
                      start: moment(
                        new Date(reporte_selected.start * 1000)
                      ).format("DD/MM/YY HH:mm"),
                    }
                  : {
                      after_trip: [],
                      after_trip_optional: [],
                      before_trip: [],
                      before_trip_optional: [],
                      id: "",
                      oper: "",
                      date_ini: "",
                      start: "",
                    },
              },
              formats_driver_url
            );
            console.log("comando enviado", {
              type: "reports",
              body: reporte_selected.forms
                ? {
                    ...reporte_selected.forms,
                    oper:
                      typeof $drivers[reporte_selected.oper] != "undefined"
                        ? $drivers[reporte_selected.oper].name
                        : reporte_selected.oper,
                    date_ini: moment(
                      new Date(reporte_selected.date_ini * 1000)
                    ).format("DD/MM/YY HH:mm"),
                    start: moment(
                      new Date(reporte_selected.start * 1000)
                    ).format("DD/MM/YY HH:mm"),
                  }
                : {
                    after_trip: [],
                    after_trip_optional: [],
                    before_trip: [],
                    before_trip_optional: [],
                    id: "",
                    oper: "",
                    date_ini: "",
                    start: "",
                  },
            });
            console.log(reporte_selected);
            break;
        }
        break;
      case units_usage_url:
        switch (event.data.type) {
          case "command":
            ifram_unit_usage = document.getElementById("ifram_unit_usage");
            ifram_unit_usage.contentWindow.postMessage(
              {
                type: "usage",
                body: usage_report_data,
              },
              units_usage_url
            );
            console.log("comando enviado", {
              type: "usage",
              body: usage_report_data,
            });
            break;
        }
        break;
    }
  }
  window.addEventListener("message", handleMessage);

  let usage_report_data = {};
  async function unit_usage() {
    socket_query.emit("usage_report", (data) => {
      usage_report_data = {
        type: "usage",
        body: data,
      };
      console.log("Original data", usage_report_data);
      units_usage_url = document.getElementById("url_usage").value;
    });
  }
  let selected_pos = -1;
  let position_point;
  function mapping_pos(i) {
    selected_alert = -1;
    selected_pos = i;
    map_routes.panTo(coordenada_real[i]);
    map_routes.setZoom(16);
    if (typeof position_point != "undefined") {
      position_point.setMap(null);
    }
    position_point = new google.maps.Marker({
      position: coordenada_real[i],
      map: map_routes,
      animation: google.maps.Animation.DROP,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        // si hay stay mayor a cero en amarillo
        fillColor: coordenada_real[i].stay == 0 ? "#4285F4" : "#FFD600",
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "#FFFFFF",
        labelOrigin: new google.maps.Point(-3, -3),
      },
      label: {
        text:
          moment(new Date(coordenada_real[i].date * 1000)).format(
            "DD/MM/YY HH:mm"
          ) +
          " | " +
          coordenada_real[i].speed +
          " km/h",
        color: "#212121",
        fontSize: "13px",
        fontWeight: "bold",
        className: "custom-label",
      },
      zIndex: 100,
    });
  }
  let coordenada_real_ = [];
  async function osrm_ruta_real(coordenada, zIndex) {
    // OSRM
    let coordenada_osrm = "";
    let radius_osrm = "";
    coordenada_real_ = [];
    let last_dest = coordenada[coordenada.length - 1];
    for (let t in coordenada) {
      coordenada_osrm =
        coordenada_osrm + coordenada[t].lng + "," + coordenada[t].lat + ";";
    }

    for (let t in coordenada) {
      radius_osrm = radius_osrm + "20%3B";
    }

    coordenada_osrm = coordenada_osrm.slice(0, -1);
    radius_osrm = radius_osrm.slice(0, -3);
    let osrm_r = await fetch(
      "https://route.omnitracs.online/match/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full&radiuses=" +
        radius_osrm
    );
    osrm_r = await osrm_r.json();
    if (osrm_r.code == "Ok") {
      if (osrm_r.matchings[0].confidence > 0.2) {
        coordenada = [];
        for (let t in osrm_r.matchings) {
          for (let y in osrm_r.matchings[t].geometry.coordinates) {
            coordenada.push({
              lat: osrm_r.matchings[t].geometry.coordinates[y][1],
              lng: osrm_r.matchings[t].geometry.coordinates[y][0],
            });
          }
        }
      }
    }
    //---------

    zIndex++;
    if (coordenada.length > 0) {
      coordenada.push(last_dest);

      let color_change = linesNeedColorChange(coordenada);
      let color_change_c = 0;

      for (let x in ruta_real) {
        if (typeof ruta_real[x] != "undefined") {
          ruta_real[x].setMap(null);
        }
      }
      ruta_real.push(
        new google.maps.Polyline({
          path: [coordenada[0]],
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map_routes,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#b71c1c",
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        })
      );
      for (let x in coordenada) {
        if (color_change.includes(parseInt(x))) {
          ruta_real.push(
            new google.maps.Polyline({
              path: [
                new google.maps.LatLng(
                  coordenada[x - 1].lat,
                  coordenada[x - 1].lng
                ),
                new google.maps.LatLng(coordenada[x].lat, coordenada[x].lng),
              ],
              geodesic: true,
              strokeColor:
                color_change_c < colors_line.length
                  ? colors_line[color_change_c]
                  : colors_line[color_change_c % colors_line.length],
              strokeOpacity: 1.0,
              strokeWeight: 2.5,
              map: map_routes,
              zIndex: zIndex,
              icons: [
                {
                  icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeColor:
                      color_change_c < colors_line.length
                        ? colors_line[color_change_c]
                        : colors_line[color_change_c % colors_line.length],
                  },
                  offset: "100px",
                  repeat: "100px",
                },
              ],
            })
          );
          color_change_c++;
          zIndex++;
        } else {
          ruta_real[ruta_real.length - 1]
            .getPath()
            .push(new google.maps.LatLng(coordenada[x].lat, coordenada[x].lng));
        }
      }
    }
    coordenada_real_ = coordenada;
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  function speed_chart() {
    current_chart = 1;
    const unit_usage_travel = document
      .getElementById("unit_usage_travel")
      .getContext("2d");
    if (charts_modal) charts_modal.destroy();
    charts_modal = new Chart(unit_usage_travel, {
      type: "line",
      data: {
        labels: coordenada_real.map((item) =>
          moment(new Date(item["date"] * 1000)).format("DD/MM/YY HH:mm")
        ),
        datasets: [
          {
            label: "Velocidad",
            data: coordenada_real.map((item) => item["speed"]),
            fill: false,
            borderColor: "#36A2EB",
            tension: 0.1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return `Velocidad: ${context.raw}`;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Fecha",
            },
          },
          y: {
            title: {
              display: true,
              text: "Velocidad",
            },
          },
        },
      },
    });
  }
  function time_chart() {
    current_chart = 0;
    // grafica
    let en_movimiento =
      Math.round((fin_real - inicio_real) / 60) -
      coordenada_real.reduce((sum, item) => sum + (item["stay"] || 0), 0);
    let idle = coordenada_real.reduce(
      (sum, item) => sum + (item["stay"] || 0),
      0
    );
    const unit_usage_travel = document
      .getElementById("unit_usage_travel")
      .getContext("2d");

    current_chart = 0;
    if (charts_modal) charts_modal.destroy();
    charts_modal = new Chart(unit_usage_travel, {
      type: "pie",
      data: {
        labels: ["En movimiento", "Idle"], // Etiquetas de los datos
        datasets: [
          {
            data: [
              Math.ceil((en_movimiento / (en_movimiento + idle)) * 100),
              100 - Math.ceil((en_movimiento / (en_movimiento + idle)) * 100),
            ], // Valores de los datos
            backgroundColor: ["#FF6384", "#36A2EB"], // Colores de las secciones
            borderColor: ["#FF6384", "#36A2EB"], // Bordes de las secciones
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label || "";
                const value = context.raw || 0;
                return `${label}: ${value}%`;
              },
            },
          },
          datalabels: {
            color: "#fff", // Asegurar buen contraste
            font: {
              weight: "bold",
              size: 14,
            },
            formatter: (value, context) => `${value}%`,
          },
        },
      },
      plugins: [ChartDataLabels], // Habilitar plugin para mostrar etiquetas
    });
  }
  let simulacion_history = false;
  let ruta_real_ = [];
  async function history_simulacion() {
    if (simulacion_history) {
      for (let x in ruta_real) {
        if (typeof ruta_real[x] != "undefined") {
          ruta_real[x].setMap(null);
        }
      }
      for (let x in ruta_real_) {
        if (typeof ruta_real_[x] != "undefined") {
          ruta_real_[x].setMap(null);
        }
      }
      for (let x in recorrido_ruta) {
        if (typeof recorrido_ruta[x] != "undefined") {
          recorrido_ruta[x].setMap(null);
        }
      }
      for (let z in route_marks) {
        if (typeof route_marks[z] != "undefined") {
          route_marks[z].setMap(null);
        }
      }
      if (typeof m_inicio != "undefined") {
        m_inicio.setMap(null);
      }
      for (let x in m_stops) {
        if (typeof m_stops[x] != "undefined") {
          m_stops[x].setMap(null);
        }
      }
      if (typeof m_fin != "undefined") {
        m_fin.setMap(null);
      }
      let zIndex = 0;
      // Mostrar punto inicial
      m_inicio = new google.maps.Marker({
        position: coordenada_real[0],
        map: map_routes,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-start.png",
        zIndex: zIndex,
      });
      // centrar mapa en el punto inicial
      map_routes.panTo(coordenada_real[0]);
      map_routes.setZoom(14);
      // Mostrar indicador de flecha en el punto inicial
      zIndex++;
      if (coordenada_real_.length == 0) {
        coordenada_real_ = coordenada_real;
      }

      m_fin = new google.maps.Marker({
        position: coordenada_real_[0],
        map: map_routes,
        animation: google.maps.Animation.DROP,
        //icon: image,
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 6, // Escala del icono (tamaño de la flecha)
          rotation:
            coordenada_real_.length > 1
              ? rotation(
                  coordenada_real_[0].lng,
                  coordenada_real_[0].lat,
                  coordenada_real_[1].lng,
                  coordenada_real_[1].lat
                )
              : 0, // Rotación de la flecha
          fillColor: "#FAFBFA",
          fillOpacity: 1,
          strokeWeight: 2,
          labelOrigin: new google.maps.Point(-3, -3),
        },
        zIndex: zIndex,
        label: {
          text: unit_name,
          color: "#212121",
          fontSize: "13px",
          fontWeight: "bold",
          className: "custom-label",
        },
      });
      ruta_real_.push(
        new google.maps.Polyline({
          path: [coordenada_real[0]],
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2.5,
          map: map_routes,
          zIndex: zIndex,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "#FF0000",
              },
              offset: "100px",
              repeat: "100px",
            },
          ],
        })
      );

      // tag coordenada_real_ with original points of coordenada_real
      let corg_c = 0;
      for (let x in coordenada_real_) {
        if (coordenada_real[corg_c]) {
          if (
            get_km(
              coordenada_real_[x].lat,
              coordenada_real_[x].lng,
              coordenada_real[corg_c].lat,
              coordenada_real[corg_c].lng
            ) < 1
          ) {
            coordenada_real_[x].org = corg_c;
            corg_c++;
          }
        }
      }

      let coordenada_real_standar = [];
      // transformar coordenada_real_ a puntos con una distancia fija
      let distancia_base = 0.2; // km 0.05
      for (let x in coordenada_real_) {
        if (x > 0) {
          let dist = get_km(
            coordenada_real_[x - 1].lat,
            coordenada_real_[x - 1].lng,
            coordenada_real_[x].lat,
            coordenada_real_[x].lng
          );
          let n_points = Math.ceil(dist / distancia_base);
          let lat1 = coordenada_real_[x - 1].lat;
          let lon1 = coordenada_real_[x - 1].lng;
          let lat2 = coordenada_real_[x].lat;
          let lon2 = coordenada_real_[x].lng;
          let dLat = (lat2 - lat1) / n_points;
          let dLon = (lon2 - lon1) / n_points;
          for (let y = 0; y < n_points; y++) {
            coordenada_real_standar.push({
              lat: lat1 + dLat * y,
              lng: lon1 + dLon * y,
              org: coordenada_real_[x].org ? coordenada_real_[x].org : null,
            });
          }
        }
      }
      coordenada_real_standar.push(
        coordenada_real_[coordenada_real_.length - 1]
      );
      console.log(coordenada_real_.length, coordenada_real_standar.length);
      coordenada_real_ = coordenada_real_standar;

      let color_change = linesNeedColorChange(coordenada_real_);
      let color_change_c = 0;
      // List of colors to change
      console.log(color_change);

      idle_time = 0;
      // move m_fin for every point
      for (let x in coordenada_real_) {
        if (x > 0 && simulacion_history) {
          // Change the position of the arrow
          m_fin.setPosition(coordenada_real_[x]);
          recenterIfNearEdge(
            map_routes,
            coordenada_real_[x].lat,
            coordenada_real_[x].lng
          );

          // Change the rotation of the arrow
          m_fin.setIcon({
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 6, // Escala del icono (tamaño de la flecha)
            rotation: rotation(
              coordenada_real_[x - 1].lat,
              coordenada_real_[x - 1].lng,
              coordenada_real_[x].lat,
              coordenada_real_[x].lng
            ), // Rotación de la flecha
            fillColor: "#FAFBFA",
            fillOpacity: 1,
            strokeWeight: 2,
            labelOrigin: new google.maps.Point(-3, -3),
          });
          // add the point to the route
          if (color_change.includes(parseInt(x))) {
            ruta_real_.push(
              new google.maps.Polyline({
                path: [
                  new google.maps.LatLng(
                    coordenada_real_[x - 1].lat,
                    coordenada_real_[x - 1].lng
                  ),
                  new google.maps.LatLng(
                    coordenada_real_[x].lat,
                    coordenada_real_[x].lng
                  ),
                ],
                geodesic: true,
                strokeColor:
                  color_change_c < colors_line.length
                    ? colors_line[color_change_c]
                    : colors_line[color_change_c % colors_line.length],
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                map: map_routes,
                zIndex: zIndex,
                icons: [
                  {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      strokeColor:
                        color_change_c < colors_line.length
                          ? colors_line[color_change_c]
                          : colors_line[color_change_c % colors_line.length],
                    },
                    offset: "100px",
                    repeat: "100px",
                  },
                ],
              })
            );
            color_change_c++;
            zIndex++;
            if (ruta_real_.length > 3) {
              //  change color of last polyline strokeColor to gray and zindex to 0
              ruta_real_[ruta_real_.length - 4].setOptions({
                strokeColor: "#9E9E9E",
                zIndex: 0,
              });
              // change color of icons to gray
              ruta_real_[ruta_real_.length - 4].icons[0].icon.strokeColor =
                "#9E9E9E";
              // change repeat of icons to 0
              ruta_real_[ruta_real_.length - 4].set("icons", [
                {
                  icon: ruta_real_[ruta_real_.length - 4].icons[0].icon,
                  offset: "100px",
                  repeat: "300px",
                },
              ]);
            }
          } else {
            ruta_real_[ruta_real_.length - 1]
              .getPath()
              .push(
                new google.maps.LatLng(
                  coordenada_real_[x].lat,
                  coordenada_real_[x].lng
                )
              );
          }
          // time relative to dist between points
          let dist = get_km(
            coordenada_real_[x - 1].lat,
            coordenada_real_[x - 1].lng,
            coordenada_real_[x].lat,
            coordenada_real_[x].lng
          );
          let time = dist * 600; // 1000
          if (
            coordenada_real_[x].org &&
            selected_pos != coordenada_real_[x].org
          ) {
            selected_pos = coordenada_real_[x].org;
            let item = document.getElementById("history_pos_" + selected_pos);
            if (item) {
              item.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
            }
            if (coordenada_real[selected_pos].stay > 0) {
              m_stops[m_stops.length] = new google.maps.Marker({
                position: coordenada_real[selected_pos],
                map: map_routes,
                animation: google.maps.Animation.DROP,
                icon: "img/dd-pause.png",
                zIndex: 0,
              });
              idle_time = idle_time + coordenada_real[selected_pos].stay;
            }
            if (coordenada_real[selected_pos].distance) {
              total_distance = coordenada_real[selected_pos].distance;
            }
            if (coordenada_real[selected_pos].speed) {
              fin_real = coordenada_real[selected_pos].date;
            }
            if (coordenada_real[selected_pos]) {
              // find coordenada_alertas with the same date
              let found = false;
              for (let w in coordenada_alertas) {
                if (
                  Math.floor(coordenada_alertas[w].date / 60) ==
                  Math.floor(coordenada_real[selected_pos].date / 60)
                ) {
                  route_marks[route_marks.length] = new google.maps.Marker({
                    position: {
                      lat: coordenada_alertas[w].lat,
                      lng: coordenada_alertas[w].lng,
                    },
                    map: map_routes,
                    animation: google.maps.Animation.DROP,
                    icon: "img/markerAlt.png",
                    zIndex: 0,
                  });
                  let item = document.getElementById("alert_pos_" + w);
                  if (item) {
                    item.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }
                  selected_alert = w;
                  found = true;
                }
              }
              if (!found) {
                selected_alert = -1;
              }
            }
          }
          // wait 1 second
          await new Promise((r) => setTimeout(r, time));
        }
      }
      simulacion_history = false;
    } else {
      show_detalle_map();
    }
  }
  function recenterIfNearEdge(map, markerLat, markerLng) {
    // Obtener los bordes visibles del mapa
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast(); // Esquina noreste
    const sw = bounds.getSouthWest(); // Esquina suroeste

    // Calcular los límites de la "zona cercana" al 10% de las orillas
    const latBuffer = (ne.lat() - sw.lat()) * 0.1;
    const lngBuffer = (ne.lng() - sw.lng()) * 0.1;

    const nearNorth = ne.lat() - latBuffer;
    const nearSouth = sw.lat() + latBuffer;
    const nearEast = ne.lng() - lngBuffer;
    const nearWest = sw.lng() + lngBuffer;

    // Verificar si el marcador está cerca de una de las orillas
    const isNearEdge =
      markerLat >= nearNorth ||
      markerLat <= nearSouth ||
      markerLng >= nearEast ||
      markerLng <= nearWest;

    // Si está cerca de una orilla, centrar el mapa en el marcador
    if (isNearEdge) {
      map.setCenter({ lat: markerLat, lng: markerLng });
    }
  }

  function rotation(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    let anguloRadianes = Math.atan2(deltaY, deltaX);
    let anguloGrados = anguloRadianes * (180 / Math.PI);
    if (anguloGrados < 0) {
      anguloGrados += 360;
    }
    return anguloGrados;
  }
  function linesNeedColorChange(points, threshold = 0.01) {
    let last_point = 0;
    let result = [];
    for (let x = 0; x < points.length - 1; x++) {
      if (x > 1) {
        for (let y = last_point; y < x - 1; y++) {
          let distance = puntoLinea(points[y], points[y + 1], points[x]);
          if (distance < threshold && distance != null) {
            result.push(x);
            last_point = x;
          }
        }
      }
    }
    return result;
  }
  function puntoLinea(a, b, p) {
    const l = { lat: b.lat - a.lat, lng: b.lng - a.lng };
    const t =
      ((p.lat - a.lat) * l.lat + (p.lng - a.lng) * l.lng) /
      (l.lat ** 2 + l.lng ** 2);
    if (t < 0 || t > 1) return null;
    let p2 = { lat: a.lat + t * l.lat, lng: a.lng + t * l.lng };
    return get_km(p.lat, p.lng, p2.lat, p2.lng);
  }
  let chats_expanded = false;
  let travel_changes_expanded = false;
  let chats_window;
  function show_chats_modal() {
    chats_window = document.getElementById("chats_window");
    chats_window.scrollTop = chats_window.scrollHeight;
  }
  async function travel_pdf_report() {
    console.log(coordenada_real);
    const { jsPDF } = window.jspdf;
    const doc = new jsPDF();

    // Cargar el logo desde la URL y convertirlo a base64
    async function loadLogo() {
      const response = await fetch("/img/logo2.png");
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    }

    const logoBase64 = await loadLogo();

    // Ajustes iniciales
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // ---------------------------------------
    // Fecha de impresión
    // ---------------------------------------
    const fechaImpresion = moment().format("DD/MM/YY HH:mm:ss");
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Fecha de impresión ${fechaImpresion}`, pageWidth - 70, 10);

    // ---------------------------------------
    // Título principal
    // ---------------------------------------
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(
      "Reporte de viaje " + (id_travel ? id_travel : ""),
      pageWidth / 2,
      20,
      { align: "center" }
    );

    // ---------------------------------------
    // Función para agregar número de página
    // ---------------------------------------
    const addPageNumber = (currentPage, totalPages) => {
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      const pageNumberText = `Página ${currentPage} de ${totalPages}`;
      doc.text(pageNumberText, pageWidth - 20, pageHeight - 10, {
        align: "right",
      });
    };

    // ---------------------------------------
    // Información del viaje
    // ---------------------------------------
    const infoViajeHead = [
      ["Unidad", "Conductor", "Fecha Programada", "ID de viaje"],
    ];
    doc.autoTable({
      head: infoViajeHead,
      body: [
        [
          unit_name,
          driver_name,
          moment(new Date(inicio_programado * 1000)).format("DD/MM/YY HH:mm"),
          id_travel ? id_travel : "-",
        ],
      ],
      startY: 25,
      headStyles: {
        fillColor: [46, 204, 113], // Verde claro
        textColor: 255,
      },
      styles: {
        halign: "center",
      },
    });

    let ultimoY = doc.lastAutoTable.finalY;

    // ---------------------------------------
    // Datos del viaje
    // ---------------------------------------
    doc.setDrawColor(200);
    doc.line(10, ultimoY + 5, pageWidth - 10, ultimoY + 5); // Línea separadora

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Datos de viaje", pageWidth / 2, ultimoY + 10, {
      align: "center",
    });

    const infoViaje2Head = [["Campo", "Valor"]];
    const infoViaje2Body = [
      ["Distancia recorrida", Math.round(total_distance) + " km"],
      [
        "Inicio real",
        moment(new Date(inicio_real * 1000)).format("DD/MM/YY HH:mm"),
      ],
      [
        "Final real",
        moment(new Date(fin_real * 1000)).format("DD/MM/YY HH:mm"),
      ],
      [
        "Duración total",
        moment.unix(fin_real).diff(moment.unix(inicio_real), "minutes") >= 60
          ? `${Math.floor(
              moment.unix(fin_real).diff(moment.unix(inicio_real), "minutes") /
                60
            )} hora(s) ${
              moment.unix(fin_real).diff(moment.unix(inicio_real), "minutes") %
              60
            } minuto(s)`
          : `${moment.unix(fin_real).diff(moment.unix(inicio_real), "minutes")} minuto(s)`,
      ],
      ["Tiempo de idle", idle_time + " minuto(s)"],
      [
        "Número de paradas",
        coordenada_real.filter((item) => item["stay"] > 0).length,
      ],
      [
        "Velocidad promedio",
        avg_speed_c == 0
          ? "0 km/h"
          : Math.round(avg_speed / avg_speed_c) + " km/h",
      ],
      ["Velocidad máxima", max_speed + " km/h"],
    ];

    doc.autoTable({
      head: infoViaje2Head,
      body: infoViaje2Body,
      startY: ultimoY + 15,
      headStyles: {
        fillColor: [39, 174, 96], // Verde medio
        textColor: 255,
      },
      styles: {
        halign: "center",
      },
      columnStyles: {
        0: { halign: "left" },
        1: { halign: "center" },
      },
    });

    ultimoY = doc.lastAutoTable.finalY;

    // ---------------------------------------
    // Alertas
    // ---------------------------------------
    doc.line(10, ultimoY + 5, pageWidth - 10, ultimoY + 5); // Línea separadora

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Alertas", pageWidth / 2, ultimoY + 10, { align: "center" });

    let newY = ultimoY + 15;

    if (alert_list_modal.length === 0) {
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text("No se generaron alertas", pageWidth / 2, newY, {
        align: "center",
      });
      newY += 10;
    } else {
      const alertasHead = [["Fecha de alerta", "Nombre de la alerta"]];

      doc.autoTable({
        head: alertasHead,
        body: alert_list_modal,
        startY: newY,
        headStyles: {
          fillColor: [22, 160, 133], // Verde oscuro
          textColor: 255,
        },
        styles: {
          halign: "center",
        },
      });
      newY = doc.lastAutoTable.finalY;
    }

    // ---------------------------------------
    // Chats
    // ---------------------------------------
    doc.line(10, newY + 5, pageWidth - 10, newY + 5); // Línea separadora

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Chats", pageWidth / 2, newY + 10, { align: "center" });

    let chatsY = newY + 15;

    if (chat_list.length === 0) {
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text("No hay mensajes de chat", pageWidth / 2, chatsY, {
        align: "center",
      });
      chatsY += 10;
    } else {
      const chatsHead = [["Fecha", "Tipo", "Mensaje"]];
      const chatsBody = chat_list.map((chat) => [
        moment(new Date(chat.date * 1000)).format("DD/MM/YY HH:mm"),
        chat.type == 0
          ? "Conductor"
          : chat.type == 1
            ? "Despacho"
            : "Automático",
        chat.message.search("data:image/") != -1
          ? "Imagen"
          : chat.message.search("data:application/pdf") != -1
            ? "PDF"
            : chat.message.search("data:audio") != -1
              ? "Audio"
              : chat.message,
      ]);

      doc.autoTable({
        head: chatsHead,
        body: chatsBody,
        startY: chatsY,
        headStyles: {
          fillColor: [46, 204, 113], // Verde claro
          textColor: 255,
        },
        styles: {
          halign: "center",
        },
        columnStyles: {
          2: { halign: "left" },
        },
      });
      chatsY = doc.lastAutoTable.finalY;
    }

    // ---------------------------------------
    // Reporte de posiciones
    // ---------------------------------------
    doc.line(10, chatsY + 5, pageWidth - 10, chatsY + 5); // Línea separadora

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Reporte de posiciones", pageWidth / 2, chatsY + 10, {
      align: "center",
    });

    let posicionesY = chatsY + 15;

    const head = [
      [
        "Fecha",
        "Latitud",
        "Longitud",
        "Tiempo sin movimiento (min)",
        "Velocidad (km/h)",
        "Distancia (km)",
      ],
    ];

    const body = coordenada_real.map((item) => [
      moment(new Date(item.date * 1000)).format("DD/MM/YY HH:mm"),
      item.lat,
      item.lng,
      item.stay,
      item.speed,
      item.distance ? item.distance.toFixed(1) : 0,
    ]);

    doc.autoTable({
      head: head,
      body: body,
      startY: chatsY + 15,
      headStyles: {
        fillColor: [39, 174, 96], // Verde medio
        textColor: 255,
      },
      styles: { halign: "center" },
      didParseCell: (data) => {
        if (data.section === "body") {
          if (parseFloat(data.row.raw[4]) > 100) {
            // light blue
            data.cell.styles.fillColor = [200, 200, 255];
            // text color to highlight
            data.cell.styles.textColor = [0, 0, 255];
          }
          for (let x in alert_list_modal) {
            if (alert_list_modal[x][0] == data.row.raw[0]) {
              // light red
              data.cell.styles.fillColor = [255, 200, 200];
              // text color to highlight
              data.cell.styles.textColor = [255, 0, 0];
            }
          }
        }
      },
    });

    posicionesY = doc.lastAutoTable.finalY;

    // ---------------------------------------
    // Reporte de Cambios de viaje
    // ---------------------------------------
    doc.line(10, posicionesY + 5, pageWidth - 10, posicionesY + 5); // Línea separadora

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Reporte de cambios de viaje", pageWidth / 2, posicionesY + 10, {
      align: "center",
    });

    let cambiosY = posicionesY + 15;

    if (changes_history.length === 0) {
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text("No hay cambios de viaje", pageWidth / 2, cambiosY, {
        align: "center",
      });
      cambiosY += 10;
    } else {
      const cambiosHead = [["Fecha", "Comentario", "Razón", "Usuario"]];

      const cambiosBody = changes_history.map((item) => [
        moment(new Date(item.date * 1000)).format("DD/MM/YY HH:mm"),
        item.comment,
        item.reason == 0
          ? "Camino cerrado"
          : item.reason == 1
            ? "Cambio de plan logístico"
            : item.reason == 2
              ? "Emergencia"
              : "Otro",
        item.user,
      ]);

      doc.autoTable({
        head: cambiosHead,
        body: cambiosBody,
        startY: cambiosY,
        headStyles: {
          fillColor: [46, 204, 113], // Verde claro
          textColor: 255,
        },
        styles: {
          halign: "center",
        },
        columnStyles: {
          1: { halign: "left" },
        },
      });
    }

    // ---------------------------------------
    // Agregar número de página
    // ---------------------------------------
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addPageNumber(i, pageCount);
      // ---------------------------------------
      // Marca de agua (Parte inferior izquierda)
      // ---------------------------------------
      const marcaAgua = "Reporte Vector GPS";
      doc.setFontSize(12); // Tamaño reducido al de los títulos
      doc.setTextColor(200, 200, 200); // Gris claro
      doc.text(marcaAgua, 10, pageHeight - 10); // Esquina inferior izquierda
      doc.setTextColor(0, 0, 0); // Restablecemos color negro

      // ---------------------------------------
      // Agregar el logo en la esquina superior izquierda
      // ---------------------------------------
      doc.addImage(logoBase64, "PNG", 10, 5, 36, 8); // Ajusta posición (x, y) y tamaño (width, height)
    }

    // Guardar PDF
    doc.save(
      `Reporte_viaje_${unit_name}_${driver_name}_${moment(
        new Date(inicio_programado * 1000)
      ).format("DDMMYY_HHmm")}.pdf`
    );
  }

  async function run_pdf_scripts() {
    await loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"
    );
    await loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/3.8.4/jspdf.plugin.autotable.min.js"
    );
  }
  run_pdf_scripts();
  function loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Error loading script: ${url}`));
      document.body.appendChild(script);
    });
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

{#if detalle_modal}
  <div
    class="modal fade {detalle_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {detalle_modal
      ? 'block'
      : 'none'}; z-index:1039;"
    use:show_detalle_map
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
      style="min-width: 95%"
    >
      <div class="modal-content" style="min-height: 99%; overflow: visible;">
        <div class="modal-header" style="padding: 0.5rem 0.5rem;">
          <h5
            class="modal-title text-center"
            id="modaltitle"
            style="margin-left: 10px;"
          >
            <i class="material-icons bblue md-2">directions</i> Detalle de viaje
            {#if id_travel}
              |
              <span
                class="badge badge-pill badge-primary"
                style="margin-left: 10px;margin-right: 10px;"
              >
                ID Viaje {id_travel}
              </span>
            {/if}
            |
            <i class="material-icons bblue md-2">directions_car</i>
            Unidad <strong>{unit_name}</strong>
            |
            <i class="material-icons bblue md-2">person</i>
            <strong>{driver_name} </strong>
            |
            <i class="material-icons bblue md-2">date_range</i>
            <strong>
              {moment(new Date(inicio_programado * 1000)).format(
                "DD/MM/YY HH:mm"
              )}</strong
            >
          </h5>
          <!-- rerport pdf button-->
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            style="margin-left: 20px; padding: 0rem 0.2rem;"
            on:click={travel_pdf_report}
          >
            <i class="material-icons">picture_as_pdf</i> Reporte PDF
          </button>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => {
              detalle_modal = false;
              selected_alert = -1;
              simulacion_history = false;
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body h-100 p-0">
          <div class="row h-100 no-gutters">
            <div class="col-6 h-100">
              <div class="h-100" id="map_routes" />
            </div>
            <div class="col-3 h-100">
              <div class="card h-50" style="border: none;">
                <div class="d-flex flex-column h-100">
                  <div
                    class="card-header bg-dark text-white b-0"
                    style="min-height: 35px;display: flex;align-items: center;"
                  >
                    <i
                      tooltip="Reportes"
                      class="material-icons md-90"
                      style="margin-right: 5px;"
                      >explore
                    </i>
                    Datos del viaje
                  </div>
                  <div
                    class="card-body flex-grow-1 overflow-auto"
                    style="font-size: 15px; display: flex; flex-direction: column; margin-left: 10px; margin-top: 3px;justify-content: space-around;"
                  >
                    <span>
                      <!-- icon for distance -->
                      <i class="material-icons orange">directions</i>
                      <strong>{Math.round(total_distance)} km</strong> recorridos
                    </span>
                    <span style="margin-top: 2px;">
                      <!-- icon for time -->
                      <i class="material-icons green">play_circle_filled</i>
                      Inicio real
                      <strong
                        >{moment(new Date(inicio_real * 1000)).format(
                          "DD/MM/YY HH:mm"
                        )}</strong
                      >
                    </span>
                    <span style="margin-top: 2px;">
                      <!-- icon for time -->
                      <i class="material-icons">stop</i>
                      Fin real
                      <strong
                        >{moment(new Date(fin_real * 1000)).format(
                          "DD/MM/YY HH:mm"
                        )}</strong
                      >
                    </span>
                    <span style="margin-top: 2px;">
                      <!--Tiempo total de viaje -->
                      <i class="material-icons bblue">timer</i> Duración
                      <strong
                        >{moment
                          .unix(fin_real)
                          .diff(moment.unix(inicio_real), "minutes") >= 60
                          ? `${Math.floor(moment.unix(fin_real).diff(moment.unix(inicio_real), "minutes") / 60)} hora(s) ${moment.unix(fin_real).diff(moment.unix(inicio_real), "minutes") % 60} minuto(s)`
                          : `${moment.unix(fin_real).diff(moment.unix(inicio_real), "minutes")} minuto(s)`}</strong
                      >
                    </span>
                    <span style="margin-top: 2px;">
                      <!--Tiempo en stay -->
                      <i class="material-icons yei">pause_circle_filled</i> Idle
                      <strong>{idle_time} minuto(s)</strong>
                    </span>
                    <span style="margin-top: 2px;">
                      <!-- Numero de paradas -->
                      <i class="material-icons red">location_on</i>
                      <strong
                        >{coordenada_real.filter((item) => item["stay"] > 0)
                          .length}</strong
                      >
                      Paradas
                    </span>
                    <span style="margin-top: 2px;">
                      <!-- Velocidad promedio -->
                      <i class="material-icons green">speed</i>
                      Promedio
                      <strong
                        >{avg_speed_c == 0
                          ? 0
                          : Math.round(avg_speed / avg_speed_c)} km/h
                      </strong>
                      Max <strong>{max_speed} km/h</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="card h-50" style="border: none;">
                <div class="d-flex flex-column h-100">
                  <div
                    class="card-header bg-dark text-white b-0"
                    style="padding-right: 10px;display: flex;align-items: center;justify-content: space-between;"
                  >
                    <div>
                      <i tooltip="Reportes" class="material-icons md-90">
                        flight_land
                      </i>
                      Reportes
                    </div>
                    <div>
                      <button
                        type="button"
                        class="btn {current_chart == 0
                          ? 'btn-primary'
                          : 'btn-outline-primary'} btn-sm float-right"
                        style="padding: 1px 5px; font-size: 10px;"
                        on:click={time_chart}
                      >
                        <i class="material-icons" style="font-size: 18px;"
                          >timer</i
                        >
                        Uso de tiempo
                      </button>
                      <button
                        type="button"
                        class="btn {current_chart == 1
                          ? 'btn-primary'
                          : 'btn-outline-primary'} btn-sm float-right"
                        style="padding: 1px 5px; margin-right: 10px; font-size: 10px;"
                        on:click={speed_chart}
                      >
                        <i class="material-icons" style="font-size: 18px;"
                          >speed</i
                        >
                        Velocidad
                      </button>
                    </div>
                  </div>
                  <div
                    class="card-body flex-grow-1 overflow-auto"
                    style="font-size: 17px; display: flex; flex-direction: column;justify-content: center;margin-top: 10px;margin-bottom: 10px;"
                  >
                    <canvas id="unit_usage_travel" style="height: 100%;"
                    ></canvas>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 h-100">
              <div
                class="card h-50"
                style="border: 0; border-left: 1px solid rgba(0, 0, 0, .125);"
              >
                <div class="d-flex flex-column h-100">
                  <div
                    class="card-header bg-dark text-white b-0"
                    style="min-height: 35px;display: flex;align-items: center;justify-content: space-between;"
                  >
                    <div>
                      <i tooltip="Reportes" class="material-icons md-90">info</i
                      >
                      Posiciones
                    </div>
                    <button
                      type="button"
                      class="btn btn-link btn-sm float-right"
                      style="padding: 0px 5px; font-size: 10px;"
                      on:click={() => {
                        simulacion_history = !simulacion_history;
                        history_simulacion();
                      }}
                    >
                      <i
                        class="material-icons"
                        style="font-size: 28px; margin: -16px 0px; color: {!simulacion_history
                          ? 'rgb(0 237 32)'
                          : 'rgb(237 210 0)'};"
                      >
                        {!simulacion_history
                          ? "play_circle_filled"
                          : "pause_circle_filled"}</i
                      >
                    </button>
                  </div>
                  <div class="card-body flex-grow-1 overflow-auto">
                    <div class="list-group" style="height: 100%;">
                      {#each coordenada_real as item, i}
                        <a
                          href="/"
                          id="history_pos_{i}"
                          class:list-group-item-warning={selected_pos == i}
                          class:active_={selected_pos == i}
                          on:click|preventDefault={() => {
                            mapping_pos(i);
                          }}
                          class="list-group-item list-group-item-action"
                          style="display: flex; justify-content: space-between;align-items: center;"
                        >
                          <div>
                            <strong
                              >{moment(new Date(item.date * 1000)).format(
                                "DD/MM/YY HH:mm"
                              )}</strong
                            >
                            |
                            <i
                              class="material-icons {item.stay == 0
                                ? 'green'
                                : 'yei'}"
                              style="font-size: 16px;"
                              >{item.stay == 0
                                ? "play_circle_filled"
                                : "pause_circle_filled"}</i
                            >
                            {item.stay == 0 ? item.speed + " km/h" : ""}
                            {item.stay > 0 ? item.stay + " min" : ""}
                          </div>
                          <span style="font-size: 10px;"
                            >({item.lat},{item.lng})</span
                          >
                        </a>
                      {/each}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card h-50 bg-light card-b"
                style="border-left: 1px solid rgba(0, 0, 0, .125);"
              >
                <div class="d-flex flex-column h-100">
                  <div class="card-header bg-dark text-white b-0">
                    <i tooltip="Reportes" class="material-icons md-90"
                      >report_problem</i
                    > Alertas
                  </div>
                  {#if !chats_expanded && !travel_changes_expanded}
                    <div class="card-body flex-grow-1 overflow-auto">
                      <div
                        class="list-group"
                        style="height: {alert_list_modal.length == 0
                          ? '100%'
                          : 'auto'};"
                      >
                        {#if alert_list_modal.length == 0}
                          <div
                            style="display: flex; height: 100%; justify-content: center; align-items: center;"
                          >
                            <strong
                              ><i class="material-icons">check_circle</i> No hay
                              alertas
                            </strong>
                          </div>
                        {:else}
                          {#each alert_list_modal as item, i}
                            <a
                              href="/"
                              id="alert_pos_{i}"
                              class:list-group-item-warning={selected_alert ==
                                i}
                              class:active_={selected_alert == i}
                              on:click|preventDefault={() => {
                                maping_alert(i);
                              }}
                              class="list-group-item list-group-item-action"
                              ><strong>{item[0]}</strong> | {item[1]}</a
                            >
                          {/each}
                        {/if}
                      </div>
                    </div>
                  {/if}
                  <div
                    class="card-header bg-dark text-white b-0"
                    style="display: flex; flex-direction:row; align-items: center; justify-content: space-between;"
                  >
                    <div>
                      <i class="material-icons md-90">chat</i> Chats de viaje
                    </div>
                    <i
                      class="material-icons"
                      style="font-size: 20px; margin-left: 10px; cursor: pointer;"
                      on:click={() => {
                        chats_expanded = !chats_expanded;
                        if (chats_expanded) travel_changes_expanded = false;
                      }}>{!chats_expanded ? "expand_less" : "expand_more"}</i
                    >
                  </div>
                  {#if chats_expanded}
                    <div
                      class="card-body flex-grow-1 overflow-auto"
                      style="padding: 0px 10px;"
                      use:show_chats_modal
                      id="chats_window"
                    >
                      {#if chat_list.length == 0}
                        <div
                          style="display: flex; height: 100%; justify-content: center; align-items: center;"
                        >
                          <strong
                            ><i class="material-icons">check_circle</i> No hay chats
                          </strong>
                        </div>
                      {:else}
                        {#each chat_list as item, i}
                          {#if item.message.search("data:audio/") != -1}
                            <div
                              class="mt1 d-flex my-1 {item.type == 0
                                ? 'flex-row'
                                : 'flex-row-reverse'}"
                              chat_date={item.date}
                            >
                              <div
                                class=" card {item.type == 0
                                  ? 'text-white bg-dark2'
                                  : ' bg-secondary2'}"
                                style="border-radius:1rem;border:2px solid #009184"
                              >
                                <div class="card-header b-0">
                                  <i
                                    class="material-icons md-12"
                                    style="color:{item.type == 0
                                      ? '#fff'
                                      : '#212121'}"
                                    >{item.type == 1 ? "home" : "person_pin"}</i
                                  >
                                  <audio
                                    src={item.message}
                                    controls="controls"
                                  />
                                </div>
                              </div>
                              <div
                                style="font-size:0.7rem"
                                class="d-flex align-items-end"
                              >
                                <strong
                                  >{moment(new Date(item.date * 1000)).format(
                                    "DD/MM/YY HH:mm"
                                  )}</strong
                                >
                              </div>
                            </div>
                          {:else if item.message.search("data:image/") != -1}
                            <div
                              class="mt1 d-flex my-1 {item.type == 0
                                ? 'flex-row'
                                : 'flex-row-reverse'}"
                              chat_date={item.date}
                            >
                              <div
                                class=" card {item.type == 0
                                  ? 'text-white bg-dark2'
                                  : ' bg-secondary2'}"
                                style="border-radius:1rem;border:2px solid #009184"
                              >
                                <div class="card-header b-0">
                                  <i
                                    class="material-icons md-12"
                                    style="color:{item.type == 0
                                      ? '#fff'
                                      : '#212121'}"
                                    >{item.type == 1 ? "home" : "person_pin"}</i
                                  >
                                  <a href={item.message} download
                                    ><img
                                      src={item.message}
                                      alt=""
                                      height="300"
                                    /></a
                                  >
                                </div>
                              </div>
                              <div
                                style="font-size:0.7rem"
                                class="d-flex align-items-end"
                              >
                                <strong
                                  >{moment(new Date(item.date * 1000)).format(
                                    "DD/MM/YY HH:mm"
                                  )}</strong
                                >
                              </div>
                            </div>
                          {:else if item.message.search("data:application/pdf") != -1}
                            <div
                              class="mt1 d-flex my-1 {item.type == 0
                                ? 'flex-row'
                                : 'flex-row-reverse'}"
                              chat_date={item.date}
                            >
                              <div
                                class=" card {item.type == 0
                                  ? 'text-white bg-dark2'
                                  : ' bg-secondary2'}"
                                style="border-radius:1rem;border:2px solid #009184"
                              >
                                <div class="card-header b-0">
                                  <i
                                    class="material-icons md-12"
                                    style="color:{item.type == 0
                                      ? '#fff'
                                      : '#212121'}"
                                    >{item.type == 1 ? "home" : "person_pin"}</i
                                  >
                                  <a href={item.message} download>
                                    <!-- icon PDF-->
                                    <i class="material-icons">picture_as_pdf</i>
                                    Documento PDF
                                  </a>
                                </div>
                              </div>
                              <div
                                style="font-size:0.7rem"
                                class="d-flex align-items-end"
                              >
                                <strong
                                  >{moment(new Date(item.date * 1000)).format(
                                    "DD/MM/YY HH:mm"
                                  )}</strong
                                >
                              </div>
                            </div>
                          {:else if item.type != -1}
                            <div
                              class="mt1 d-flex my-1 {item.type == 0
                                ? 'flex-row'
                                : item.type == 1
                                  ? 'flex-row-reverse'
                                  : 'flex-column justify-content-center align-items-center'}"
                              chat_date={item.date}
                            >
                              <div
                                class=" card {item.type == 0
                                  ? 'text-white bg-dark2'
                                  : item.type == 1
                                    ? 'bg-secondary2'
                                    : 'bg-secondary3'}"
                                style="border-radius:1rem;border:2px solid #009184"
                              >
                                <div
                                  class="card-header b-0"
                                  style="border-bottom:0px;"
                                >
                                  <i
                                    class="material-icons md-12"
                                    style="color:{item.type == 0
                                      ? '#fff'
                                      : '#212121'}"
                                    >{item.type == 1
                                      ? "home"
                                      : item.type == 0
                                        ? "person_pin"
                                        : "info"}</i
                                  >
                                  {item.message}
                                </div>
                              </div>
                              <div
                                style="font-size:0.7rem; margin: {item.type == 2
                                  ? '5px'
                                  : '0px'} 5px 0px 5px;"
                                class="d-flex align-items-end"
                              >
                                <strong
                                  >{moment(new Date(item.date * 1000)).format(
                                    "DD/MM/YY HH:mm"
                                  )}</strong
                                >
                              </div>
                            </div>
                          {:else}
                            <div
                              class="d-flex justify-content-center"
                              style="text-align: center; margin: 10px;"
                              id="last_read"
                            >
                              <div class="text-white" style="width: 100%;">
                                <div
                                  class=""
                                  style="display: flex;color: black;flex-direction: row;justify-content: center;align-items: center;"
                                >
                                  <div
                                    style="display: inline-block;background:black; height:2px; width:10%;"
                                  ></div>
                                  <span
                                    style="margin-left: 10px;margin-right: 10px;"
                                    ><i class="material-icons md-12">info</i>
                                    <strong>{item.message}</strong></span
                                  >
                                  <div
                                    style="display: inline-block;background:black; height:2px; width:10%;"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          {/if}
                        {/each}
                      {/if}
                    </div>
                  {/if}
                  {#if changes_history.length > 0}
                    <div
                      class="card-header bg-dark text-white b-0"
                      style="display: flex; flex-direction:row; align-items: center; justify-content: space-between;"
                    >
                      <div>
                        <i class="material-icons md-90"> directions_bus </i> Cambios
                        de viaje
                      </div>
                      <i
                        class="material-icons"
                        style="font-size: 20px; margin-left: 10px; cursor: pointer;"
                        on:click={() => {
                          travel_changes_expanded = !travel_changes_expanded;
                          if (travel_changes_expanded) chats_expanded = false;
                        }}
                        >{!travel_changes_expanded
                          ? "expand_less"
                          : "expand_more"}</i
                      >
                    </div>
                  {/if}
                  {#if travel_changes_expanded}
                    <div
                      class="card-body flex-grow-1 overflow-auto"
                      style="padding: 0px 10px;"
                    >
                      {#each changes_history as change}
                        <br />
                        <!--Icon for date-->
                        <i
                          class="material-icons"
                          style="font-size: 20px; margin-left: 10px; color: #009184;"
                          >date_range</i
                        >
                        Fecha {moment(new Date(change.date * 1000)).format(
                          "DD/MM/YY HH:mm"
                        )}
                        <br />
                        <!--Icon for comment-->
                        <i
                          class="material-icons"
                          style="font-size: 20px; margin-left: 10px; color: #009184;"
                          >comment</i
                        >
                        Comentario {change.comment}
                        <br />
                        <!--Icon for reason-->
                        <i
                          class="material-icons"
                          style="font-size: 20px; margin-left: 10px; color: #009184;"
                          >info</i
                        >
                        Razón {change.reason == 0
                          ? "Camino cerrado"
                          : change.reason == 1
                            ? "Cambio de plan logístico"
                            : change.reason == 2
                              ? "Emergencia"
                              : change.reason == 3
                                ? "Otro"
                                : "Desconocido"}
                        <br />
                        <!--Icon for user-->
                        <i
                          class="material-icons"
                          style="font-size: 20px; margin-left: 10px; color: #009184;"
                          >person</i
                        >
                        Usuario {change.user}
                        <br />
                        <hr />
                      {/each}
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if formulario_modal}
  <div
    class="modal fade {formulario_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {formulario_modal
      ? 'block'
      : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
      style="min-width: 95%"
    >
      <div class="modal-content" style="min-height: 99%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">assignment </i> Formularios
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => {
              formulario_modal = false;
              selected_alert = -1;
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body h-100 p-0" style="overflow-y: unset;">
          <iframe
            title="FormatosViaje"
            src="{formats_driver_url}/?report=true&url_post={window.location
              .origin + window.location.pathname.replace(/\/$/, '')}"
            style="width: 100%; height: 100%; border: none;"
            id="iframe_reports"
            use:iframe_reports_on
          ></iframe>
          <div class="watermark">External tool</div>
        </div>
      </div>
    </div>
  </div>
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div class="card-header bg-dark text-white b-0">
            <i tooltip="Reportes" class="material-icons md-90">widgets</i> Reportes
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each reportes as item, i}
                <a
                  href="/"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={() => {
                    maping(i);
                  }}
                  class="list-group-item list-group-item-action">{item}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          {#if selected != -1}
            <div class="card-header bg-dark text-white b-0">
              <div class="d-flex justify-content-between">
                <div>
                  <i tooltip="Reportes" class="material-icons md-90">notes</i>
                  {reportes[selected]}
                </div>
                <div>
                  <button
                    on:click={reporte_csv}
                    id="csv"
                    type="button"
                    class="btn btn-success mini">Descargar CSV</button
                  >
                </div>
              </div>
            </div>
            <div class="card-body flex-grow-1 overflow-auto">
              {#if selected == 0}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <div
                    style="display:flex;border-bottom: 1px solid #e0e0e0;height: 40px;align-items: center;"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      style="margin-left:16px;margin-right:0px">filter_list</i
                    >
                    <div class="mdc-chip-set mdc-chip-set--filter" role="grid">
                      <div
                        class="mdc-chip mdc-chip--selected"
                        role="row"
                        id="f_concluido"
                      >
                        <div class="mdc-chip__ripple" />
                        <span class="mdc-chip__checkmark">
                          <svg
                            class="mdc-chip__checkmark-svg"
                            viewBox="-2 -3 30 30"
                          >
                            <path
                              class="mdc-chip__checkmark-path"
                              fill="none"
                              stroke="black"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                        </span>
                        <span role="gridcell">
                          <span
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="mdc-chip__primary-action"
                          >
                            <span class="mdc-chip__text">Cerrado</span>
                          </span>
                        </span>
                      </div>
                      <div
                        class="mdc-chip mdc-chip--selected"
                        role="row"
                        id="f_en_proceso"
                      >
                        <div class="mdc-chip__ripple" />
                        <span class="mdc-chip__checkmark">
                          <svg
                            class="mdc-chip__checkmark-svg"
                            viewBox="-2 -3 30 30"
                          >
                            <path
                              class="mdc-chip__checkmark-path"
                              fill="none"
                              stroke="black"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                        </span>
                        <span role="gridcell">
                          <span
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="mdc-chip__primary-action"
                          >
                            <span class="mdc-chip__text">En Proceso</span>
                          </span>
                        </span>
                      </div>
                      <div
                        class="mdc-chip mdc-chip--selected"
                        role="row"
                        id="f_parcial"
                      >
                        <div class="mdc-chip__ripple" />
                        <span class="mdc-chip__checkmark">
                          <svg
                            class="mdc-chip__checkmark-svg"
                            viewBox="-2 -3 30 30"
                          >
                            <path
                              class="mdc-chip__checkmark-path"
                              fill="none"
                              stroke="black"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                        </span>
                        <span role="gridcell">
                          <span
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="mdc-chip__primary-action"
                          >
                            <span class="mdc-chip__text">Parcial</span>
                          </span>
                        </span>
                      </div>
                      <div class="mdc-chip mdc-chip" role="row" id="f_borrado">
                        <div class="mdc-chip__ripple" />
                        <span class="mdc-chip__checkmark">
                          <svg
                            class="mdc-chip__checkmark-svg"
                            viewBox="-2 -3 30 30"
                          >
                            <path
                              class="mdc-chip__checkmark-path"
                              fill="none"
                              stroke="black"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                            />
                          </svg>
                        </span>
                        <span role="gridcell">
                          <span
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="mdc-chip__primary-action"
                          >
                            <span class="mdc-chip__text">Borrado</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        />
                        <th
                          style=""
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">ID</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha programada</th
                        >
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha de Inicio</th
                        >
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha de fin</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Unidad</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Operador</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Estado</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Origen</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Destino</th
                        >
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Rutas</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Incidencias</th
                        >
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Posicion final</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                        />
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each travel_report as item, i}
                        {#if (f_concluido && item.status == 0) || (f_en_proceso && item.status == 1) || (f_borrado && item.status == 2) || (f_parcial && item.status == 3)}
                          <tr class="mdc-data-table__row">
                            <td
                              class="mdc-data-table__cell"
                              style="padding-right:0px"
                            >
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() => {
                                  expaned == item.uuid
                                    ? (expaned = -1)
                                    : (expaned = item.uuid);
                                }}
                                class="mdc-icon-button material-icons lista-a"
                                >{expaned == item.uuid
                                  ? "keyboard_arrow_up"
                                  : "keyboard_arrow_down"}</button
                              >
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="padding-right:0px;"
                            >
                              <span
                                class={item.id_travel
                                  ? "badge badge-pill badge-primary"
                                  : ""}
                                style="font-size: 12px;"
                              >
                                {item.id_travel ? item.id_travel : "-"}
                              </span>
                            </td>
                            <td class="mdc-data-table__cell">
                              {moment(new Date(item.date_ini * 1000)).format(
                                "DD/MM/YY HH:mm"
                              )}
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="display:none;"
                            >
                              {typeof item.start != "undefined"
                                ? moment(new Date(item.start * 1000)).format(
                                    "DD/MM/YY HH:mm"
                                  )
                                : ""}
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="display:none;"
                            >
                              {typeof item.end != "undefined"
                                ? moment(new Date(item.end * 1000)).format(
                                    "DD/MM/YY HH:mm"
                                  )
                                : ""}
                            </td>
                            <td class="mdc-data-table__cell">
                              <strong
                                >{typeof $nicks_list[item.id] != "undefined"
                                  ? $nicks_list[item.id]
                                  : item.id}</strong
                              >
                            </td>
                            <td class="mdc-data-table__cell"
                              >{typeof $drivers[item.oper] != "undefined"
                                ? $drivers[item.oper].name
                                : item.oper}</td
                            >
                            <td class="mdc-data-table__cell">
                              <i
                                class="material-icons {item.status == 0
                                  ? 'green'
                                  : item.status == 1
                                    ? 'yei'
                                    : item.status == 2
                                      ? 'red'
                                      : item.status == 3
                                        ? 'gray'
                                        : 'orange'} mdc-list-item__graphic"
                                aria-hidden="true"
                                style="margin-right:0px"
                                >{item.status == 0
                                  ? "verified_user"
                                  : item.status == 1
                                    ? "autorenew"
                                    : item.status == 2
                                      ? "delete"
                                      : item.status == 3
                                        ? "rule"
                                        : "star_rate"}</i
                              >
                              {item.status == 0
                                ? "Cerrado"
                                : item.status == 1
                                  ? "En proceso"
                                  : item.status == 2
                                    ? "Borrado"
                                    : item.status == 3
                                      ? "Parcial"
                                      : ""}
                            </td>
                            <td class="mdc-data-table__cell"
                              >{typeof $geos_class[item.org] != "undefined"
                                ? $geos_class[item.org][0]
                                : typeof $poli[item.org] != "undefined"
                                  ? $poli[item.org][1]
                                  : item.org}</td
                            >
                            <td class="mdc-data-table__cell"
                              >{item.sec_dests.length > 0
                                ? typeof $geos_class[
                                    item.sec_dests[item.sec_dests.length - 1]
                                  ] != "undefined"
                                  ? $geos_class[
                                      item.sec_dests[item.sec_dests.length - 1]
                                    ][0]
                                  : typeof $poli[
                                        item.sec_dests[
                                          item.sec_dests.length - 1
                                        ]
                                      ] != "undefined"
                                    ? $poli[
                                        item.sec_dests[
                                          item.sec_dests.length - 1
                                        ]
                                      ][1]
                                    : item.sec_dests[item.sec_dests.length - 1]
                                : typeof $geos_class[item.dest] != "undefined"
                                  ? $geos_class[item.dest][0]
                                  : typeof $poli[item.dest] != "undefined"
                                    ? $poli[item.dest][1]
                                    : item.dest}</td
                            >
                            <td
                              class="mdc-data-table__cell"
                              style="display:none;"
                            >
                              {typeof $route_list_d[item.route] != "undefined"
                                ? $route_list_d[item.route]
                                : "No asignada"}
                              {#each item.sec_routes as item, i}
                                | {typeof $route_list_d[item] != "undefined"
                                  ? $route_list_d[item]
                                  : "No asignada"}
                              {/each}
                            </td>
                            <td class="mdc-data-table__cell">
                              {#if item.alerts.length > 0}
                                <i
                                  tooltip="{item.alerts.length} incidencias"
                                  class="material-icons red mdc-list-item__graphic"
                                  aria-hidden="true"
                                  style="margin-right:0px">report_problem</i
                                >
                                <strong>{item.alerts.length}</strong>
                              {:else}
                                <i
                                  tooltip="Sin incidencias"
                                  class="material-icons green mdc-list-item__graphic"
                                  aria-hidden="true"
                                  style="margin-right:0px">done_outline</i
                                >
                              {/if}
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="display:none;"
                            >
                              {typeof item.end_pos != "undefined"
                                ? item.end_pos[0] + " " + item.end_pos[1]
                                : ""}
                            </td>
                            <td class="mdc-data-table__cell">
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() => {}}
                                class="mdc-icon-button material-icons"
                                >more_vert</button
                              >
                            </td>
                          </tr>
                          {#if expaned == item.uuid}
                            <tr
                              class="mdc-data-table__row"
                              style="vertical-align: top;height: unset;background-color:rgba(0, 0, 0, 0.04)"
                            >
                              <td
                                class="mdc-data-table__cell"
                                colspan="10"
                                style="padding:16px"
                              >
                                {#if typeof item.reason != "undefined"}
                                  <div
                                    class="d-flex justify-content-between align-items-center"
                                  >
                                    {#if typeof item.start != "undefined"}
                                      <div>
                                        <strong>Inicio real: </strong>{moment(
                                          new Date(item.start * 1000)
                                        ).format("DD/MM/YY HH:mm")}
                                      </div>
                                    {/if}
                                    {#if typeof item.end != "undefined"}
                                      <div>
                                        <strong>Final real: </strong>{moment(
                                          new Date(item.end * 1000)
                                        ).format("DD/MM/YY HH:mm")}
                                      </div>
                                    {/if}
                                    <div>
                                      <strong
                                        >Tipo de cierre:
                                      </strong>{item.reason == 0
                                        ? "Unidad terminó viaje"
                                        : item.reason == 1
                                          ? "Unidad canceló viaje"
                                          : item.reason == 2
                                            ? "Unidad no inició viaje"
                                            : "?"}
                                    </div>
                                    <div>
                                      <button
                                        class="mdc-button mdc-button--outlined"
                                        on:click={() => {
                                          detalle_modal = true;
                                        }}
                                      >
                                        <div class="mdc-button__ripple" />
                                        <i
                                          class="material-icons mdc-button__icon"
                                          aria-hidden="true">map</i
                                        >
                                        <span class="mdc-button__label"
                                          >Detalle</span
                                        >
                                      </button>
                                      {#if item.forms}
                                        <button
                                          class="mdc-button mdc-button--outlined"
                                          on:click={() => {
                                            reporte_selected = item;
                                            formulario_modal = true;
                                          }}
                                        >
                                          <div class="mdc-button__ripple" />
                                          <i
                                            class="material-icons mdc-button__icon"
                                            aria-hidden="true"
                                          >
                                            assignment
                                          </i>
                                          <span class="mdc-button__label"
                                            >Formularios</span
                                          >
                                        </button>
                                      {/if}
                                    </div>
                                  </div>
                                {/if}
                                {#if item.alerts.length > 0}
                                  <div
                                    class="mdc-data-table"
                                    style="display:flex;margin-top:10px"
                                  >
                                    <table class="mdc-data-table__table">
                                      <thead>
                                        <tr class="mdc-data-table__header-row">
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Fecha</th
                                          >
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Nombre</th
                                          >
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Fecha de cierre</th
                                          >
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Posición</th
                                          >
                                          <th
                                            class="mdc-data-table__header-cell"
                                            role="columnheader"
                                            scope="col">Comentario</th
                                          >
                                        </tr>
                                      </thead>
                                      <tbody class="mdc-data-table__content">
                                        {#each item.alerts as item, i}
                                          <tr class="mdc-data-table__row">
                                            <td class="mdc-data-table__cell">
                                              {moment(
                                                new Date(
                                                  item.alerts_crtl.date_on *
                                                    1000
                                                )
                                              ).format("DD/MM/YY HH:mm")}
                                            </td>
                                            <td class="mdc-data-table__cell">
                                              {item.alerts.name}
                                            </td>
                                            <td class="mdc-data-table__cell">
                                              {typeof item.date_close !=
                                              "undefined"
                                                ? moment(
                                                    new Date(
                                                      item.date_close * 1000
                                                    )
                                                  ).format("DD/MM/YY HH:mm")
                                                : ""}
                                            </td>
                                            <td class="mdc-data-table__cell">
                                              {item.pos_data.lat},{item.pos_data
                                                .lon}
                                            </td>
                                            <td class="mdc-data-table__cell">
                                              {typeof item.comment !=
                                              "undefined"
                                                ? item.comment
                                                : ""}
                                            </td>
                                          </tr>
                                        {/each}
                                      </tbody>
                                    </table>
                                  </div>
                                {/if}
                                {#if typeof item.comment != "undefined"}
                                  <div style="display:flex;margin-top:10px">
                                    <strong
                                      >Comentario de cierre:
                                    </strong>{item.comment}
                                  </div>
                                {/if}
                              </td>
                            </tr>
                          {/if}
                        {/if}
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 1}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <div
                    style="display:flex;border-bottom: 1px solid #e0e0e0;height: 40px;align-items: center;"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      style="margin-left:16px;margin-right:0px">filter_list</i
                    >
                    <div
                      class="mdc-chip-set mdc-chip-set--filter"
                      role="grid"
                    />
                  </div>
                  <table class="mdc-data-table__table" id="alerts_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          style="display:none;"
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Id</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Unidad</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Operador</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Nombre</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Fecha de cierre</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Posición</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Comentario</th
                        >
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each travel_report as item_a, i}
                        {#if item_a.alerts.length > 0}
                          {#each item_a.alerts as item, i}
                            <tr class="mdc-data-table__row">
                              <td
                                class="mdc-data-table__cell"
                                style="padding-right:0px;display:none;"
                              >
                                {item_a.uuid}
                              </td>
                              <td class="mdc-data-table__cell">
                                {moment(
                                  new Date(item.alerts_crtl.date_on * 1000)
                                ).format("DD/MM/YY HH:mm")}
                              </td>
                              <td class="mdc-data-table__cell">
                                <strong
                                  >{typeof $nicks_list[item_a.id] != "undefined"
                                    ? $nicks_list[item_a.id]
                                    : item_a.id}</strong
                                >
                              </td>
                              <td class="mdc-data-table__cell">
                                {typeof $drivers[item_a.oper] != "undefined"
                                  ? $drivers[item_a.oper].name
                                  : item_a.oper}
                              </td>
                              <td class="mdc-data-table__cell">
                                {item.alerts.name}
                              </td>
                              <td class="mdc-data-table__cell">
                                {typeof item.date_close != "undefined"
                                  ? moment(
                                      new Date(item.date_close * 1000)
                                    ).format("DD/MM/YY HH:mm")
                                  : ""}
                              </td>
                              <td class="mdc-data-table__cell">
                                {item.pos_data.lat}
                                {item.pos_data.lon}
                              </td>
                              <td class="mdc-data-table__cell">
                                {typeof item.comment != "undefined"
                                  ? item.comment
                                  : ""}
                              </td>
                            </tr>
                          {/each}
                        {/if}
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 5}
                <div class="col-md h-100 w-100" use:initMap style="padding:0">
                  <div class="row h-60 no-gutters w-100">
                    <div class="col-md-12 h-100 w-100">
                      <div class="h-100 w-100" id="map" />
                    </div>
                  </div>
                  {#if !gaia_runing}
                    <div class="row h-40 no-gutters w-100">
                      <div
                        class="col-md-4 h-100 border"
                        style="border-color:#9e9d99"
                      >
                        <table class="table table-striped table-hover table-sm">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">
                                <i
                                  tooltip="Viaje"
                                  class="material-icons"
                                  style="font-size:13px">calendar_today</i
                                >
                                Estimar viajes
                                {#if gaia_free}
                                  <select
                                    name="tipo_ruta"
                                    id="tipo_ruta"
                                    bind:value={tipo_ruta}
                                  >
                                    <option value="o" selected>Cuota</option>
                                    <option value="l">Preferente Libre</option>
                                  </select>
                                {/if}
                              </th>
                              {#if !gaia_free}
                                <th scope="col" style="padding:0.1rem">
                                  <button
                                    on:click={new_travel}
                                    id="edit_prog"
                                    type="button"
                                    class="btn btn-success mini"
                                    >Nuevo viaje</button
                                  >
                                </th>
                              {/if}
                            </tr>
                          </thead>
                          <div class="d-flex flex-column flex-grow-1 p-2">
                            <div>
                              <i
                                tooltip="Origen"
                                class="material-icons md-90"
                                style="color:#26a69a">play_circle_outline</i
                              >
                              <input
                                list="org_list"
                                placeholder="Origen"
                                size="30"
                                on:change={(event) => {
                                  org_selected = "";
                                  for (let x in $geos_class_list) {
                                    if (
                                      $geos_class_list[x][1] ==
                                      event.target.value
                                    ) {
                                      org_selected = $geos_class_list[x][0];
                                    }
                                  }
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={org_selected_}
                              />
                              <datalist id="org_list">
                                {#each $geos_class_list as item, i}
                                  <option value={item[1]} />{/each}
                              </datalist>
                            </div>
                            <div>
                              <i
                                tooltip="Destino"
                                class="material-icons md-90"
                                style="color:#26a69a">stop</i
                              >
                              <input
                                list="dest_list"
                                placeholder="Destino"
                                size="30"
                                on:change={(event) => {
                                  dest_selected = "";
                                  for (let x in $geos_class_list) {
                                    if (
                                      $geos_class_list[x][1] ==
                                      event.target.value
                                    ) {
                                      dest_selected = $geos_class_list[x][0];
                                    }
                                  }
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={dest_selected_}
                              />
                              <datalist id="dest_list">
                                {#each $geos_class_list as item, i}
                                  <option value={item[1]} />{/each}
                              </datalist>
                            </div>
                            <div>
                              <i
                                tooltip="Tipo"
                                class="material-icons md-90"
                                style="color:#26a69a">commute</i
                              >
                              <input
                                list="type_list"
                                placeholder="Tipo de unidad"
                                size="30"
                                on:change={(event) => {
                                  tunidad_selected = event.target.value;
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={tunidad_selected_}
                              />
                              <datalist id="type_list">
                                <option value="Motocicleta" /><option
                                  value="Automóvil"
                                /><option value="Autobús" /><option
                                  value="Camión"
                                /></datalist
                              >
                            </div>
                            <div>
                              <i
                                tooltip="Ejes"
                                class="material-icons md-90"
                                style="color:#26a69a">timeline</i
                              >
                              <input
                                list="ejes_list"
                                placeholder="Numero de ejes"
                                size="30"
                                on:change={(event) => {
                                  ejes_selected = event.target.value;
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={ejes_selected_}
                              />
                              <datalist id="ejes_list">
                                <option value="2" /><option value="3" /><option
                                  value="4"
                                /><option value="5" /><option
                                  value="6"
                                /><option value="7" /><option
                                  value="8"
                                /><option value="9" /></datalist
                              >
                            </div>
                            <div>
                              <i
                                tooltip="Ejes excedentes"
                                class="material-icons md-90"
                                style="color:#26a69a">timeline</i
                              >
                              <input
                                list="ejes2_list"
                                placeholder="Numero de ejes excedentes"
                                size="30"
                                on:change={(event) => {
                                  ejes2_selected = event.target.value;
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={ejes2_selected_}
                              />
                              <datalist id="ejes2_list">
                                <option value="0" /><option value="1" /><option
                                  value="2"
                                /><option value="3" /><option
                                  value="4"
                                /></datalist
                              >
                            </div>
                            <div>
                              <i
                                tooltip="Redimiento"
                                class="material-icons md-90"
                                style="color:#26a69a">eco</i
                              >
                              <input
                                type="text"
                                placeholder="Redimiento por litro"
                                size="30"
                                on:change={(event) => {
                                  rendimiento = event.target.value;
                                }}
                                disabled={gaia_free ? "" : "disabled"}
                                bind:value={rendimiento_}
                              />
                            </div>
                          </div>
                        </table>
                      </div>
                      <div
                        class="col-md-4 h-100 border"
                        style="border-color:#9e9d99"
                      >
                        <div
                          class="mdc-data-table"
                          style="display:flex"
                          use:data_table_on2
                        >
                          <table
                            class="mdc-data-table__table"
                            id="casetas_table"
                          >
                            <thead>
                              <tr
                                class="mdc-data-table__header-row"
                                style="height:1px"
                              >
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col">Datos de viaje</th
                                >
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col">Valor</th
                                >
                              </tr>
                            </thead>
                            <tbody class="mdc-data-table__content">
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Costo de casetas
                                </td>
                                <td class="mdc-data-table__cell">
                                  <strong
                                    >{formatter.format(costo_caseta)}</strong
                                  >
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Costo de combustible
                                </td>
                                <td class="mdc-data-table__cell">
                                  <strong
                                    >{formatter.format(
                                      (long_km / parseInt(rendimiento)) *
                                        costo_diesel
                                    )}</strong
                                  >
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Costo de total
                                </td>
                                <td
                                  class="mdc-data-table__cell"
                                  style="color:red"
                                >
                                  <strong
                                    >{formatter.format(
                                      costo_caseta +
                                        (long_km / parseInt(rendimiento)) *
                                          costo_diesel
                                    )}</strong
                                  >
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Distancia
                                </td>
                                <td class="mdc-data-table__cell">
                                  <strong>{long_km} KM</strong>
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell"> Tiempo </td>
                                <td class="mdc-data-table__cell">
                                  <strong>{tiempo_min}</strong>
                                </td>
                              </tr>
                              <tr
                                class="mdc-data-table__row"
                                style="height:1px"
                              >
                                <td class="mdc-data-table__cell">
                                  Costo diesel
                                </td>
                                <td class="mdc-data-table__cell">
                                  <!--<strong>{formatter.format(costo_diesel)}</strong>-->
                                  <input
                                    type="text"
                                    bind:value={costo_diesel}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="col-md-4 h-100 overflow-auto border"
                        style="border-color:#9e9d99"
                      >
                        <div
                          class="mdc-data-table"
                          style="display:flex"
                          use:data_table_on2
                        >
                          <table
                            class="mdc-data-table__table"
                            id="casetas_table"
                          >
                            <thead>
                              <tr
                                class="mdc-data-table__header-row"
                                style="height:1px"
                              >
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col">Nombre de caseta</th
                                >
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col">Costo</th
                                >
                              </tr>
                            </thead>
                            <tbody class="mdc-data-table__content">
                              {#each gaia_casetas_l as item, i}
                                <tr
                                  class="mdc-data-table__row"
                                  style="height:1px"
                                >
                                  <td class="mdc-data-table__cell">
                                    <a
                                      href="/"
                                      on:click|preventDefault={() => {
                                        map.setZoom(13);
                                        map.panTo({
                                          lat: item[2],
                                          lng: item[3],
                                        });
                                      }}
                                    >
                                      {item[0]}
                                    </a>
                                  </td>
                                  <td class="mdc-data-table__cell">
                                    <strong>{formatter.format(item[1])}</strong>
                                  </td>
                                </tr>
                              {/each}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  {:else}
                    <div
                      class="h-40 d-flex flex-column justify-content-center flex-grow-1 align-items-center border"
                      style="border-color:#9e9d99"
                    >
                      <div
                        class="spinner-border text-danger"
                        style="width: 6rem; height: 6rem;"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  {/if}
                </div>
              {/if}
              {#if selected == 6}
                <div style="margin: 10px; height: 90%;">
                  <input
                    type="text"
                    id="url_usage"
                    style="width: 500px; margin-"
                  />
                  <button style="margin-bottom:10px;" on:click={unit_usage}
                    >Enviar</button
                  >
                  <iframe
                    id="ifram_unit_usage"
                    src={units_usage_url}
                    style="width: 100%; height: 100%;"
                    title="Unit usage"
                  ></iframe>
                </div>
              {/if}
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .h-30 {
    height: 30%;
  }
  .h-70 {
    height: 70%;
  }
  .h-40 {
    height: 40%;
  }
  .h-60 {
    height: 60%;
  }
  .h-49 {
    height: 49%;
  }
  .h-2 {
    height: 2%;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .bg-secondary3 {
    background-color: #8afd9e;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  .mdc-data-table__row {
    height: 45px;
  }
  .mdc-data-table__header-row {
    height: 45px;
  }
  .mdc-chip__text:focus {
    outline: unset;
  }
  .mdc-chip__primary-action:focus {
    outline: unset;
  }
  .mdc-icon-button {
    width: 24px;
    height: 24px;
    padding: 0px;
  }
  .mdc-data-table__table {
    white-space: normal;
  }

  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }

  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  .watermark {
    position: fixed;
    bottom: 40px;
    right: 80px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3); /* Color negro con 30% de opacidad */
    z-index: 1000;
    pointer-events: none; /* Para que no interfiera con otros elementos */
  }
</style>
