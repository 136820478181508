<script>
  //https://programadorwebvalencia.com/javascript-recortar-y-previsualizar-imagen/
  export let socket_query;
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    travels_list,
    poli,
    map_ready,
    group,
  } from "./stores.js";
  let move_actividad_scroll = false;
  let first_actividad_scroll = true;
  afterUpdate(() => {
    if (move_actividad_scroll) {
      let element = document.getElementById("actividad_scroll");
      if (element) {
        element.scrollTop = element.scrollHeight;
        move_actividad_scroll = false;
      }
    }
  });
  onDestroy(() => {
    socket_query.off("hos");
    clearInterval(hos_time);
  });
  let selected = !localStorage.getItem("selected_driver_hos")
    ? -1
    : parseInt(localStorage.getItem("selected_driver_hos"));
  $: {
    localStorage.setItem("selected_driver_hos", selected);
    first_actividad_scroll = true;
    run_HOS();
  }
  $: {
    localStorage.setItem("driver_fav", JSON.stringify(driver_fav));
  }
  $: {
    if ($drivers_list[selected]) {
      socket_query.emit("hos", { driver: $drivers_list[selected][0] });
    }
  }
  $: {
    if ($drivers_list) {
      for (let x in $drivers_list) {
        socket_query.emit("hos", { driver: $drivers_list[x][0] });
      }
    }
  }
  let hos_time = setInterval(() => {
    for (let x in $drivers_list) {
      socket_query.emit("hos", { driver: $drivers_list[x][0] });
    }
  }, 60000);

  async function maping(i, manual = false) {
    if (manual) {
      table_hos_turns_selected = -1;
      selected = i;
    }
  }
  let chartInstances = {};
  function createTimeChart(chartId, centerText, used, total) {
    if (!document.getElementById(chartId)) return;
    let backgroundColor = "#E5E5E5"; // Gris por defecto
    if (used < 0) {
      used = 0;
      backgroundColor = "#FF0000"; // Rojo si se excede
    }
    // Calcula la proporción
    const ratio = total > 0 ? used / total : 0;
    let usedColor = "#36A2EB"; // Azul por defecto

    if (ratio <= 0.25) {
      usedColor = "red";
    } else if (ratio <= 0.5) {
      usedColor = "#ffc107";
    }
    // Si ratio > 0.5, se queda en azul

    // Si ya existe la instancia, se actualiza
    if (chartInstances[chartId]) {
      const existingChart = chartInstances[chartId];
      existingChart.data.datasets[0].data = [used, Math.max(total - used, 0)];
      existingChart.data.datasets[0].backgroundColor = [
        usedColor,
        backgroundColor,
      ];
      existingChart.options.plugins.centerText.text = centerText;

      existingChart.update();
      return existingChart;
    }

    // Si no existe, se crea por primera vez
    const ctx = document.getElementById(chartId).getContext("2d");
    const newChart = new ChartV2(ctx, {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [used, total - used],
            backgroundColor: [
              usedColor, // Color "usado", dinámico según la proporción
              backgroundColor, // Color "restante"
            ],
            borderWidth: 0,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
          centerText: {
            text: centerText,
          },
        },
        cutout: "85%",
      },
    });

    // Guardamos la referencia
    chartInstances[chartId] = newChart;
    return newChart;
  }
  async function run_HOS() {
    if (!window.ChartV2) {
      let Chart_old = window.Chart;
      await loadScript(
        "https://cdn.jsdelivr.net/npm/chart.js@4.4.7/dist/chart.umd.min.js"
      );
      window.ChartV2 = window.Chart; // Opcional: exponer globalmente
      window.Chart = Chart_old; // Restaurar referencia original
    }
    // Plugin para dibujar texto en el centro
    const centerTextPlugin = {
      id: "centerText",
      afterDraw(chart, args, options) {
        const {
          ctx,
          chartArea: { left, right, top, bottom },
        } = chart;
        const text = options.text || "";

        ctx.save();
        ctx.font = "bold 35px sans-serif";
        ctx.fillStyle = "#333";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const x = left + (right - left) / 2;
        const y = top + (bottom - top) / 2;
        ctx.fillText(text, x, y);
        ctx.restore();
      },
    };
    ChartV2.register(centerTextPlugin);
    createTimeChart("chartBreak", "08:00", 8, 8);
    createTimeChart("chartDrive", "11:00", 11, 11);
    createTimeChart("chartShift", "14:00", 14, 14);
    createTimeChart("chartCycle", "60:00", 60, 60);
    run_bitacora("hos_graph");
  }
  let analisis_status = "bitacora";
  function extraerValoresDivididos(arr, clave) {
    return arr
      .map((obj) => (obj[clave] !== undefined ? obj[clave] / 60 : undefined))
      .filter((valor) => valor !== undefined);
  }
  function run_bitacora(
    chartId,
    new_data = [],
    new_data2 = { labels: [], datasets: [] }
  ) {
    if (!document.getElementById(chartId)) return;
    const ctx = document.getElementById(chartId).getContext("2d");
    // Bitácora de horas de servicio
    const data = {
      datasets: [
        {
          label: "Horas de Servicio",
          data: new_data,
          borderWidth: 4,
          borderColor: "rgba(54, 162, 235, 1)",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          tension: 0, // Sin curvatura
          stepped: true, // Línea escalonada (estilo bitácora)
          segment: {
            borderColor: (ctx) => {
              // ctx.p0 y ctx.p1 son los 2 puntos que forman el segmento
              const estadoInicial = ctx.p0.parsed.y;
              // Si deseas basarte en el punto final en vez del inicial:
              const estadoFinal = ctx.p1.parsed.y;

              switch (estadoInicial) {
                case 0:
                  return "rgba(92, 184, 92)";
                case 1:
                  return "rgba(253, 126, 20)";
                case 2:
                  return "rgba(0, 123, 255)";
                default:
                  return "gray";
              }
            },
          },
        },
      ],
    };
    const config = {
      type: "line",
      data: data,
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Bitácora de turno",
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            type: "linear", // Eje horizontal numérico (0-24)
            min: 0,
            //max: 24, // Máximo valor de horas
            title: {
              display: true,
              text: "Horas",
            },
            ticks: {
              stepSize: 1, // Muestra cada hora
            },
          },
          y: {
            type: "category", // Eje vertical con categorías para los estados
            labels: ["Descanso", "En Servicio", "Conducción"],
            title: {
              display: false,
              text: "Estados HOS",
            },
            reverse: true, // Activar si deseas que aparezca 'Sin Viaje' arriba
          },
        },
      },
    };

    // Datos  últimos 7 días
    const data2 = {
      labels: new_data2.labels,
      datasets: [
        {
          label: "Conducción",
          data: extraerValoresDivididos(new_data2.datasets, "Driving"), // Ejemplo de horas de conducción por día
          backgroundColor: "rgba(0, 123, 255, 0.8)",
          borderColor: "rgba(0, 123, 255, 1)",
          borderWidth: 1,
        },
        {
          label: "En servicio",
          data: extraerValoresDivididos(new_data2.datasets, "OnDuty"), // Horas en servicio (no manejando)
          backgroundColor: "rgba(253, 126, 20, 0.8)",
          borderColor: "rgba(253, 126, 20, 1)",
          borderWidth: 1,
        },
        {
          label: "Descanso",
          data: extraerValoresDivididos(new_data2.datasets, "OffDuty"), // Horas de descanso (sleeper berth)
          backgroundColor: "rgba(92, 184, 92, 0.8)",
          borderColor: "rgba(92, 184, 92, 1)",
          borderWidth: 1,
        },
      ],
    };
    const config2 = {
      type: "bar",
      data: data2,
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: "Turno",
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            title: {
              display: true,
              text: "Horas",
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: "Acumulado de turnos",
          },
          legend: {
            position: "top",
          },
        },
      },
    };

    if (
      chartInstances[chartId] &&
      ((chartInstances[chartId].config.type == "line" &&
        analisis_status == "bitacora") ||
        (chartInstances[chartId].config.type == "bar" &&
          analisis_status == "acumulado"))
    ) {
      chartInstances[chartId].data =
        analisis_status == "bitacora" ? data : data2;
      chartInstances[chartId].update();
    } else {
      if (chartInstances[chartId]) chartInstances[chartId].destroy();
      chartInstances[chartId] = new ChartV2(
        ctx,
        analisis_status == "bitacora" ? config : config2
      );
    }
  }
  function loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Error loading script: ${url}`));
      document.body.appendChild(script);
    });
  }
  let table_hos = [];
  var table_hos_travels = [];
  let table_hos_turns = [];
  let table_hos_turns_selected = -1;
  let drive_max = 11;
  let icons_driver = {};
  let icons_driver_error = {};
  const generarLista = (n) => Array.from({ length: n }, (_, i) => i + 1);
  socket_query.on("hos", async function (data) {
    let p_ini = new Date().getTime();
    // Iconos de estado
    if (data.array_times.length == 0) icons_driver[data.driver] = "Descanso";
    else if (data.array_times[data.array_times.length - 1].type == "OffDuty")
      icons_driver[data.driver] = "Descanso";
    else if (data.array_times[data.array_times.length - 1].type == "OnDuty")
      icons_driver[data.driver] = "En Servicio";
    else if (data.array_times[data.array_times.length - 1].type == "Driving")
      icons_driver[data.driver] = "Conducción";
    // iconos de error
    let ratio_brek =
      (data.break.max * 60 - data.break.minutes) / (data.break.max * 60);
    let ratio_drive =
      (data.drive.max * 60 - data.drive.minutes) / (data.drive.max * 60);
    let ratio_shift =
      (data.shift.max * 60 - data.shift.minutes) / (data.shift.max * 60);
    let ratio_cycle =
      (data.cycle.max * 60 - data.cycle.minutes) / (data.cycle.max * 60);
    if (ratio_brek < 0 || ratio_drive < 0 || ratio_shift < 0 || ratio_cycle < 0)
      icons_driver_error[data.driver] = "Error";
    else if (
      ratio_brek <= 0.25 ||
      ratio_drive <= 0.25 ||
      ratio_shift <= 0.25 ||
      ratio_cycle <= 0.25
    )
      icons_driver_error[data.driver] = "Warning";
    else icons_driver_error[data.driver] = "";

    if ($drivers_list[selected][0] != data.driver) return;
    const formatearMinutos = (m) => {
      let signo = m < 0 ? "-" : "";
      m = m < 0 ? Math.abs(m) : m;
      return (
        signo +
        ("0" + Math.floor(m / 60)).slice(-2) +
        ":" +
        ("0" + (m % 60)).slice(-2)
      );
    };
    if (ChartV2) {
      createTimeChart(
        "chartBreak",
        formatearMinutos(data.break.max * 60 - data.break.minutes),
        data.break.max * 60 - data.break.minutes,
        data.break.max * 60
      );
      createTimeChart(
        "chartDrive",
        formatearMinutos(data.drive.max * 60 - data.drive.minutes),
        data.drive.max * 60 - data.drive.minutes,
        data.drive.max * 60
      );
      createTimeChart(
        "chartShift",
        formatearMinutos(data.shift.max * 60 - data.shift.minutes),
        data.shift.max * 60 - data.shift.minutes,
        data.shift.max * 60
      );
      createTimeChart(
        "chartCycle",
        formatearMinutos(data.cycle.max * 60 - data.cycle.minutes),
        data.cycle.max * 60 - data.cycle.minutes,
        data.cycle.max * 60
      );
    }
    // HOS table
    table_hos = [];
    let tramo_date;
    let time_accum = 0;
    for (let x in data.array_times) {
      if (data.array_times[x].tramo) {
        tramo_date = data.array_times[x].ds;
        time_accum = 0;
      }
      if (data.array_times[x].type) {
        if (!data.array_times[x].date_start) {
          data.array_times[x].date_start = tramo_date + time_accum * 60;
          data.array_times[x].date_end =
            data.array_times[x].date_start + data.array_times[x].time * 60;
        }
        data.array_times[x].id = x;
        table_hos.push(data.array_times[x]);
      }
      if (data.array_times[x].time) {
        time_accum += data.array_times[x].time;
      }
    }
    // Reduccion de iguales
    let last_type_table;
    let table_hos_ = [];
    for (let x in table_hos) {
      if (table_hos[x].source || table_hos[x].part == "manual") {
        table_hos_.push(table_hos[x]);
        last_type_table = "";
      } else if (last_type_table == table_hos[x].type) {
        table_hos_[table_hos_.length - 1].time += table_hos[x].time;
        table_hos_[table_hos_.length - 1].date_end = table_hos[x].date_end;
      } else {
        table_hos_.push(table_hos[x]);
        last_type_table = table_hos[x].type;
      }
    }
    table_hos = table_hos_; //.reverse();
    //console.log("table_hos", table_hos);
    // Search travels
    table_hos_travels = [];
    let table_hos_travels_next = false;
    const table_hos_c = JSON.parse(JSON.stringify(table_hos));
    for (let x = 0; x < table_hos_c.length; x++) {
      if (x == 0) {
        table_hos_travels.push({
          ...table_hos_c[x],
          Driving: table_hos_c[x].type == "Driving" ? table_hos_c[x].time : 0,
          OffDuty: table_hos_c[x].type == "OffDuty" ? table_hos_c[x].time : 0,
          OnDuty: table_hos_c[x].type == "OnDuty" ? table_hos_c[x].time : 0,
        });
      } else if (table_hos_c[x].part == "between_travels") {
        table_hos_travels_next = true;
      } else if (table_hos_travels_next) {
        table_hos_travels_next = false;
        table_hos_travels.push({
          ...table_hos_c[x],
          Driving: table_hos_c[x].type == "Driving" ? table_hos_c[x].time : 0,
          OffDuty: table_hos_c[x].type == "OffDuty" ? table_hos_c[x].time : 0,
          OnDuty: table_hos_c[x].type == "OnDuty" ? table_hos_c[x].time : 0,
        });
      } else {
        table_hos_travels[table_hos_travels.length - 1].time +=
          table_hos_c[x].time;
        table_hos_travels[table_hos_travels.length - 1].date_end =
          table_hos_c[x].date_end;
        table_hos_travels[table_hos_travels.length - 1][table_hos_c[x].type] +=
          table_hos_c[x].time;
      }
    }
    // Search turnos
    let reset_turno = data.hos_config.DRIVE_REST_HRS * 60;
    drive_max = data.hos_config.DRIVE_MAX_HRS;
    table_hos_turns = [];
    let table_hos_clean = [];
    let table_hos_clean_last = "";
    for (let x = 0; x < table_hos_c.length; x++) {
      if (table_hos_clean_last != table_hos_c[x].type) {
        table_hos_clean.push(table_hos_c[x]);
        table_hos_clean_last = table_hos_c[x].type;
      } else {
        table_hos_clean[table_hos_clean.length - 1].time += table_hos_c[x].time;
        table_hos_clean[table_hos_clean.length - 1].date_end =
          table_hos_c[x].date_end;
      }
    }
    for (let x in table_hos_clean) {
      if (
        table_hos_clean[x].type == "OffDuty" &&
        table_hos_clean[x].time >= reset_turno
      ) {
        table_hos_turns.push({
          date_start: table_hos_clean[x].date_end,
          date_end: table_hos_clean[x].date_end,
          time: 0,
          Driving: 0,
          OffDuty: 0,
          OnDuty: 0,
          graph: [],
        });
      } else if (table_hos_turns.length > 0) {
        // Grafico
        table_hos_turns[table_hos_turns.length - 1].graph.push({
          x: table_hos_turns[table_hos_turns.length - 1].time / 60,
          y:
            table_hos_clean[x].type == "Driving"
              ? "Conducción"
              : table_hos_clean[x].type == "OnDuty"
                ? "En Servicio"
                : table_hos_clean[x].type == "OffDuty"
                  ? "Descanso"
                  : "Sin Viaje",
        });

        table_hos_turns[table_hos_turns.length - 1].time +=
          table_hos_clean[x].time;
        table_hos_turns[table_hos_turns.length - 1].date_end =
          table_hos_clean[x].date_end;
        table_hos_turns[table_hos_turns.length - 1][table_hos_clean[x].type] +=
          table_hos_clean[x].time;

        // Grafico
        table_hos_turns[table_hos_turns.length - 1].graph.push({
          x: table_hos_turns[table_hos_turns.length - 1].time / 60,
          y:
            table_hos_clean[x].type == "Driving"
              ? "Conducción"
              : table_hos_clean[x].type == "OnDuty"
                ? "En Servicio"
                : table_hos_clean[x].type == "OffDuty"
                  ? "Descanso"
                  : "Sin Viaje",
        });
      }
    }
    //console.log("table_hos_turns", table_hos_turns);
    if (ChartV2 && table_hos_turns.length > 0) {
      if (table_hos_turns_selected == -1)
        table_hos_turns_selected = table_hos_turns.length - 1;
      if (table_hos_turns[table_hos_turns_selected])
        run_bitacora(
          "hos_graph",
          table_hos_turns[table_hos_turns_selected].graph,
          {
            labels: generarLista(table_hos_turns.length),
            datasets: table_hos_turns,
          }
        );
      else run_bitacora("hos_graph");
    } else run_bitacora("hos_graph");
    let turn_numer = 0;
    for (let x = 0; x < table_hos_turns.length; x++) {
      for (let y in table_hos) {
        if (!table_hos[y].turn) table_hos[y].turn = turn_numer;
        if (
          Math.abs(table_hos[y].date_start - table_hos_turns[x].date_start) < 60
        ) {
          table_hos[y].turn_ini = x;
          turn_numer = x + 1;
        }
        if (
          Math.abs(table_hos[y].date_end - table_hos_turns[x].date_end) < 60
        ) {
          table_hos[y].turn_end = x;
          turn_numer = "";
          break;
        }
      }
    }
    if (first_actividad_scroll) {
      move_actividad_scroll = true;
      first_actividad_scroll = false;
    }
    let p_fin = new Date().getTime();
    console.log("Tiempo de ejecucion hos", p_fin - p_ini);
  });
  let detalle_modal = false;
  let original_hos = {};
  let lista_new_hos = [];
  const minToHm = (m) =>
    m >= 60 ? `${~~(m / 60)}h${m % 60 ? ` ${m % 60}m` : ""}` : `${m}m`;
  $: {
    if (
      lista_new_hos.reduce((suma, obj) => suma + obj.time, 0) >
      original_hos.time
    ) {
      // No se puede exceder el tiempo original, ajustar los necesarios para que no se exceda
      let diff =
        lista_new_hos.reduce((suma, obj) => suma + obj.time, 0) -
        original_hos.time;
      for (let i = 0; i < lista_new_hos.length; i++) {
        if (diff == 0) break;
        if (lista_new_hos[i].time > diff) {
          lista_new_hos[i].time -= diff;
          diff = 0;
        } else {
          diff -= lista_new_hos[i].time;
          lista_new_hos[i].time = 0;
        }
      }
    }
    // Solo numero positivos
    lista_new_hos = lista_new_hos.map((obj) => {
      obj.time = Math.max(0, obj.time);
      return obj;
    });
  }
  let turn_travel_option = "turn";
  let driver_fav = !localStorage.getItem("driver_fav")
    ? []
    : JSON.parse(localStorage.getItem("driver_fav"));
</script>

{#if detalle_modal}
  <div
    class="modal fade {detalle_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="display: {detalle_modal
      ? 'block'
      : 'none'}; z-index:1039; top: 50%; left: 50%; width: 50%; max-height:500px; max-width: 600px; transform: translate(-50%, -50%);"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
      style="min-width: 95%"
    >
      <div class="modal-content" style="min-height: 99%; overflow: visible;">
        <div class="modal-header" style="padding: 0.5rem 0.5rem;">
          <h5
            class="modal-title text-center"
            id="modaltitle"
            style="margin-left: 10px;"
          >
            <i class="material-icons bblue md-2">traffic</i> Editar HOS
          </h5>
          <button
            type="button"
            class="close"
            on:click={() => {
              detalle_modal = false;
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body h-100 p-0">
          <div class="row h-100 no-gutters">
            <div
              class="col-12 h-100"
              style="padding: 0px 20px 20px 20px;display: flex;flex-direction: column;"
            >
              <div style="margin: 10px;">
                <strong>Fecha de inicio</strong>
                {moment(new Date(original_hos.date_start * 1000)).format(
                  "DD/MM/YY HH:mm"
                )}
              </div>
              <div style="margin: 10px;">
                <strong>Fecha de fin</strong>
                {moment(new Date(original_hos.date_end * 1000)).format(
                  "DD/MM/YY HH:mm"
                )}
              </div>
              <div
                style="margin: 10px;{original_hos.time -
                  lista_new_hos.reduce((suma, obj) => suma + obj.time, 0) >
                0
                  ? 'color:red;'
                  : ''}"
              >
                <strong>Minutos faltantes</strong>
                {original_hos.time -
                  lista_new_hos.reduce((suma, obj) => suma + obj.time, 0)}
              </div>
              <div style="margin: 10px;flex-grow: 1;overflow:auto;">
                <table class="table table-sm table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Minutos</th>
                      <th>Actividad</th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {#each lista_new_hos as item, i}
                      <tr>
                        <td
                          ><input
                            type="number"
                            bind:value={item.time}
                            style="font-size: 1rem;"
                          /></td
                        >
                        <td>
                          <!-- select actividad-->
                          <select class="form-control" bind:value={item.type}>
                            <option value="OnDuty">En Servicio</option>
                            <option value="OffDuty">Descanso</option>
                            <option value="Unknown">Pendiente</option>
                          </select>
                        </td>
                        <td>
                          {#if lista_new_hos.length - 1 == i}
                            <button
                              class="btn btn-sm btn-primary"
                              on:click={() => {
                                lista_new_hos.unshift({
                                  time: 0,
                                  type: "OnDuty",
                                });
                                lista_new_hos = [...lista_new_hos];
                              }}
                            >
                              <i class="material-icons" style="font-size: 18px;"
                                >add</i
                              >
                              Agregar
                            </button>
                          {:else if lista_new_hos.length > 1}
                            <button
                              class="btn btn-sm btn-danger"
                              on:click={() => {
                                lista_new_hos.splice(i, 1);
                                lista_new_hos = [...lista_new_hos];
                              }}
                            >
                              <i class="material-icons" style="font-size: 18px;"
                                >delete</i
                              >
                              Eliminar
                            </button>
                          {/if}
                        </td>
                      </tr>
                    {/each}
                  </tbody>
                </table>
              </div>
              <div style="display: flex;justify-content: flex-end;">
                <button
                  class="btn btn-primary"
                  disabled={original_hos.time !=
                    lista_new_hos.reduce((suma, obj) => suma + obj.time, 0)}
                  on:click={() => {
                    let new_hos = [];
                    for (let x in lista_new_hos) {
                      new_hos.push({
                        time: lista_new_hos[x].time,
                        type: lista_new_hos[x].type,
                      });
                    }
                    socket_query.emit(
                      "edit_hos",
                      {
                        driver: $drivers_list[selected][0],
                        original_hos: original_hos,
                        new_hos: new_hos,
                      },
                      (res) => {
                        if (res == "ok") {
                          socket_query.emit("hos", {
                            driver: $drivers_list[selected][0],
                          });
                        }
                      }
                    );
                    detalle_modal = false;
                  }}
                >
                  <i class="material-icons" style="font-size: 18px;">save</i>
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div
            class="card-header bg-dark text-white b-0"
            style="padding-right: 0.2rem;"
          >
            <div class="d-flex">
              <div class="flex-grow-1">
                <div class="flex-grow-1">
                  <i
                    tooltip="Operador"
                    class="material-icons md-90"
                    style="margin-right: 5px;">traffic</i
                  > HOS - Horarios de Servicio
                </div>
              </div>
            </div>
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              <div
                style="border: {driver_fav.length > 0
                  ? '3px'
                  : '0px'} solid #13834a;"
              >
                {#each driver_fav as item, i}
                  {#if $drivers[item[0]]}
                    <a
                      href="/{item[0]}"
                      class:list-group-item-warning={selected == item[1]}
                      class:active_={selected == item[1]}
                      on:click|preventDefault={() => {
                        maping(item[1], true);
                      }}
                      class="list-group-item list-group-item-action"
                      style="display: flex;justify-content: space-between;align-items: center;"
                    >
                      <div>
                        <i
                          class="material-icons"
                          style="font-size: 22px;color:orange;cursor:pointer;z-index: 100;"
                          on:click|stopPropagation|preventDefault={() => {
                            driver_fav.splice(i, 1);
                            driver_fav = [...driver_fav];
                          }}>star</i
                        >
                        {typeof $drivers[item[0]].name != "undefined"
                          ? $drivers[item[0]].name
                          : item}
                      </div>
                      <div>
                        {#if icons_driver_error[item[0]] == "Error"}
                          <i
                            class="material-icons"
                            style="font-size: 22px;color:red;">error</i
                          >
                        {:else if icons_driver_error[item[0]] == "Warning"}
                          <i
                            class="material-icons"
                            style="font-size: 22px;color:orange;">warning</i
                          >
                        {/if}
                        {#if icons_driver[item[0]]}
                          <i
                            class="material-icons"
                            style="font-size: 22px;color:{icons_driver[
                              item[0]
                            ] == 'Descanso'
                              ? 'green'
                              : icons_driver[item[0]] == 'En Servicio'
                                ? 'orange'
                                : icons_driver[item[0]] == 'Conducción'
                                  ? 'blue'
                                  : 'gray'};"
                            >{icons_driver[item[0]] == "Descanso"
                              ? "hotel"
                              : icons_driver[item[0]] == "En Servicio"
                                ? "work"
                                : icons_driver[item[0]] == "Conducción"
                                  ? "directions_car"
                                  : "error"}</i
                          >
                        {/if}
                      </div>
                    </a>
                  {/if}
                {/each}
              </div>
              {#each $drivers_list as item, i}
                {#if !driver_fav.some((obj) => obj[0] === item[0])}
                  <a
                    href="/{item[0]}"
                    class:list-group-item-warning={selected == i}
                    class:active_={selected == i}
                    on:click|preventDefault={() => {
                      maping(i, true);
                    }}
                    class="list-group-item list-group-item-action"
                    style="display: flex;justify-content: space-between;align-items: center;"
                  >
                    <div>
                      <i
                        class="material-icons"
                        style="font-size: 22px;color:orange;cursor:pointer;z-index: 100;"
                        on:click|stopPropagation|preventDefault={() => {
                          if (!driver_fav.some((obj) => obj[0] === item[0])) {
                            driver_fav.push([
                              item[0],
                              i,
                              $drivers[item[0]].name,
                            ]);
                            // order by name
                            driver_fav.sort((a, b) =>
                              a[2] > b[2] ? 1 : b[2] > a[2] ? -1 : 0
                            );
                            driver_fav = [...driver_fav];
                          }
                        }}>star_border</i
                      >
                      {typeof $drivers[item[0]].name != "undefined"
                        ? $drivers[item[0]].name
                        : item}
                    </div>
                    <div>
                      {#if icons_driver_error[item[0]] == "Error"}
                        <i
                          class="material-icons"
                          style="font-size: 22px;color:red;">error</i
                        >
                      {:else if icons_driver_error[item[0]] == "Warning"}
                        <i
                          class="material-icons"
                          style="font-size: 22px;color:orange;">warning</i
                        >
                      {/if}
                      {#if icons_driver[item[0]]}
                        <i
                          class="material-icons"
                          style="font-size: 22px;color:{icons_driver[item[0]] ==
                          'Descanso'
                            ? 'green'
                            : icons_driver[item[0]] == 'En Servicio'
                              ? 'orange'
                              : icons_driver[item[0]] == 'Conducción'
                                ? 'blue'
                                : 'gray'};"
                          >{icons_driver[item[0]] == "Descanso"
                            ? "hotel"
                            : icons_driver[item[0]] == "En Servicio"
                              ? "work"
                              : icons_driver[item[0]] == "Conducción"
                                ? "directions_car"
                                : "error"}</i
                        >
                      {/if}
                    </div>
                  </a>
                {/if}
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-5 h-100" style="display: flex;flex-direction: column;">
      <div
        style="display:flex;align-items: center;"
        class="card-header bg-dark text-white b-0"
      >
        <i
          tooltip="Operador"
          class="material-icons md-90"
          style="margin-right: 5px;"
        >
          access_time
        </i> Detalle HOS
      </div>
      <div
        class="charts-container"
        style="background: white;border-right: 0.1rem solid gray;"
      >
        <div class="chart-item">
          <canvas id="chartBreak"></canvas>
        </div>
        <div class="chart-item">
          <canvas id="chartDrive"></canvas>
        </div>
        <div class="chart-item">
          <canvas id="chartShift"></canvas>
        </div>
        <div class="chart-item">
          <canvas id="chartCycle"></canvas>
        </div>
      </div>
      <div
        style="display:flex;background: white;border-right: 0.1rem solid gray;"
      >
        <div class="chart-label-container">
          <div class="chart-label">Descanso</div>
        </div>
        <div class="chart-label-container">
          <div class="chart-label">Conducción</div>
        </div>
        <div class="chart-label-container">
          <div class="chart-label">Turno</div>
        </div>
        <div class="chart-label-container">
          <div class="chart-label">Ciclo</div>
        </div>
      </div>
      <div
        style="background: white;height: 20px;border-right: 0.1rem solid gray;"
      ></div>
      <div style="flex-grow:1;overflow:auto;height: 100%;">
        <div
          style="height: 100%;display: flex;flex-direction: column;border: 0.1rem solid gray;"
        >
          <div
            style="display:flex;align-items: center;padding:0;"
            class="card-header bg-dark text-white b-0"
          >
            <i
              tooltip="Operador"
              class="material-icons md-90"
              style="margin-right: 5px;margin-left: 5px;"
            >
              list
            </i> Lista de actividades
          </div>
          <!-- demo table HOS horus of service-->
          <div
            class="table-responsive"
            style="overflow:auto;flex-grow: 1;"
            id="actividad_scroll"
          >
            <table class="table table-sm table-striped table-hover">
              <thead
                style="position: sticky; top: 0; background: white; z-index: 1;"
              >
                <tr>
                  <th>Turno</th>
                  <th>Inicio </th>
                  <th>Final</th>
                  <th>Actividad</th>
                  <th style="text-align: end;">Tiempo</th>
                  <!--<th style="text-align: end;">Parte</th>-->
                  <th style="text-align: end;">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {#if table_hos.length > 0}
                  {#each table_hos as item, i}
                    <tr
                      style={item.part == "between_travels"
                        ? "background-color:rgb(0 191 245 / 32%);"
                        : i == table_hos.length - 1
                          ? "background-color:#79e9cf;"
                          : ""}
                    >
                      <th>
                        {#if item.turn_ini != undefined}
                          {item.turn_ini + 1}
                        {:else if item.turn_end != undefined}
                          {item.turn_end + 1}
                        {/if}
                        {#if item.turn_ini != undefined}
                          <i
                            class="material-icons"
                            style="font-size: 25px;color:#9f00ff;"
                            >play_circle_filled</i
                          >
                        {/if}
                        {#if item.turn_end != undefined}
                          <i
                            class="material-icons"
                            style="font-size: 25px;color:blue;">stop</i
                          >
                        {/if}
                        {#if item.turn_ini == undefined && item.turn_end == undefined}
                          {item.turn ? item.turn : ""}
                          <i
                            class="material-icons"
                            style="font-size: 25px;color:{item.turn != ''
                              ? '#60b75f'
                              : 'gray'};">flag</i
                          >
                        {/if}
                      </th>
                      <td
                        >{item.date_start
                          ? moment(new Date(item.date_start * 1000)).format(
                              "DD/MM/YY HH:mm"
                            )
                          : "-"}</td
                      >
                      <td>
                        {item.date_end
                          ? Math.abs(
                              Math.round(new Date().getTime() / 1000) -
                                item.date_end
                            ) <= 60
                            ? "En curso"
                            : moment(new Date(item.date_end * 1000)).format(
                                "DD/MM/YY HH:mm"
                              )
                          : ""}
                      </td>
                      <td
                        ><i
                          class="material-icons"
                          style="font-size: 28px;color: {item.type == 'Unknown'
                            ? 'gray'
                            : item.type == 'Driving'
                              ? '#007bff'
                              : item.type == 'OnDuty'
                                ? '#fd7e14'
                                : item.type == 'OffDuty'
                                  ? '#5cb85c'
                                  : item.type == 'SleeperBerth'
                                    ? 'golden'
                                    : 'gray'};"
                          >{item.type == "Unknown"
                            ? "help"
                            : item.type == "Driving"
                              ? "directions_car"
                              : item.type == "OnDuty"
                                ? "work"
                                : item.type == "OffDuty"
                                  ? "airline_seat_individual_suite"
                                  : item.type == "SleeperBerth"
                                    ? "bed"
                                    : "-"}</i
                        >
                        {item.type == "Unknown"
                          ? "Pendiente"
                          : item.type == "Driving"
                            ? "Conducción"
                            : item.type == "OnDuty"
                              ? "En Servicio"
                              : item.type == "OffDuty"
                                ? "Descanso"
                                : item.type == "SleeperBerth"
                                  ? "Descanso en litera"
                                  : "-"}</td
                      >
                      <td style="text-align: end;">{minToHm(item.time)}</td>
                      <!--<td style="text-align: end;"
                        >{item.part ? item.part : ""}</td
                      >-->
                      <td style="text-align: end;">
                        {#if item.source == "Hibrid" || item.type == "Unknown" || item.part == "manual"}
                          <button
                            class="btn btn-sm btn-primary"
                            on:click={() => {
                              original_hos = item;
                              lista_new_hos = [
                                {
                                  time: item.time,
                                  type: item.type,
                                },
                              ];
                              detalle_modal = true;
                            }}
                          >
                            <i class="material-icons" style="font-size: 18px;"
                              >edit</i
                            >
                            Editar
                          </button>
                        {/if}
                      </td>
                    </tr>
                  {/each}
                {/if}
              </tbody>
            </table>
          </div>
          {#if table_hos.length == 0}
            <div
              style="flex-grow: 10;display: flex;justify-content: center;align-items: center;"
            >
              <div class="alert alert-warning" role="alert">
                <i class="material-icons">warning</i>
                No hay datos últimos 7 dias
              </div>
            </div>
          {/if}
        </div>
      </div>
    </div>
    <div class="col-5 h-100" style="display: flex;flex-direction: column;">
      <div
        style="display:flex;align-items: center;justify-content: space-between;"
        class="card-header bg-dark text-white b-0"
      >
        <div>
          <i
            tooltip="Operador"
            class="material-icons md-90"
            style="margin-right: 5px;"
          >
            insert_chart
          </i>
          Analisis de HOS
        </div>
        <div>
          {#if analisis_status == "bitacora"}
            {#if table_hos_turns.length > 0}
              Turno
            {/if}
            <div class="btn-group mx-2" role="group">
              {#each table_hos_turns as item, i}
                <button
                  on:click|preventDefault={() => {
                    table_hos_turns_selected = i;
                    run_bitacora("hos_graph", item.graph, {
                      labels: generarLista(table_hos_turns.length),
                      datasets: table_hos_turns,
                    });
                  }}
                  type="button"
                  class="btn {table_hos_turns_selected == i
                    ? 'btn-info'
                    : 'btn-outline-info'}">{i + 1}</button
                >
              {/each}
            </div>
          {/if}
          <div class="btn-group mx-2" role="group">
            <button
              on:click|preventDefault={() => {
                analisis_status = "bitacora";
                if (table_hos_turns_selected >= 0) {
                  run_bitacora(
                    "hos_graph",
                    table_hos_turns[table_hos_turns_selected].graph,
                    {
                      labels: generarLista(table_hos_turns.length),
                      datasets: table_hos_turns,
                    }
                  );
                } else {
                  run_bitacora("hos_graph");
                }
              }}
              type="button"
              class="btn {analisis_status == 'bitacora'
                ? 'btn-info'
                : 'btn-outline-info'}">Bitacora</button
            >
            <button
              on:click|preventDefault={() => {
                analisis_status = "acumulado";
                if (table_hos_turns_selected >= 0) {
                  run_bitacora(
                    "hos_graph",
                    table_hos_turns[table_hos_turns_selected].graph,
                    {
                      labels: generarLista(table_hos_turns.length),
                      datasets: table_hos_turns,
                    }
                  );
                } else {
                  run_bitacora("hos_graph");
                }
              }}
              type="button"
              class="btn {analisis_status != 'bitacora'
                ? 'btn-info'
                : 'btn-outline-info'}">Acumulado</button
            >
          </div>
        </div>
      </div>
      <div style="flex-grow: 0;background-color: white;">
        <div style="margin:5px">
          <canvas id="hos_graph"></canvas>
        </div>
      </div>
      <div
        style="display:flex;align-items: center;justify-content: space-between;"
        class="card-header bg-dark text-white b-0"
      >
        <div>
          <i
            tooltip="Operador"
            class="material-icons md-90"
            style="margin-right: 5px;padding: 0px;"
          >
            insert_chart
          </i>
          {turn_travel_option == "turn" ? "Turnos" : "Viajes"} (últimos 7 días)
        </div>
        <div class="btn-group mx-2" role="group">
          <button
            on:click|preventDefault={() => {
              turn_travel_option = "turn";
            }}
            type="button"
            class="btn {turn_travel_option == 'turn'
              ? 'btn-info'
              : 'btn-outline-info'}">Turnos</button
          >
          <button
            on:click|preventDefault={() => {
              turn_travel_option = "travel";
            }}
            type="button"
            class="btn {turn_travel_option == 'travel'
              ? 'btn-info'
              : 'btn-outline-info'}">Viajes</button
          >
        </div>
      </div>
      <div style="flex-grow: 1;overflow:auto;">
        <table class="table table-sm table-striped table-hover">
          <thead
            style="position: sticky; top: 0; background: white; z-index: 1;"
          >
            <tr>
              <th>ID</th>
              <th>Inicio</th>
              <th style="text-align: end;">Duración</th>
              <th style="text-align: end;">Conducción</th>
              <th style="text-align: end;">Descanso</th>
              <th style="text-align: end;">En Servicio</th>
              <th style="text-align: center;">Eficiencia</th>
            </tr>
          </thead>
          <tbody>
            {#each turn_travel_option == "turn" ? table_hos_turns : table_hos_travels as item, i}
              <tr>
                <th>{i + 1}</th>
                <td
                  >{moment(new Date(item.date_start * 1000)).format(
                    "DD/MM/YY HH:mm"
                  )}</td
                >
                <td style="text-align: end;">{minToHm(item.time)}</td>
                <td
                  style="text-align: end; color: {Math.round(
                    (item.Driving / (drive_max * 60)) * 100
                  ) > 100
                    ? 'red'
                    : 'black'};"
                >
                  {#if item.Driving > drive_max * 60}
                    <i
                      class="material-icons"
                      style="font-size: 18px;color:red;"
                      tooltip="Conducción excedida">error</i
                    >
                  {/if}
                  {minToHm(item.Driving)}
                </td>
                <td style="text-align: end;">{minToHm(item.OffDuty)}</td>
                <td style="text-align: end;">{minToHm(item.OnDuty)}</td>
                <td
                  style="text-align: center;color: {Math.round(
                    (item.Driving / (drive_max * 60)) * 100
                  ) > 100
                    ? 'red'
                    : 'black'};"
                >
                  {#if item.Driving > drive_max * 60}
                    <i
                      class="material-icons"
                      style="font-size: 18px;color:red;"
                      tooltip="Conducción excedida">error</i
                    >
                  {:else if Math.round((item.Driving / (drive_max * 60)) * 100) > 80 && Math.round((item.Driving / (drive_max * 60)) * 100) <= 100}
                    <i
                      class="material-icons"
                      style="font-size: 18px;color:green;"
                      tooltip="Conducción deseada">check_circle</i
                    >
                  {:else}
                    <i
                      class="material-icons"
                      style="font-size: 18px;color:gray;"
                      tooltip="Conducción de bajo desemeño">remove_circle</i
                    >
                  {/if}
                  {Math.round((item.Driving / (drive_max * 60)) * 100)}%
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        {#if turn_travel_option == "turn" ? table_hos_turns.length == 0 : table_hos_travels.length == 0}
          <div
            style="flex-grow: 10;display: flex;justify-content: center;align-items: center;"
          >
            <div class="alert alert-warning" role="alert">
              <i class="material-icons">warning</i>
              No hay datos últimos 7 dias
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  .btn {
    font-size: 0.75rem;
    padding: 0.1rem, 0.1rem;
    line-height: 0.7;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .c100.big {
    font-size: 11vw;
  }
  .c100 {
    margin: 0;
  }
  .c100 > span {
    width: 2.5em;
    line-height: 2.5em;
    font-size: 0.4em;
  }
  .badge {
    font-size: 1.5rem;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  th.dato-general,
  td.dato-general {
    text-align: start;
    vertical-align: middle;
    font-size: 0.9rem;
    padding-left: 0.75rem;
  }
  /* HOS */
  .charts-container {
    height: 50%;
    display: flex;
    max-height: 200px;
  }
  .chart-item {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .chart-label {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 21px;
  }
  .chart-label-container {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  th,
  td {
    vertical-align: middle;
  }
  .table thead th {
    vertical-align: middle;
  }
</style>
