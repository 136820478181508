<script>
  export let routes_db, socket_query;
  import { onMount } from "svelte";
  import {
    route_list,
    geos,
    geos_class,
    route_list_nosig_d,
    map_ready,
    geos_map,
    poli,
  } from "./stores.js";

  let map;
  var directionsService;
  var directionsRenderer;
  let all_steps = [];
  let mount_complete = false;
  function initMap() {
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });

    directionsService = new google.maps.DirectionsService();
    directionsRenderer = new google.maps.DirectionsRenderer({
      draggable: true,
      map: map,
    });

    directionsRenderer.addListener("directions_changed", function () {
      directionsRenderer.setMap(map);
      console.log(
        directionsRenderer.getDirections().routes[0].legs[0].distance.text
      );
      console.log(
        directionsRenderer.getDirections().routes[0].legs[0].duration.text
      );
      let steps = directionsRenderer.getDirections().routes[0].legs[0].steps;
      all_steps = [];
      for (let z in steps) {
        for (let w in steps[z].lat_lngs) {
          all_steps.push([
            steps[z].lat_lngs[w].lat(),
            steps[z].lat_lngs[w].lng(),
          ]);
        }
      }
      if (all_steps.length > 0) {
        all_steps.unshift([coordenadas[0].lat, coordenadas[0].lng]);
        all_steps.push([
          coordenadas[coordenadas.length - 1].lat,
          coordenadas[coordenadas.length - 1].lng,
        ]);
      }
      //console.log(all_steps);
    });
  }
  onMount(() => {
    mount_complete = true;
  });
  $: {
    if ($map_ready == 1 && mount_complete) {
      let bounds = new google.maps.LatLngBounds();
      bounds.extend({ lat: 32.534353, lng: -117.123783 });
      bounds.extend({ lat: 21.137926, lng: -86.740844 });
      bounds.extend({ lat: 14.534659, lng: -92.231633 });
      initMap();
      map.fitBounds(bounds);
      console.log("mapa cargado");
    }
  }

  function displayRoute(origin, destination, service, display) {
    service.route(
      {
        origin: origin,
        destination: destination,
        waypoints: [],
        travelMode: "DRIVING",
        avoidTolls: false,
      },
      function (response, status) {
        if (status === "OK") {
          //console.log("Servicio ok ",response);
          display.setDirections(response);
        } else {
          alert("Could not display directions due to: " + status);
        }
      }
    );
  }

  let selected_r = -1;
  let coordenadas = [];
  let recorrido;
  let marks_timeout = [];
  let m_inicio = [];
  let stops = [];
  let with_nosig = false;
  let last_r_selected = "";
  async function route_map(i, r, event) {
    if (typeof event.target != "undefined") {
      if (event.target.id != "") {
        return 0;
      }
    }
    if (edited_ruta != i) {
      edited_ruta = -1;
      directionsRenderer.setMap(null);
      all_steps = [];
    }
    selected_r = i;
    selected_event = -1;
    let route;
    let bounds = new google.maps.LatLngBounds();
    try {
      route = await routes_db.getItem(r);
    } catch (e) {
      console.log("Error leyendo routa", e);
    }

    let lat_org = route["pos_start"][0];
    let lng_org = route["pos_start"][1];
    coordenadas = [];
    for (let x in route["pos"]) {
      let lat = (lat_org - route["pos"][x][0]) / 10000;
      let lng = (lng_org - route["pos"][x][1]) / 10000;
      coordenadas.push({ lat: lat, lng: lng });
      bounds.extend({ lat: lat, lng: lng });
    }
    if (typeof recorrido != "undefined") {
      recorrido.setMap(null);
    }
    recorrido = new google.maps.Polyline({
      path: coordenadas,
      geodesic: true,
      strokeColor: "#2196f3",
      strokeOpacity: 1.0,
      strokeWeight: 2.5,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            strokeColor: "#2196f3",
          },
          offset: "100px",
          repeat: "100px",
        },
      ],
    });
    recorrido.setMap(map);
    map.fitBounds(bounds);

    for (let w in marks_timeout) {
      clearTimeout(marks_timeout[w]);
    }
    marks_timeout = [];
    for (let w in m_inicio) {
      if (typeof m_inicio[w] != "undefined") {
        m_inicio[w].setMap(null);
      }
    }
    m_inicio = [];

    let time_mark = 500;
    marks_timeout.push(
      setTimeout(
        function () {
          m_inicio[0] = new google.maps.Marker({
            position: coordenadas[0],
            animation: google.maps.Animation.DROP,
            map: map,
            icon: "img/dd-start.png",
            zIndex: 2,
          });
        },
        time_mark,
        coordenadas
      )
    );
    time_mark = time_mark + 150;
    marks_timeout.push(
      setTimeout(
        function () {
          m_inicio.push(
            new google.maps.Marker({
              position: coordenadas[coordenadas.length - 1],
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/dd-end.png",
              zIndex: 2,
            })
          );
        },
        time_mark,
        coordenadas
      )
    );
    time_mark = time_mark + 150;

    stops = [];
    for (let x in route["stops"]) {
      let lat_s = route["stops"][x][0];
      let lng_s = route["stops"][x][1];
      stops.push({ lat: lat_s, lng: lng_s, geo: geo_search(lat_s, lng_s) });

      marks_timeout.push(
        setTimeout(
          function () {
            m_inicio.push(
              new google.maps.Marker({
                position: { lat: lat_s, lng: lng_s },
                animation: google.maps.Animation.DROP,
                map: map,
                icon: "img/dd-end.png",
                zIndex: 1,
              })
            );
          },
          time_mark,
          lat_s,
          lng_s
        )
      );
      time_mark = time_mark + 150;
    }

    // Opciones
    last_r_selected = r;
    if (typeof $route_list_nosig_d[r] != "undefined") {
      with_nosig = true;
      time_nosig = $route_list_nosig_d[r];
    } else {
      with_nosig = false;
      time_nosig = 20;
    }
  }
  let selected_event = -1;
  function event_select(e) {
    if (e.target.id.split("_")[0] == "stop") {
      selected_event = e.target.id.split("_")[1];
      map.panTo({
        lat: stops[selected_event].lat,
        lng: stops[selected_event].lng,
      });
      map.setZoom(16);
    }
  }
  function geo_search(lat, lng) {
    let box_size = 0.0001797 * 10; // 200 metros por celula
    let p = Math.floor((lat - 14.5408) / box_size);
    let q = Math.floor((lng + 118.2689) / box_size);
    let key_close_list = [
      p + "_" + q,
      p + "_" + (q + 1),
      p + "_" + (q - 1),
      p + 1 + "_" + q,
      p - 1 + "_" + q,
      p + 1 + "_" + (q + 1),
      p - 1 + "_" + (q - 1),
      p + 1 + "_" + (q - 1),
      p - 1 + "_" + (q + 1),
    ];
    let geos_arround = [];
    for (let w in key_close_list) {
      if ($geos_map[key_close_list[w]]) {
        for (let s in $geos_map[key_close_list[w]]) {
          if (!geos_arround.includes($geos_map[key_close_list[w]][s])) {
            geos_arround.push($geos_map[key_close_list[w]][s]);
          }
        }
      }
    }
    let found = false;
    for (let x in $poli) {
      if (geos_arround.includes(x)) {
        if (dentro([lat, lng], $poli[x][0])) {
          return [$poli[x][1], 2, x];
        }
      }
    }
    for (let x in $geos) {
      // filtro
      if (geos_arround.includes($geos[x][0])) {
        for (let y in $geos[x][1]) {
          let lat_ = $geos[x][1][y][0];
          let lng_ = $geos[x][1][y][1];
          if (get_km(lat, lng, lat_, lng_) <= 0.13) {
            if (typeof $geos_class[$geos[x][0]] == "undefined") {
              return [
                "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                1,
                $geos[x][0],
              ];
            } else {
              return [
                $geos_class[$geos[x][0]][0],
                $geos_class[$geos[x][0]][1],
                $geos[x][0],
              ];
            }
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    return ["Geocerca_automatica_", 1, ""];
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  function dentro(point, vs) {
    let x = point[0],
      y = point[1];
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0],
        yi = vs[i][1];
      let xj = vs[j][0],
        yj = vs[j][1];
      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }
  let selected_org = -1;
  let selected_dest = -1;
  let origenes = [{ id: 0 }];
  let destinos = [];
  function origen_map(i) {
    if (selected_org != i) {
      selected_org = i;
      selected_dest = -1;
    } else {
      selected_org = -1;
    }
  }
  function destino_map(i) {
    if (selected_dest != i) {
      selected_dest = i;
      selected_org = -1;
    } else {
      selected_dest = -1;
    }
  }
  let route_list_ = [];
  $: {
    let origenes_p = [];
    let destinos_p = [];
    let route_list_p = [];
    $geos_class;
    $geos;
    for (let x in $route_list) {
      route_list_p.push($route_list[x]);

      let geo = geo_search(
        $route_list[x][2][0] / 10000,
        $route_list[x][2][1] / 10000
      );
      let found = false;
      for (let w in origenes_p) {
        if (origenes_p[w].id == geo[2]) {
          origenes_p[w] = { name: geo[0], type: geo[1], id: geo[2] };
          found = true;
          break;
        }
      }
      if (!found && geo[2] != "" && geo[1] != 1) {
        origenes_p.push({ name: geo[0], type: geo[1], id: geo[2] });
      }
      route_list_p[x][2][2] = geo[2];

      geo = geo_search(
        $route_list[x][3][0] / 10000,
        $route_list[x][3][1] / 10000
      );
      found = false;
      for (let w in destinos_p) {
        if (destinos_p[w].id == geo[2]) {
          destinos_p[w] = { name: geo[0], type: geo[1], id: geo[2] };
          found = true;
          break;
        }
      }
      if (!found && geo[2] != "" && geo[1] != 1) {
        destinos_p.push({ name: geo[0], type: geo[1], id: geo[2] });
      }
      route_list_p[x][3][2] = geo[2];
    }
    origenes = [...origenes_p];
    destinos = [...destinos_p];
    route_list_ = [...route_list_p];
  }
  let edited_ruta = -1;
  let route_name;
  let route_tune = 0;
  let saved_ok = false;
  let saved_type, saved_text;
  function ruta_edit_(i) {
    route_name = route_list_[i][1];
    edited_ruta = i;
    if (recorrido.getPath().getArray().length < 700) {
      recorrido.setEditable(true);
    }
  }
  function ruta_edit(i) {
    route_name = route_list_[i][1];
    route_tune = route_list_[i][4];
    edited_ruta = i;
  }
  function edicion_api() {
    displayRoute(
      coordenadas[0],
      coordenadas[coordenadas.length - 1],
      directionsService,
      directionsRenderer
    );
  }
  function ruta_save(i, r) {
    let new_route = [];
    let out_vector = [];
    let tune = 0;

    if (all_steps.length > 0) {
      tune = 1;
      let lat_start = Math.round(all_steps[0][0] * 10000);
      let lng_start = Math.round(all_steps[0][1] * 10000);
      out_vector.push({ pos_start: [lat_start, lng_start], pos: [[0, 0]] });
      for (let x in all_steps) {
        let lat_ = Math.round(all_steps[x][0] * 10000);
        let lng_ = Math.round(all_steps[x][1] * 10000);
        if (
          lat_start != lat_ &&
          lng_start != lng_ &&
          (out_vector[0].pos[out_vector[0].pos.length - 1][0] !=
            lat_start - lat_ ||
            out_vector[0].pos[out_vector[0].pos.length - 1][1] !=
              lng_start - lng_)
        ) {
          out_vector[0].pos.push([lat_start - lat_, lng_start - lng_]);
        }
      }
      console.log(out_vector[0]);
    } else {
      tune = route_tune;
      if (typeof recorrido != "undefined") {
        let points = recorrido.getPath().getArray();
        for (let x in points) {
          new_route.push([points[x].lat(), points[x].lng()]);
        }
        console.log(new_route);
        let lat_start = Math.round(new_route[0][0] * 10000);
        let lng_start = Math.round(new_route[0][1] * 10000);
        out_vector.push({ pos_start: [lat_start, lng_start], pos: [[0, 0]] });
        for (let x in new_route) {
          let lat_ = Math.round(new_route[x][0] * 10000);
          let lng_ = Math.round(new_route[x][1] * 10000);
          if (
            lat_start != lat_ &&
            lng_start != lng_ &&
            (out_vector[0].pos[out_vector[0].pos.length - 1][0] !=
              lat_start - lat_ ||
              out_vector[0].pos[out_vector[0].pos.length - 1][1] !=
                lng_start - lng_)
          ) {
            out_vector[0].pos.push([lat_start - lat_, lng_start - lng_]);
          }
        }
        console.log(out_vector);
      }
    }
    all_steps = [];
    directionsRenderer.setMap(null);
    edited_ruta = -1;

    socket_query.emit(
      "route_edit",
      r,
      route_name,
      out_vector[0].pos_start,
      out_vector[0].pos,
      tune,
      (data) => {
        if (data == "ok") {
          saved_type = "alert-success";
          saved_text = "Ruta Guardada";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      }
    );

    /*
        if(typeof recorrido != 'undefined'){
            let points = recorrido.getPath().getArray();
            for(let x in points)
            {
                new_route.push([points[x].lat(),points[x].lng()]);
            }
            console.log(new_route);
            let lat_start = Math.round(new_route[0][0]*10000);
            let lng_start = Math.round(new_route[0][1]*10000);
            out_vector.push({"pos_start":[lat_start,lng_start],"pos":[[0,0]]});
            for(let x in new_route)
            {
                let lat_ = Math.round(new_route[x][0]*10000);
                let lng_ = Math.round(new_route[x][1]*10000);
                if(lat_start!=lat_&&lng_start!=lng_&&(out_vector[0].pos[out_vector[0].pos.length-1][0]!=lat_start-lat_||out_vector[0].pos[out_vector[0].pos.length-1][1]!=lng_start-lng_))
                {
                    out_vector[0].pos.push([lat_start-lat_,lng_start-lng_]);
                }
            }
            console.log(out_vector);
        }

        recorrido.setEditable(false);

        edited_ruta = -1;
        socket_query.emit('route_edit',r,route_name,out_vector[0].pos_start,out_vector[0].pos,(data)=> {
            if(data=="ok"){
                saved_type = "alert-success"; saved_text="Ruta Guardada";
                saved_ok = true;
                setTimeout(function(){saved_ok=false;},3000);
                
            }
        });
        */
  }
  function ruta_delete(i, r) {
    edited_ruta = -1;
    console.log(r);
    socket_query.emit("route_delete", r, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Ruta Borrada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }
  let file_name;
  function carga_kml() {
    for (let x of file_name.files) {
      let reader = new FileReader();
      reader.addEventListener("load", (event) => {
        console.log(event.target.fileName);
        let new_route = [];
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(event.target.result, "text/xml");
        let res_cod = xmlDoc
          .getElementsByTagName("LineString")[0]
          .getElementsByTagName("coordinates")[0]
          .childNodes[0].nodeValue.trim()
          .split("\n");
        for (let y of res_cod) {
          let cord = y.trim().split(",");
          new_route.push([cord[1], cord[0]]);
        }
        console.log(new_route);
        let lat_start = Math.round(new_route[0][0] * 10000);
        let lng_start = Math.round(new_route[0][1] * 10000);
        let out_vector = [];
        out_vector.push({
          pos_start: [lat_start, lng_start],
          pos: [[0, 0]],
          stops: [],
        });
        for (let x in new_route) {
          let lat_ = Math.round(new_route[x][0] * 10000);
          let lng_ = Math.round(new_route[x][1] * 10000);
          if (
            lat_start != lat_ &&
            lng_start != lng_ &&
            (out_vector[0].pos[out_vector[0].pos.length - 1][0] !=
              lat_start - lat_ ||
              out_vector[0].pos[out_vector[0].pos.length - 1][1] !=
                lng_start - lng_)
          ) {
            out_vector[0].pos.push([lat_start - lat_, lng_start - lng_]);
          }
        }
        console.log(out_vector);
        socket_query.emit(
          "route_save",
          event.target.fileName.split(".kml")[0],
          out_vector[0],
          1,
          (data) => {
            if (data == "ok") {
              saved_type = "alert-success";
              saved_text = "Ruta Guardada";
              saved_ok = true;
              setTimeout(function () {
                saved_ok = false;
              }, 3000);
            }
          }
        );
      });
      reader.fileName = x.name;
      reader.readAsText(x);
    }
    file_name.value = "";
  }

  let origen_filter_mode = false;
  let filter_origen;
  function origen_filter() {
    origen_filter_mode = origen_filter_mode ? false : true;
    if (origen_filter_mode) filter_origen = "";
  }
  function focus(i) {
    i.focus();
  }
  function run_origen_filter(event) {
    for (let x in origenes) {
      if (origenes[x].name == event.target.value) {
        origen_map(x);
        origen_filter_mode = false;
        filter_origen = "";
        let origen_l = document.getElementById("origen_l_" + x);
        if (origen_l != null) {
          origen_l.scrollIntoView();
        }
      }
    }
  }

  let destino_filter_mode = false;
  let filter_destino;
  function destino_filter() {
    destino_filter_mode = destino_filter_mode ? false : true;
    if (destino_filter_mode) filter_destino = "";
  }
  function run_destino_filter(event) {
    for (let x in destinos) {
      if (destinos[x].name == event.target.value) {
        destino_map(x);
        destino_filter_mode = false;
        filter_destino = "";
        let destino_l = document.getElementById("destino_l_" + x);
        if (destino_l != null) {
          destino_l.scrollIntoView();
        }
      }
    }
  }
  let time_nosig = 20;
  function change_nosig() {
    socket_query.emit(
      "route_nosig_up",
      last_r_selected,
      with_nosig,
      time_nosig,
      (data) => {
        if (data == "ok") {
          saved_type = "alert-success";
          saved_text = "Ruta Guardada";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      }
    );
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-md-auto h-100">
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100" style="min-width:13rem">
          <div class="card h-100 bg-light card-b">
            <div class="d-flex flex-column h-100">
              <div class="card-header bg-dark text-white b-0">
                <div class="d-flex justify-content-between">
                  <div>
                    {#if !origen_filter_mode}
                      <i class="material-icons md-85">play_circle_filled</i> Origen
                    {:else}
                      <input
                        list="lista_origenes"
                        placeholder="Origen"
                        use:focus
                        bind:value={filter_origen}
                        on:change={run_origen_filter}
                      />
                      <datalist id="lista_origenes">
                        {#each origenes as item, i}
                          <option value={item.name} />{/each}
                      </datalist>
                    {/if}
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-link p-0"
                      on:click={origen_filter}
                    >
                      <i class="material-icons md-85" style="color:#fafafa"
                        >{origen_filter_mode ? "cancel" : "search"}</i
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body flex-grow-1 overflow-auto">
                <div class="list-group">
                  {#each origenes as item, i}
                    <a
                      id="origen_l_{i}"
                      href="/"
                      class:active_={selected_org == i}
                      on:click|preventDefault={() => {
                        origen_map(i);
                      }}
                      class:list-group-item-warning={selected_org == i}
                      class="g_list list-group-item list-group-item-action"
                      >{item.name}</a
                    >
                  {/each}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100">
          <div class="card h-100 bg-light card-b">
            <div class="d-flex flex-column h-100">
              <div class="card-header bg-dark text-white b-0">
                <div class="d-flex justify-content-between">
                  <div>
                    {#if !destino_filter_mode}
                      <i class="material-icons md-85">stop</i> Destino
                    {:else}
                      <input
                        list="lista_destino"
                        placeholder="Destino"
                        use:focus
                        bind:value={filter_destino}
                        on:change={run_destino_filter}
                      />
                      <datalist id="lista_destino">
                        {#each destinos as item, i}
                          <option value={item.name} />{/each}
                      </datalist>
                    {/if}
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-link p-0"
                      on:click={destino_filter}
                    >
                      <i class="material-icons md-85" style="color:#fafafa"
                        >{destino_filter_mode ? "cancel" : "search"}</i
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body flex-grow-1 overflow-auto">
                <div class="list-group">
                  {#each destinos as item, i}
                    <a
                      id="destino_l_{i}"
                      href="/"
                      class:active_={selected_dest == i}
                      on:click|preventDefault={() => {
                        destino_map(i);
                      }}
                      class:list-group-item-warning={selected_dest == i}
                      class="g_list list-group-item list-group-item-action"
                      >{item.name}</a
                    >
                  {/each}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md h-100">
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100">
          <div class="h-100" id="map" />
        </div>
      </div>
      <div class="row h-50 no-gutters">
        <div class="col-md-8 h-100">
          <div class="card h-100 bg-light">
            <div class="d-flex flex-column h-100">
              <div class="card-header b-0">
                <div class="d-flex justify-content-between">
                  <div>
                    <i class="material-icons md-85">near_me</i> Rutas
                    <span class="badge badge-pill badge-success"
                      >{$route_list.length}</span
                    >
                  </div>
                  <div>
                    {#if edited_ruta == -1}
                      <input
                        type="file"
                        id="files"
                        accept=".kml"
                        bind:this={file_name}
                        multiple
                      />
                      <button
                        on:click={carga_kml}
                        id="update_route"
                        type="button"
                        class="btn btn-link">Cargar KML</button
                      >
                    {:else}
                      <button
                        on:click={edicion_api}
                        id="update_route"
                        type="button"
                        class="btn btn-link">Cargar ruta automática</button
                      >
                    {/if}
                  </div>
                </div>
              </div>
              <div class="card-body flex-grow-1 overflow-auto">
                {#each route_list_ as item, i}
                  {#if (selected_org == -1 && selected_dest == -1) || (selected_org != -1 && selected_dest == -1 && origenes[selected_org].id == item[2][2]) || (selected_org == -1 && selected_dest != -1 && destinos[selected_dest].id == item[3][2])}
                    <div
                      class="card m-3 {selected_r == i ? 'br-green' : ''}"
                      style="font-size:0.8rem"
                    >
                      <div
                        class="d-flex justify-content-between"
                        on:click={(e) => {
                          route_map(i, item[0], e);
                        }}
                      >
                        <p
                          class="card-header b-0"
                          style="background-color:rgba(0,0,0,0)"
                        >
                          {#if edited_ruta != i}
                            <i
                              tooltip={item[4] == 1
                                ? "Alta definición"
                                : "Baja definición"}
                              class="material-icons {item[4] == 1
                                ? 'bblue'
                                : 'red'}"
                              >{item[4] == 1
                                ? "trending_up"
                                : "report_problem"}</i
                            >
                            {item[1]}
                          {:else}
                            <input
                              type="text"
                              id="ruta_name"
                              placeholder=""
                              bind:value={route_name}
                              size="45"
                            />
                          {/if}
                        </p>
                        {#if edited_ruta != i && selected_r == i}
                          <button
                            on:click={() => {
                              ruta_edit(i);
                            }}
                            id="cancel_change"
                            type="button"
                            class="btn btn-link">Editar</button
                          >
                        {:else if edited_ruta == i}
                          <div class="d-flex">
                            <button
                              on:click={() => {
                                ruta_delete(i, item[0]);
                              }}
                              id="cancel_change"
                              type="button"
                              class="btn btn-link"
                              style="color:red"
                              onMouseOver="this.style.color='red'"
                              >Borrar</button
                            >
                            <button
                              on:click={() => {
                                ruta_save(i, item[0]);
                              }}
                              id="cancel_change"
                              type="button"
                              class="btn btn-link">Guardar</button
                            >
                            <button
                              on:click={() => {
                                all_steps = [];
                                directionsRenderer.setMap(null);
                                edited_ruta = -1;
                                recorrido.setEditable(false);
                              }}
                              id="cancel_change"
                              type="button"
                              class="btn btn-link">Cancelar</button
                            >
                          </div>
                        {/if}
                      </div>
                    </div>
                  {/if}
                {/each}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 h-100">
          <div class="card h-100 bg-light">
            <div class="d-flex flex-column h-100">
              <div class="card-header b-0">
                <!--
                                <i class="material-icons md-85">pause_circle_filled</i> Paradas permitidas <span class="badge badge-pill badge-info">{stops.length}</span>
                                -->
                <i class="material-icons md-85">visibility</i> Opciones adicionales
              </div>
              <!--
                            <div class="card-body  flex-grow-1 overflow-auto">
                                <table class="table table-sm table-striped" style="font-size:0.75rem">
                                    <tbody on:click={event_select}>
                                        {#each stops as item,i}
                                            <tr>
                                                <td id="stop_{i}" class:table-info={selected_event==i}>
                                                    <i class="material-icons gray">stop</i> <strong><mark>{item.geo[0]}.</mark></strong> 
                                                    <button type="button" class="btn btn-link"><i class="material-icons md-85 red">cancel</i></button>
                                                </td>
                                            </tr>
                                        {/each}
                                        {#if selected_r!=-1}
                                            <tr>
                                                <td>
                                                    <i class="material-icons gray">stop</i>
                                                    <select id="geo_list">
                                                        <option value="">Lista de paradas</option>
                                                    </select>
                                                    <button type="button" class="btn btn-link"><i class="material-icons md-85 green">add_circle</i></button>
                                                </td>
                                            </tr>
                                        {/if}
                                    </tbody>
                                </table> 
                            </div>
                            -->
              {#if selected_r != -1}
                <div class="card-body flex-grow-1 overflow-auto">
                  <table
                    class="table table-sm table-striped"
                    style="font-size:0.8rem"
                  >
                    <tbody>
                      <tr>
                        <td class="table-info">
                          <input
                            type="checkbox"
                            bind:checked={with_nosig}
                            on:change={change_nosig}
                          /> <span class="ml-3 align-top">Ruta sin señal</span>
                        </td>
                        {#if with_nosig}
                          <td class="table-info">
                            <span>Tiempo</span>
                            <input
                              style="width:60px;"
                              on:change={change_nosig}
                              bind:value={time_nosig}
                              type="number"
                            /> Min
                          </td>
                        {/if}
                      </tr>
                    </tbody>
                  </table>
                </div>
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .btn {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .card-b {
    border: 0px;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .br-green {
    background-color: #ffecb3;
  }
  .card-header:first-child {
    border-radius: 0;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
