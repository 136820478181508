<script>
  export let units_travel_db, socket_query;
  import { onMount } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    geos_joined,
    geos_full,
    map_ready,
  } from "./stores.js";

  let key,
    nicks_,
    nicks = {};
  let parametros = new URLSearchParams(window.location.search);
  key = parametros.get("key");
  nicks_ = parametros.get("nicks");

  if (key != null) {
    //embed_dtc_worker.postMessage({type:"save",key:"embedded_key",message:key});
    /*
        socket_query.emit('embedded_key',key,(data)=> {
            if(data=="error"){
                alert("Login incorrecto");
            }
        });
        */
  }

  let units_list_p;
  $: {
    units_list_p = $units_list;
    $nicks_list;
    units_list_p.sort(units_order);
  }

  var chart1 = {
    type: "line",
    data: {
      labels: [],
      datasets: [
        {
          label: "km/h",
          data: [],
          backgroundColor: "rgba(255, 159, 64, 0.2)",
          borderColor: "rgb(255, 159, 64)",
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        point: { radius: 0 },
        line: { tension: 0.2 },
      },
    },
  };

  var map, chart_1;
  onMount(() => {
    chart_1 = new Chart(
      document.getElementById("chart1").getContext("2d"),
      chart1
    );

    //initMap();
  });

  function initMap() {
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
  }

  $: {
    if ($map_ready == 1) {
      initMap();
      console.log("mapa cargado");
    }
  }

  let list_recorridos;
  let selected = -1;
  let selected_trip = 0;
  let recorridos = [];
  let coordenada = [];
  let recorrido;
  let m_inicio, m_fin;
  let stops = [];
  let info_stops = [];
  let stops_table = [];
  let total_dist = 0;
  let vel_prom = 0,
    vel_prom_c = 0,
    v_max = 0;
  let t_stop = 0;
  let t_duracion = 0;
  let menu = 0;
  async function maping(event) {
    if (event.path == undefined) event.path = event.composedPath();
    let unit_s = event.toElement.pathname.split("/")[1];
    selected = event.target.id;
    selected_trip = 0;
    let rec_p = JSON.parse(await units_travel_db.getItem(unit_s));
    //let rec_p = (await units_travel_db.getItem(unit_s));
    for (var x in rec_p) {
      if (typeof rec_p[x]["pos"] != "undefined") {
        let fecha_org = new Date(rec_p[x]["date_start"] * 1000);
        let duracion = rec_p[x]["pos"][rec_p[x]["pos"].length - 1][3];
        rec_p[x]["trip_data"] = moment(fecha_org).format("DD/MM/YY HH:mm");
        rec_p[x]["trip_data2"] = time_code(duracion);
      }
    }
    if (rec_p != null) {
      recorridos = rec_p.sort(trips_order);
    } else {
      recorridos = [];
    }
    let e = { target: { id: 0 } };
    if (recorridos.length > 0) {
      trip(e);
    }
    list_recorridos.scrollTop = 0;
  }
  async function trip(event) {
    let zIndex = 0;
    selected_trip = event.target.id;
    coordenada = [];

    let fecha_org = recorridos[selected_trip]["date_start"];

    let bounds = new google.maps.LatLngBounds();
    chart_1.data.datasets[0].data = [];
    chart_1.data.labels = [];

    for (var x in stops) {
      stops[x].setMap(null);
    }
    for (var x in info_stops) {
      info_stops[x].setMap(null);
    }
    stops = [];
    info_stops = [];
    stops_table = [];
    let data_c = 0;
    total_dist = 0;
    vel_prom = 0;
    vel_prom_c = 1;
    let last_lat = recorridos[selected_trip]["pos_start"][0] / 10000;
    let last_lng = recorridos[selected_trip]["pos_start"][1] / 10000;
    t_stop = 0;
    t_duracion =
      recorridos[selected_trip]["pos"][
        recorridos[selected_trip]["pos"].length - 1
      ][3];
    for (var x in recorridos[selected_trip]["pos"]) {
      let lat =
        (recorridos[selected_trip]["pos_start"][0] -
          recorridos[selected_trip]["pos"][x][0]) /
        10000;
      let lng =
        (recorridos[selected_trip]["pos_start"][1] -
          recorridos[selected_trip]["pos"][x][1]) /
        10000;
      coordenada.push({ lat: lat, lng: lng });
      bounds.extend({ lat: lat, lng: lng });

      if (typeof recorridos[selected_trip]["pos"][x][4] != "undefined") {
        if (
          recorridos[selected_trip]["pos"][x][4] -
            recorridos[selected_trip]["pos"][x][3] >
          4
        ) {
          t_stop =
            t_stop +
            (recorridos[selected_trip]["pos"][x][4] -
              recorridos[selected_trip]["pos"][x][3]);
        }
      }

      total_dist = total_dist + get_km(last_lat, last_lng, lat, lng);
      last_lat = lat;
      last_lng = lng;
      if (recorridos[selected_trip]["pos"][x][2] > 15) {
        vel_prom_c++;
        vel_prom = vel_prom + recorridos[selected_trip]["pos"][x][2];
      }

      chart_1.data.labels.push(recorridos[selected_trip]["pos"][x][3]);
      chart_1.data.datasets[0].data[data_c] =
        recorridos[selected_trip]["pos"][x][2];
      data_c++;

      if (typeof recorridos[selected_trip]["pos"][x][4] != "undefined") {
        for (
          var y = recorridos[selected_trip]["pos"][x][3] + 1;
          y < recorridos[selected_trip]["pos"][x][4];
          y++
        ) {
          chart_1.data.labels.push(String(y));
          chart_1.data.datasets[0].data[data_c] = 0;
          data_c++;
        }
        if (
          recorridos[selected_trip]["pos"][x][4] -
            recorridos[selected_trip]["pos"][x][3] >
          4
        ) {
          let m_pause = new google.maps.Marker({
            position: { lat: lat, lng: lng },
            map: map,
            animation: google.maps.Animation.DROP,
            icon: "img/dd-pause.png",
            zIndex: zIndex,
          });
          zIndex++;
          stops.push(m_pause);

          let time_stop =
            recorridos[selected_trip]["pos"][x][4] -
            recorridos[selected_trip]["pos"][x][3];
          let contentString = time_code(time_stop);
          let infowindow = new google.maps.InfoWindow({
            content: contentString,
          });
          m_pause.addListener("click", function () {
            infowindow.open(map, m_pause);
          });
          m_pause.addListener("dblclick", function () {
            map.setZoom(18);
            map.panTo({ lat: lat, lng: lng });
          });
          info_stops.push(infowindow);
          let hora_inicial = new Date(
            (recorridos[selected_trip]["date_start"] +
              recorridos[selected_trip]["pos"][x][3] * 60) *
              1000
          );
          let hora_final = new Date(
            (recorridos[selected_trip]["date_start"] +
              recorridos[selected_trip]["pos"][x][4] * 60) *
              1000
          );
          stops_table.push({
            tiempo: contentString,
            pos: lat + "," + lng,
            date: moment(hora_inicial).format("DD/MM/YY HH:mm"),
            date2: moment(hora_final).format("DD/MM/YY HH:mm"),
          });
        }
      }
    }
    vel_prom = vel_prom / vel_prom_c;

    if (typeof recorrido != "undefined") {
      recorrido.setMap(null);
    }
    recorrido = new google.maps.Polyline({
      path: coordenada,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2.5,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            strokeColor: "#b71c1c",
          },
          offset: "100px",
          repeat: "100px",
        },
      ],
    });

    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    if (typeof m_fin != "undefined") {
      m_fin.setMap(null);
    }
    m_inicio = new google.maps.Marker({
      position: {
        lat: recorridos[selected_trip]["pos_start"][0] / 10000,
        lng: recorridos[selected_trip]["pos_start"][1] / 10000,
      },
      map: map,
      animation: google.maps.Animation.DROP,
      icon: "img/dd-start.png",
      zIndex: zIndex,
    });
    zIndex++;
    let lat_f =
      (recorridos[selected_trip]["pos_start"][0] -
        recorridos[selected_trip]["pos"][
          recorridos[selected_trip]["pos"].length - 1
        ][0]) /
      10000;
    let lng_f =
      (recorridos[selected_trip]["pos_start"][1] -
        recorridos[selected_trip]["pos"][
          recorridos[selected_trip]["pos"].length - 1
        ][1]) /
      10000;
    m_fin = new google.maps.Marker({
      position: { lat: lat_f, lng: lng_f },
      map: map,
      animation: google.maps.Animation.DROP,
      icon: "img/dd-end.png",
      zIndex: zIndex,
    });
    zIndex++;
    recorrido.setMap(map);
    map.fitBounds(bounds);
    chart_1.update();

    //Geos
    geo_name = "";
    geo_name_ = "";
    geo_c_sel = 1;
    geo_c_sel_ = 1;

    let res = geo_search(
      recorridos[selected_trip]["pos_start"][0] / 10000,
      recorridos[selected_trip]["pos_start"][1] / 10000
    );
    inicio_bounds = {
      lat: recorridos[selected_trip]["pos_start"][0] / 10000,
      lng: recorridos[selected_trip]["pos_start"][1] / 10000,
    };
    geo_name = res[0];
    geo_c_sel = res[1];
    geo_key = res[2];
    geo_c_sel_org = geo_c_sel;
    geo_name_org = geo_name;

    let res_ = geo_search(lat_f, lng_f);
    fin_bounds = { lat: lat_f, lng: lng_f };
    geo_name_ = res_[0];
    geo_c_sel_ = res_[1];
    geo_key_ = res_[2];
    geo_c_sel__org = geo_c_sel_;
    geo_name__org = geo_name_;

    var text_to = new SpeechSynthesisUtterance(
      "Unidad " +
        $nicks_list[units_list_p[selected]] +
        ", viaje inició el " +
        moment(new Date(fecha_org * 1000)).format("DD/MM/YY HH:mm") +
        ", velocidad promedio " +
        Math.round(vel_prom) +
        " kilómetros por hora, distancia recorrida " +
        Math.round(total_dist) +
        " kilómetros, realizó " +
        stops.length +
        " paradas durante el viaje"
    );
    //window.speechSynthesis.cancel();window.speechSynthesis.speak(text_to);

    osrm(coordenada);
  }

  function geo_search(lat, lng) {
    let found = false;
    for (let x in $geos) {
      for (let y in $geos[x][1]) {
        let lat_ = $geos[x][1][y][0];
        let lng_ = $geos[x][1][y][1];
        if (get_km(lat, lng, lat_, lng_) <= 0.13) {
          if (typeof $geos_class[$geos[x][0]] != "undefined") {
            return [
              $geos_class[$geos[x][0]][0],
              $geos_class[$geos[x][0]][1],
              $geos[x][0],
            ];
          } else {
            if (typeof $geos_joined[$geos[x][0]] == "undefined") {
              return [
                "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                1,
                $geos[x][0],
              ];
            } else {
              for (let h in $geos_joined[$geos[x][0]]) {
                if (
                  typeof $geos_class[$geos_joined[$geos[x][0]][h]] !=
                  "undefined"
                ) {
                  return [
                    $geos_class[$geos_joined[$geos[x][0]][h]][0],
                    $geos_class[$geos_joined[$geos[x][0]][h]][1],
                    $geos_joined[$geos[x][0]][h],
                  ];
                }
              }
              return [
                "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                1,
                $geos[x][0],
              ];
            }
          }
          found = true;
          break;
        }
      }
      if (found) {
        break;
      }
    }
    return ["", 1, ""];
  }

  function trips_order(a, b) {
    return b.date_start - a.date_start;
  }
  function units_order(a, b) {
    if ($nicks_list[b] < $nicks_list[a]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  let geo_name;
  let geo_c_sel = 1;
  let geo_name_;
  let geo_c_sel_ = 1;
  let geo_name_org, geo_name__org, geo_c_sel_org, geo_c_sel__org;
  let geo_key, geo_key_;
  let saved_ok = false,
    saved_type,
    saved_text;
  async function save_geo(event) {
    let geo_keyQ, geo_nameQ, geo_c_selQ, geo_dataQ;
    if (event.target.id == "inicio") {
      geo_keyQ = geo_key;
      geo_nameQ = geo_name;
      geo_c_selQ = geo_c_sel;
    } else {
      geo_keyQ = geo_key_;
      geo_nameQ = geo_name_;
      geo_c_selQ = geo_c_sel_;
    }
    //geo_dataQ = JSON.parse(await geos_db.getItem(geo_keyQ));
    geo_dataQ = $geos_full[geo_keyQ];

    socket_query.emit(
      "geo_save",
      geo_keyQ,
      geo_nameQ,
      geo_c_selQ,
      geo_dataQ,
      (data) => {
        if (data == "ok") {
          if (event.target.id == "inicio") {
            save_btn = "disabled";
            save_btn_c = "btn-secondary";
          } else {
            save_btn_ = "disabled";
            save_btn_c_ = "btn-secondary";
          }
          saved_ok = true;
          saved_text = "Geocerca guardada";
          saved_type = "alert-success";
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      }
    );
  }
  let save_btn = "disabled";
  let save_btn_c = "btn-secondary";
  let save_btn_ = "disabled";
  let save_btn_c_ = "btn-secondary";
  $: {
    if (geo_name != geo_name_org || geo_c_sel != geo_c_sel_org) {
      save_btn = "";
      save_btn_c = "btn-success";
    } else {
      save_btn = "disabled";
      save_btn_c = "btn-secondary";
    }
    if (geo_name_ != geo_name__org || geo_c_sel_ != geo_c_sel__org) {
      save_btn_ = "";
      save_btn_c_ = "btn-success";
    } else {
      save_btn_ = "disabled";
      save_btn_c_ = "btn-secondary";
    }
  }
  let inicio_bounds, fin_bounds;
  function geo_focus(event) {
    let lista;
    if (event.target.id == "t_inicio") {
      lista = inicio_bounds;
    } else {
      lista = fin_bounds;
    }
    //map.setZoo
    map.setZoom(18);
    map.panTo(lista);
  }
  function time_code(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("m[min]");
    } else {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("H[h] m[min]");
    }
  }

  let unit_filter_mode = false;
  let filter_unit;
  function unit_filter() {
    unit_filter_mode = unit_filter_mode ? false : true;
    if (unit_filter_mode) filter_unit = "";
  }
  function focus(i) {
    i.focus();
  }
  function run_unit_filter(event) {
    for (let x in units_list_p) {
      if ($nicks_list[units_list_p[x]] == event.target.value) {
        let event_ = {
          target: { id: x },
          toElement: { pathname: "/" + units_list_p[x] },
        };
        selected = x;
        maping(event_);
        unit_filter_mode = false;
        filter_unit = "";
        let unidad_l = document.getElementById(x);
        if (unidad_l != null) {
          unidad_l.scrollIntoView();
        }
      }
    }
  }

  async function osrm(coordenada) {
    // OSRM
    let coordenada_osrm = "";
    let radius_osrm = "";
    for (let t in coordenada) {
      coordenada_osrm =
        coordenada_osrm + coordenada[t].lng + "," + coordenada[t].lat + ";";
    }
    for (let t in coordenada) {
      radius_osrm = radius_osrm + "20;";
    }
    let coordenada_first = coordenada[0];
    let coordenada_last = coordenada[coordenada.length - 1];

    coordenada_osrm = coordenada_osrm.slice(0, -1);
    radius_osrm = radius_osrm.slice(0, -1);
    let osrm_r = await fetch(
      "https://route.omnitracs.online/match/v1/driving/" +
        coordenada_osrm +
        "?geometries=geojson&generate_hints=false&skip_waypoints=true&overview=full&radiuses=" +
        radius_osrm
    );
    osrm_r = await osrm_r.json();
    if (osrm_r.code == "Ok") {
      if (osrm_r.matchings[0].confidence > 0.6) {
        coordenada = [];
        for (let t in osrm_r.matchings) {
          for (let y in osrm_r.matchings[t].geometry.coordinates) {
            coordenada.push({
              lat: osrm_r.matchings[t].geometry.coordinates[y][1],
              lng: osrm_r.matchings[t].geometry.coordinates[y][0],
            });
          }
        }
        coordenada.unshift(coordenada_first);
        coordenada.push(coordenada_last);
      }
      console.log("confidence", osrm_r.matchings[0].confidence);
    }
    //---------
    if (typeof recorrido != "undefined") {
      recorrido.setMap(null);
    }
    recorrido = new google.maps.Polyline({
      path: coordenada,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2.5,
      map: map,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            strokeColor: "#b71c1c",
          },
          offset: "100px",
          repeat: "100px",
        },
      ],
    });
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<main role="main" class="container-fluid h-100 w-100 pl-0 pr-0">
  <div
    style="position: fixed; top: 5px; right: 0; color: #95A5A6;z-index:20000;"
  >
    powered by VectorGPS®
  </div>
  <div class="row h-100 no-gutters">
    <div class="col-md-auto h-100">
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100">
          <div class="card h-100 bg-light">
            <div class="d-flex flex-column h-100">
              <div
                class="card-header b-0  text-white"
                style="background-color:#80b539;font-weight:bold"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    {#if !unit_filter_mode}
                      <i class="material-icons md-85">commute</i> Equipment
                    {:else}
                      <input
                        list="lista_unidades"
                        placeholder="Unidades"
                        use:focus
                        bind:value={filter_unit}
                        on:change={run_unit_filter}
                      />
                      <datalist id="lista_unidades">
                        {#each units_list_p as item, i}
                          <option
                            value={typeof $nicks_list[item] != "undefined"
                              ? $nicks_list[item]
                              : item}
                          />{/each}
                      </datalist>
                    {/if}
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-link p-0"
                      on:click={unit_filter}
                    >
                      <i class="material-icons md-85" style="color:#fafafa"
                        >{unit_filter_mode ? "cancel" : "search"}</i
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body flex-grow-1 overflow-auto">
                <div class="list-group">
                  {#each units_list_p as item, i}
                    <a
                      id={i}
                      href="/{item}"
                      class:list-group-item-warning={selected == i}
                      class:active_={selected == i}
                      on:click|preventDefault={maping}
                      class="list-group-item list-group-item-action"
                      >{typeof $nicks_list[item] != "undefined"
                        ? $nicks_list[item]
                        : item}</a
                    >
                  {/each}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100" style="min-width:13rem">
          <div class="card h-100 bg-light ">
            <div class="d-flex flex-column h-100">
              <div
                class="card-header b-0  text-white"
                style="background-color:#80b539;font-weight:bold"
              >
                <i class="material-icons md-85">call_merge</i> Routes
              </div>
              <div
                class="card-body flex-grow-1 overflow-auto"
                bind:this={list_recorridos}
              >
                <div class="list-group">
                  {#each recorridos as item, i}
                    {#if typeof item.date_start != "undefined"}
                      <a
                        id={i}
                        href="/"
                        class:list-group-item-warning={selected_trip == i}
                        class:active_={selected_trip == i}
                        on:click|preventDefault={trip}
                        class="list-group-item list-group-item-action"
                        >{item.trip_data}<br />{item.trip_data2}</a
                      >
                    {/if}
                  {/each}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md h-100">
      <div class="row h-70 no-gutters">
        <div class="col-md-12 h-100">
          <div class="h-100" id="map" />
        </div>
      </div>
      <div class="row h-30 no-gutters">
        <div class="col-md-6 h-100">
          <div class="card h-100">
            <div class="d-flex flex-column h-100">
              <div class="card-header pt-1">
                <ul
                  class="nav nav-tabs card-header-tabs"
                  style="font-size:0.85rem"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      class:active={menu == 0}
                      on:click|preventDefault={() => (menu = 0)}
                      href="/">Speed</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      class:active={menu == 1}
                      on:click|preventDefault={() => (menu = 1)}
                      href="/">Geofences</a
                    >
                  </li>
                </ul>
              </div>
              <div class="card-body h-100 pb-md-1 flex-grow-1 overflow-auto">
                <canvas
                  id="chart1"
                  style="display: {menu == 0 ? 'block' : 'none'};"
                />
                {#if menu == 1}
                  {#if geo_name_org != ""}
                    <div class="d-inline-flex pt-2 pr-2">
                      <label for="geo_name" class="col col-form-label"
                        >Inicio</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="t_inicio"
                        placeholder=""
                        bind:value={geo_name}
                        on:focusin={geo_focus}
                        size="30"
                      />
                      <select
                        class="form-control ml-3"
                        id="geo_class"
                        bind:value={geo_c_sel}
                      >
                        <option value={1}>Sin Clasificar</option>
                        <option value={2}>Inicio/Fin de Viaje</option>
                        <option value={3}>Parada Autorizada</option>
                        <option value={4}> Parada No Autorizada</option>
                      </select>
                      <button
                        type="button"
                        on:click={save_geo}
                        id="inicio"
                        class="btn ml-3 {save_btn_c}"
                        disabled={save_btn}>Guardar</button
                      >
                    </div>
                  {/if}
                  {#if geo_name__org != ""}
                    <div class="d-inline-flex pt-2 pr-2">
                      <label for="geo_name_" class="col col-form-label"
                        >Fin</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="t_fin"
                        placeholder=""
                        bind:value={geo_name_}
                        on:focusin={geo_focus}
                        size="30"
                      />
                      <select
                        class="form-control ml-3"
                        id="geo_class_"
                        bind:value={geo_c_sel_}
                      >
                        <option value={1}>Sin Clasificar</option>
                        <option value={2}>Inicio/Fin de Viaje</option>
                        <option value={3}>Parada Autorizada</option>
                        <option value={4}> Parada No Autorizada</option>
                      </select>
                      <button
                        type="button"
                        on:click={save_geo}
                        id="fin"
                        class="btn ml-3 {save_btn_c_}"
                        disabled={save_btn_}>Guardar</button
                      >
                    </div>
                  {/if}
                {/if}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md h-100">
          <div class="card h-100">
            <div class="card-body h-100 pr-md-0">
              <div class="tableFixHead h-100 ">
                <table class="table table-striped table-hover table-sm ">
                  <thead class="thead-dark">
                    <tr>
                      <th
                        scope="col"
                        style="border-color:#80b539;background-color:#80b539;font-weight:bold"
                        >#</th
                      >
                      <th
                        scope="col"
                        style="border-color:#80b539;background-color:#80b539;font-weight:bold"
                        >Stops</th
                      >
                      <th
                        scope="col"
                        style="border-color:#80b539;background-color:#80b539;font-weight:bold"
                        >Start</th
                      >
                      <th
                        scope="col"
                        style="border-color:#80b539;background-color:#80b539;font-weight:bold"
                        >End</th
                      >
                    </tr>
                  </thead>
                  <tbody>
                    {#each stops_table as item, i}
                      <tr
                        on:click={() => {
                          map.setZoom(18);
                          map.panTo({
                            lat: parseFloat(item.pos.split(",")[0]),
                            lng: parseFloat(item.pos.split(",")[1]),
                          });
                        }}
                      >
                        <th scope="row">{i + 1}</th>
                        <td>{item.tiempo}</td>
                        <td>{item.date}</td>
                        <td>{item.date2}</td>
                      </tr>
                    {/each}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 h-100" style="min-width:16rem">
          <div class="card h-100 ">
            <div class="d-flex flex-column h-100">
              <div
                class="card-header  text-white font-weight-bold card-header-small"
                style="background-color:#80b539;font-weight:bold"
              >
                Route data
              </div>
              <div
                class="card-body  pl-md-3 pr-md-3 pt-md-2 flex-grow-1 overflow-auto h-100"
              >
                <p>Total Distance: {Math.round(total_dist)} Km</p>
                <p>Stops: {stops.length}</p>
                <p>Speed: {Math.round(vel_prom)} km/h</p>
                <p>Parked: {time_code(t_stop)}</p>
                <p>
                  Moving: {t_duracion - t_stop > 0
                    ? time_code(t_duracion - t_stop)
                    : 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .h-30 {
    height: 30%;
  }
  .h-70 {
    height: 70%;
  }
  .h-49 {
    height: 49%;
  }
  .h-2 {
    height: 2%;
  }
  .card-header-small {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .card {
    border: 0px;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  p {
    margin-block-end: 0.5rem;
  }
  .tableFixHead {
    overflow-y: auto;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }

  .form-control {
    font-size: 0.85rem;
  }
  .btn {
    font-size: 0.85rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
