<script>
  export let socket_query;
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    travels_list,
    poli,
    map_ready,
    group,
  } from "./stores.js";
  onDestroy(() => {
    console.log("destroyed");
    socket_query.off("driverVideoList");
    socket_query.off("rtc_candidate");
    socket_query.off("rtc_offer");
    if (timer_check_list) clearInterval(timer_check_list);
  });
  afterUpdate(() => {
    let video_list_element = document.getElementById("video_list");
    if (video_list_element && update_scroll) {
      update_scroll = false;
      video_list_element.scrollTop = video_list_element.scrollHeight;
    }
  });
  let max_local_videos = localStorage.getItem("max_local_videos") || 500;
  let selected = -1;
  let transfer_status = 0; // 0: idle, 1: sending, 2: receiving 3: error
  let video_list_status = 0; // 0: idle, 1: requesting, 2: received, 3: error
  let timer_check_list;
  let max_try = 2;
  let video_list = [];
  let update_scroll = false;
  function maping(i, force = false) {
    let video = document.getElementById("video");
    video.src = "";
    show_grid = false;
    selected = i;
    video_list = [];
    if (timer_check_list) clearInterval(timer_check_list);
    break_change_video = true;
    socket_query.emit("rtc_getVideoList", $drivers_list[i][0]);
    timer_check_list = setInterval(async () => {
      if (video_list.length > 0) {
        clearInterval(timer_check_list);
        video_list_status = 2;
      }
      if (max_try-- <= 0) {
        clearInterval(timer_check_list);
        let keys = await obtenerKeys("videos", "recordings");
        keys = keys.filter((key) => key.includes($drivers_list[i][0]));
        video_list = keys.map((key) => {
          let parts = key.split("|");
          return { id: parts[1], label: parts[2], local: true };
        });
        video_list_status = 3;
        let max_img = 20;
        let c = 0;
        for (let x in video_list) {
          const base64Image = await getFirstFrameFromIndexedDB(
            $drivers_list[i][0] +
              "|" +
              video_list[x].id +
              "|" +
              video_list[x].label
          );

          // Actualizar la lista de videos con la nueva fuente de imagen
          video_list = video_list.map((video) => {
            if (video.id === video_list[x].id) {
              video.src = base64Image;
            }
            return video;
          });

          // Renderizar la cuadrícula de imágenes después de actualizar la lista
          if (max_img > 0) {
            renderImageGrid(
              JSON.parse(JSON.stringify(video_list)),
              document.getElementById("video_grid")
            );
          }
          c++;
          if (document.getElementById("grid-progress-line"))
            document.getElementById("grid-progress-line").style.width =
              Math.round((c / video_list.length) * 100) + "%";
          max_img--;
        }
        renderImageGrid(
          JSON.parse(JSON.stringify(video_list)),
          document.getElementById("video_grid")
        );
      }
      socket_query.emit("rtc_getVideoList", $drivers_list[i][0]);
    }, 4000);
  }
  let break_change_video = false;
  socket_query.on("driverVideoList", async (data) => {
    clearInterval(timer_check_list);
    if (!break_change_video) return;
    if (data.driver == $drivers_list[selected][0]) {
      break_change_video = false;
      let keys = await obtenerKeys("videos", "recordings");
      keys = keys.filter((key) => key.includes($drivers_list[selected][0]));
      // Change keys to dictionary
      let keys_dict = {};
      keys.forEach((key) => {
        let parts = key.split("|");
        keys_dict[parts[2]] = true;
      });
      // add local videos to list
      video_list = data.list;
      video_list_status = 2;
      let max_img = 20;
      let c = 0;
      for (let item of data.list) {
        if (keys_dict[item.label]) item.local = true;
        else item.local = false;
        if (item.local) {
          const base64Image = await getFirstFrameFromIndexedDB(
            $drivers_list[selected][0] + "|" + item.id + "|" + item.label
          );
          //update_scroll = true;
          item.src = base64Image;
          if (max_img > 0) {
            renderImageGrid(
              JSON.parse(JSON.stringify(data.list)),
              document.getElementById("video_grid")
            );
          }
          video_list.map((video) => {
            if (video.id === item.id) {
              video.src = base64Image;
            }
            return video;
          });
          max_img--;
        }
        if (break_change_video) {
          show_grid = false;
          break;
        }
        if (
          item.id == data.list[data.list.length - 1].id &&
          now_playing == -1
        ) {
          update_scroll = true;
        }
        video_list = [...video_list];
        c++;
        if (document.getElementById("grid-progress-line"))
          document.getElementById("grid-progress-line").style.width =
            Math.round((c / data.list.length) * 100) + "%";
      }
      video_list = [...video_list];
      renderImageGrid(
        JSON.parse(JSON.stringify(video_list)),
        document.getElementById("video_grid")
      );
    }
  });
  async function saveFileToIndexedDB(
    dbName,
    storeName,
    fileBlob,
    fileName,
    date
  ) {
    const openRequest = indexedDB.open(dbName);
    openRequest.onupgradeneeded = (e) => {
      const db = e.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "name" });
      }
    };
    openRequest.onsuccess = () => {
      const db = openRequest.result;
      const tx = db.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
      store.put({
        name: fileName,
        date,
        data: fileBlob,
        date_download: new Date().getTime(),
      });
      console.log("Archivo guardado en IndexedDB");
      tx.oncomplete = () => {
        const tx2 = db.transaction(storeName, "readwrite");
        const store2 = tx2.objectStore(storeName);
        const getAll = store2.getAll();
        getAll.onsuccess = () => {
          const files = getAll.result;
          if (files.length > max_local_videos) {
            const smallest = files.reduce((a, b) =>
              a.date_download < b.date_download ? a : b
            );
            store2.delete(smallest.name).onsuccess = () => {
              console.log(
                `Archivo con key ${smallest.name} eliminado para mantener el límite`
              );
            };
            try {
              localStorage.removeItem("thumbnail_" + smallest.name);
            } catch (e) {
              console.log("Error removing thumbnail from localstorage", e);
            }
          }
        };
      };
    };
    openRequest.onerror = (e) =>
      console.error("Error al abrir IndexedDB", e.target.error);
  }

  async function getFileFromIndexedDB(dbName, storeName, key) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);
      request.onsuccess = (event) => {
        const db = event.target.result;
        // Verificar si el almacén existe
        if (!db.objectStoreNames.contains(storeName)) {
          db.close(); // Cerrar antes de eliminar
          console.warn(
            `El almacén "${storeName}" no existe. Eliminando la base de datos "${dbName}"...`
          );
          // Eliminar la base de datos
          const deleteRequest = indexedDB.deleteDatabase(dbName);
          deleteRequest.onsuccess = () => resolve(null); // Retorna null después de eliminar
          deleteRequest.onerror = () =>
            reject(`Error eliminando la base de datos "${dbName}"`);
          return;
        }
        const transaction = db.transaction(storeName, "readonly");
        const store = transaction.objectStore(storeName);
        const getRequest = store.get(key);
        getRequest.onsuccess = () => {
          db.close(); // Cerrar la base de datos después de la operación
          resolve(getRequest.result ? getRequest.result.data : null);
        };
        getRequest.onerror = () => {
          db.close(); // Cerrar en caso de error
          reject("Error obteniendo el archivo");
        };
      };

      request.onerror = () => reject("Error abriendo IndexedDB");
    });
  }

  async function obtenerKeys(nombreDB, storeName) {
    try {
      const db = await new Promise((resolve, reject) => {
        const req = indexedDB.open(nombreDB);
        req.onsuccess = () => resolve(req.result);
        req.onerror = () => reject(req.error);
      });

      if (!db.objectStoreNames.contains(storeName)) {
        db.close();
        return [];
      }

      const keys = await new Promise((resolve, reject) => {
        const trans = db.transaction(storeName, "readonly");
        const store = trans.objectStore(storeName);
        const req = store.getAllKeys();
        req.onsuccess = () => resolve(req.result);
        req.onerror = () => reject(req.error);
      });

      db.close();

      // Ordenar las claves según el segundo valor numérico
      const sortedKeys = keys.sort((a, b) => {
        const aParts = a.split("|");
        const bParts = b.split("|");

        const aValue = parseInt(aParts[1], 10);
        const bValue = parseInt(bParts[1], 10);

        return aValue - bValue;
      });

      return sortedKeys;
    } catch {
      return [];
    }
  }

  // WEBRTC
  let peerConnection;
  const turnConfig = {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
      {
        urls: "turn:portainer.sub.omnitracs.online:3478",
        username: "alfa",
        credential: "js1ma",
      },
    ],
  };
  let driver_id, video_id, video_date;
  socket_query.on("rtc_candidate", async function (data) {
    //console.log("Recibido candidato ICE:", data);
    if (peerConnection.remoteDescription)
      await peerConnection.addIceCandidate(new RTCIceCandidate(data));
  });
  socket_query.on("rtc_offer", async function (data) {
    console.log("Recibida oferta:", data);
    if (peerConnection) {
      peerConnection.close();
    }
    peerConnection = new RTCPeerConnection(turnConfig);
    peerConnection.ontrack = (event) => {
      console.log("track", event.track.kind);
    };

    let receivedBuffers = [];
    peerConnection.ondatachannel = (event) => {
      console.log("Data channel received:", event);
      const sendChannel = event.channel;
      sendChannel.onopen = () => {
        console.log("Data channel is open and ready to be used.");
        sendChannel.send("Video," + video_id);
        transfer_status = 2;
      };
      sendChannel.onmessage = (event) => {
        console.log("Data channel message received:", event.data);
        if (event.data instanceof ArrayBuffer) {
          receivedBuffers.push(event.data);
        } else {
          if (event.data == "end") {
            socket_query.emit("rtc_disconnect", driver_id);
            // Check if any of receivedBuffers is empty
            if (receivedBuffers.some((buffer) => buffer.byteLength === 0)) {
              console.log("Received empty buffers");
              transfer_status = 3;
              return;
            }
            const blob = new Blob(receivedBuffers, { type: "video/webm" });
            // Get video element and set the source
            let video = document.getElementById("video");
            video.src = URL.createObjectURL(blob);
            video.play();
            transfer_status = 0;
            saveFileToIndexedDB(
              "videos",
              "recordings",
              blob,
              $drivers_list[selected][0] + "|" + video_id + "|" + video_date,
              video_date
            );
            // chage video list to add tag local
            let index = video_list.findIndex((item) => item.id == video_id);
            video_list[index].local = true;
            // add thumbnail
            getFirstFrameFromIndexedDB(
              $drivers_list[selected][0] + "|" + video_id + "|" + video_date
            ).then((base64Image) => {
              video_list[index].src = base64Image;
            });
            video_list = [...video_list];
          }
        }
      };
    };

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        console.log("Enviando candidato ICE:", event.candidate);
        socket_query.emit("rtc_candidate_admin", driver_id, event.candidate);
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      console.log(
        "ICE connection state change:",
        peerConnection.iceConnectionState
      );
      if (peerConnection.iceConnectionState === "connected") {
      }
    };

    await peerConnection.setRemoteDescription(new RTCSessionDescription(data));
    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);

    console.log("Enviando respuesta:", answer);
    socket_query.emit("rtc_answer", driver_id, peerConnection.localDescription);
  });

  let now_playing = -1;
  let download_on_progress = false;
  async function getFirstFrameFromIndexedDB(videoName) {
    try {
      // Buscar en IndexedDB si ya existe la imagen
      const existingBase64 = await getBase64FromIndexedDB(videoName);
      if (existingBase64) {
        return existingBase64; // Retornar la imagen si ya está almacenada
      }
    } catch (error) {
      console.error("Error al buscar en IndexedDB:", error);
    }
    console.log("Obteniendo primer cuadro del video:", videoName);
    return new Promise((resolve, reject) => {
      // Abrir conexión con IndexedDB
      const request = indexedDB.open("videos");

      request.onerror = () => reject("Error al abrir la base de datos");

      request.onsuccess = (event) => {
        const db = event.target.result;

        // Verificar si el almacén recordings existe
        if (!db.objectStoreNames.contains("recordings")) {
          db.close();
          return reject("No existe el almacén recordings en IndexedDB");
        }

        const transaction = db.transaction(["recordings"], "readonly");
        const store = transaction.objectStore("recordings");
        const getRequest = store.get(videoName);

        getRequest.onsuccess = async () => {
          const record = getRequest.result;
          if (!record || !record.data) {
            return reject("Video no encontrado");
          }

          const blob = record.data;
          const videoURL = URL.createObjectURL(blob);
          const video = document.createElement("video");

          video.src = videoURL;
          video.crossOrigin = "anonymous";
          video.style.display = "none";
          video.muted = true; // Evitar bloqueos por políticas del navegador
          document.body.appendChild(video);

          video.addEventListener("loadedmetadata", () => {
            video.currentTime = 0; // Tomar el primer cuadro
          });

          video.addEventListener("seeked", () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Convertir el canvas a base64
            const base64Image = canvas.toDataURL("image/png");

            // Limpiar
            URL.revokeObjectURL(videoURL);
            document.body.removeChild(video);

            // Guardar en IndexedDB
            saveBase64ToIndexedDB(base64Image, videoName);

            resolve(base64Image);
          });
          video.addEventListener("error", () =>
            reject("Error al procesar el video")
          );
        };
        getRequest.onerror = () => reject("Error al obtener el video");
      };
    });
  }

  let show_grid = false;
  function renderImageGrid(videos, container) {
    if (!container) {
      console.error("El contenedor no existe");
      return;
    }

    // Obtener array de imágenes en base64 en base a src
    let base64Images = [];
    for (let x in videos) {
      if (videos[x].src) {
        base64Images.push([videos[x].src, videos[x].id]);
      }
    }

    const totalImages = base64Images.length;
    if (totalImages === 0) return;

    // Obtener el tamaño del contenedor
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    // Calcular cuántas columnas y filas son necesarias
    const cols = Math.ceil(Math.sqrt(totalImages)); // Redondear hacia arriba para tener suficiente espacio
    const rows = Math.ceil(totalImages / cols);

    // Calcular tamaño de cada imagen
    const imgWidth = containerWidth / cols;
    const imgHeight = containerHeight / rows;

    // Establecer estilos del contenedor
    //container.style.display = "grid";
    show_grid = true;
    container.style.gridTemplateColumns = `repeat(${cols}, 1fr)`;
    container.style.gridTemplateRows = `repeat(${rows}, 1fr)`;
    container.style.width = "100%";
    container.style.height = "100%";
    container.style.gap = "0"; // Sin espacios entre las imágenes

    // Limpiar el contenedor
    container.innerHTML = "";

    // Crear y agregar imágenes
    let img_list = [];
    base64Images.forEach((base64) => {
      const img = document.createElement("img");
      if (!base64[0]) console.log("Error");
      img.src = base64[0];
      img.style.width = "100%";
      img.style.height = "100%";
      img.style.objectFit = "cover"; // Ajustar la imagen para llenar el espacio sin deformarse
      img.style.border = "1px solid white"; // Borde blanco
      img.style.cursor = "pointer"; // Cambiar el cursor al pasar sobre la imagen
      img.onclick = () => {
        ensureElementVisible("#video_list", "video_" + base64[1]);
        let video = document.getElementById("video_" + base64[1]);
        video.click();
      };
      img_list.push(img);
      //container.appendChild(img);
    });
    container.append(...img_list);
    // add  to cointainer this images: <div style="position: absolute;bottom: 0;left: 0;height: 4px;background-color: #007bff;width: 0%;"></div>
    const progressLine = document.createElement("div");
    progressLine.style.position = "absolute";
    progressLine.style.bottom = "0";
    progressLine.style.left = "0";
    progressLine.style.height = "6px";
    progressLine.style.backgroundColor = "rgb(255 165 0)";
    progressLine.style.width = "0%";
    progressLine.id = "grid-progress-line";
    img_list.push(progressLine);
    container.append(...img_list);
  }
  function ensureElementVisible(container, elementId) {
    const containerElement = document.querySelector(container);
    const targetElement = document.getElementById(elementId);

    if (!containerElement || !targetElement) {
      console.warn("Container or target element not found.");
      return;
    }

    const containerRect = containerElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();

    const isVisible =
      targetRect.top >= containerRect.top &&
      targetRect.bottom <= containerRect.bottom;

    if (!isVisible) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }
  function saveBase64ToIndexedDB(base64, label) {
    const request = indexedDB.open("videos_img", 1);

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("img")) {
        db.createObjectStore("img");
        // Aquí se crea el almacén con la clave por defecto (keyPath = undefined)
        // Se usará (key, value) => (label, base64) al hacer store.put(base64, label).
      }
    };

    request.onsuccess = function (event) {
      const db = event.target.result;
      const tx = db.transaction("img", "readwrite");
      const store = tx.objectStore("img");

      // Guardamos la imagen con la 'label' como clave
      store.put(base64, label);

      // Una vez que termina la transacción de escritura
      tx.oncomplete = function () {
        // Abrimos otra transacción en modo readwrite para comprobar el número de registros
        const checkTx = db.transaction("img", "readwrite");
        const checkStore = checkTx.objectStore("img");

        const countRequest = checkStore.count();
        countRequest.onsuccess = function () {
          const total = countRequest.result;
          if (total > max_local_videos) {
            // Si nos pasamos del máximo, eliminamos el primer registro (clave más baja)
            const cursorRequest = checkStore.openCursor(); // abre en modo ascendente (por clave)
            cursorRequest.onsuccess = function (e) {
              const cursor = e.target.result;
              if (cursor) {
                checkStore.delete(cursor.key);
                console.log(
                  `Se ha eliminado la key más baja para no superar las ${max_local_videos} entradas.`
                );
              }
            };
          }
        };

        checkTx.oncomplete = function () {
          console.log(
            "Proceso de verificación y posible eliminación completado."
          );
        };
        checkTx.onerror = function (err) {
          console.error(
            "Error al verificar o eliminar el registro más antiguo:",
            err
          );
        };
      };

      tx.onerror = function (err) {
        console.error("Error al guardar la imagen en IndexedDB:", err);
      };
    };

    request.onerror = function (err) {
      console.error("Error al abrir/crear la base de datos:", err);
    };
  }
  function getBase64FromIndexedDB(label) {
    return new Promise((resolve) => {
      const request = indexedDB.open("videos_img", 1);

      // Si no se puede abrir la base de datos, resolvemos null
      request.onerror = function () {
        resolve(null);
      };

      // Se ejecuta solo si la base no existe o hay un cambio de versión
      request.onupgradeneeded = function (event) {
        const db = event.target.result;
        // Crea el object store si no existe
        if (!db.objectStoreNames.contains("img")) {
          db.createObjectStore("img");
        }
      };

      request.onsuccess = function (event) {
        const db = event.target.result;

        // Verificamos si el store "img" existe, por seguridad
        if (!db.objectStoreNames.contains("img")) {
          resolve(null);
          return;
        }

        const tx = db.transaction("img", "readonly");
        const store = tx.objectStore("img");
        const getRequest = store.get(label);

        // Si hay error al leer, resolvemos null
        getRequest.onerror = function () {
          resolve(null);
        };

        getRequest.onsuccess = function () {
          // result será undefined si no existe la clave en la DB
          const result = getRequest.result;
          resolve(result || null);
        };
      };
    });
  }
</script>

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div
            class="card-header bg-dark text-white b-0"
            style="padding-right: 0.2rem;"
          >
            <div class="d-flex">
              <div class="flex-grow-1">
                <div class="flex-grow-1">
                  <i
                    tooltip="Operador"
                    class="material-icons md-90"
                    style="margin-right: 5px;">videocam</i
                  > Videos de Choferes
                </div>
              </div>
            </div>
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each $drivers_list as item, i}
                <a
                  href="/{item[0]}"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={() => {
                    video_list_status = 1;
                    max_try = 2;
                    now_playing = -1;
                    maping(i, true);
                  }}
                  class="list-group-item list-group-item-action"
                  style="display: flex;justify-content: space-between;align-items: center;"
                >
                  <div>
                    {typeof $drivers[item[0]].name != "undefined"
                      ? $drivers[item[0]].name
                      : item}
                  </div>
                </a>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 h-100" style="display: flex;flex-direction: column;">
      <div
        style="display:flex;align-items: center;padding-right: 10px;"
        class="card-header bg-dark text-white b-0"
      >
        <i
          tooltip="Operador"
          class="material-icons md-90"
          style="margin-right: 5px;"
        >
          videocam
        </i>
        Videos
        {#if video_list.length > 0 && video_list_status != 3}
          <!--Boton para descargar todos los videos-->
          <button
            class="btn btn-primary btn-sm"
            style="margin-left: auto;padding: 1px 5px;"
            on:click|preventDefault={async () => {
              download_on_progress = !download_on_progress;
              for (let i = 0; i < video_list.length; i++) {
                if (!download_on_progress) break;
                driver_id = $drivers_list[selected][0];
                video_id = video_list[i].id;
                video_date = video_list[i].label;
                let video = await getFileFromIndexedDB(
                  "videos",
                  "recordings",
                  driver_id + "|" + video_id + "|" + video_date
                );
                console.log("Video from IndexedDB:", video);
                now_playing = i;
                ensureElementVisible("#video_list", "video_" + video_id);
                if (video) {
                  let video_element = document.getElementById("video");
                  video_element.src = URL.createObjectURL(video);
                  //video_element.play();
                  continue;
                }
                socket_query.emit(
                  "rtc_requestOffer",
                  $drivers_list[selected][0],
                  "file"
                );
                transfer_status = 1;
                await new Promise((resolve) => {
                  let timer = setInterval(() => {
                    if (transfer_status == 0 || transfer_status == 3) {
                      clearInterval(timer);
                      resolve();
                    }
                  }, 1000);
                });
              }
              download_on_progress = false;
              // simulate click on drvivers list
              let driver = document.querySelector(
                ".list-group-item-action.active_"
              );
              if (driver) driver.click();
            }}
          >
            <i class="material-icons" style="font-size: 20px;">
              {download_on_progress ? "stop" : "cloud_download"}
            </i>
            {!download_on_progress ? "Descargar" : "Detener"}
          </button>
        {/if}
      </div>
      <div
        style="flex-grow:1;overflow:auto;height: 100%;border: 0.1px solid #ffeeba87;"
        id="video_list"
      >
        {#if video_list_status == 3 && video_list.length > 0}
          <div
            class="alert alert-danger"
            role="alert"
            style="display: flex;justify-content: center;text-align: center;margin-bottom:0;"
          >
            <i class="material-icons md-24">error</i> Conductor no disponible, mostrando
            videos locales
          </div>
        {/if}
        {#each video_list as item, i}
          <div
            id="video_{item.id}"
            class="list-group-item list-group-item-action progress-line-container"
            style="display: flex;justify-content: space-evenly;align-items: center;cursor: pointer;min-height: 76px;{now_playing ==
            i
              ? 'background-color: cornsilk;'
              : ''}"
            on:click|preventDefault={async () => {
              if (transfer_status == 3) transfer_status = 0;
              show_grid = false;
              driver_id = $drivers_list[selected][0];
              video_id = item.id;
              video_date = item.label;
              let video = await getFileFromIndexedDB(
                "videos",
                "recordings",
                driver_id + "|" + video_id + "|" + video_date
              );
              //console.log("Video from IndexedDB:", video);
              now_playing = i;
              if (video) {
                let video_element = document.getElementById("video");
                video_element.src = URL.createObjectURL(video);
                video_element.play();
                return;
              }
              socket_query.emit(
                "rtc_requestOffer",
                $drivers_list[selected][0],
                "file"
              );
              transfer_status = 1;
            }}
          >
            {#if item.local}
              <div>
                {#if item.src}
                  <img
                    src={item.src}
                    id="thumbnail_{item.id}"
                    style="width: 50px;height: 50px;"
                  />
                {:else}
                  <i
                    class="material-icons"
                    style="color: cadetblue;font-size:47px;">save</i
                  >
                {/if}
              </div>
            {:else}
              <i
                class="material-icons"
                style="color: cadetblue;font-size:47px;"
              >
                cloud_download
              </i>
            {/if}
            <div style="margin-left: 5px;text-align: center;">
              {item.label.replace(",", " ")}
            </div>
            {#if now_playing == i}
              <div class="progress-line"></div>
            {/if}
          </div>
        {/each}
        {#if video_list_status == 2 && selected != -1 && video_list.length == 0}
          <!--Selecione un operador en linea -->
          <div
            style="display: flex;height: 100%;flex-direction: column;justify-content: center;padding: 10px 20px;border: 0.1px solid #ffeeba87;"
          >
            <div
              class="alert alert-primary"
              role="alert"
              style="text-align: center;text-align: center;"
            >
              <i class="material-icons md-24">warning</i> No hay videos disponibles
              para este operador
            </div>
          </div>
        {/if}
        {#if video_list_status == 0 && selected == -1}
          <!--Selecione un operador en linea -->
          <div
            style="display: flex;height: 100%;flex-direction: column;justify-content: center;padding: 10px 20px;border: 0.1px solid #ffeeba87;"
          >
            <div
              class="alert alert-warning"
              role="alert"
              style="text-align: center;text-align: center;"
            >
              <i class="material-icons md-24">warning</i> Seleccione un operador
              en línea para obtener lista de videos
            </div>
          </div>
        {/if}
        {#if video_list_status == 1 && selected != -1}
          <!--Selecione un operador en linea -->
          <div
            style="display: flex;height: 100%;flex-direction: column;justify-content: center;padding: 10px 20px;border: 0.1px solid #ffeeba87;"
          >
            <div
              class="alert alert-warning"
              role="alert"
              style="display: flex;justify-content: space-between;"
            >
              <div style="align-self: center;text-align: center;">
                <i class="material-icons md-24">warning</i> Solicitando lista
              </div>
              <div>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        {/if}
        {#if video_list_status == 3 && selected != -1 && video_list.length == 0}
          <!--Timeout para solicitud-->
          <div
            style="display: flex;height: 100%;flex-direction: column;justify-content: center;padding: 10px 20px;border: 0.1px solid #ffeeba87;"
          >
            <div class="alert alert-danger" role="alert" style="display: flex;">
              <div style="align-self: center;text-align: center;">
                <i class="material-icons md-24">error</i> Conductor no disponible
              </div>
            </div>
          </div>
        {/if}
      </div>
    </div>
    <div class="col-8 h-100" style="display: flex;flex-direction: column;">
      <div
        id="video_grid"
        style="overflow: auto;height: 100%;display: {show_grid &&
        transfer_status == 0 &&
        now_playing == -1
          ? 'grid'
          : 'none'};"
      ></div>
      <!--Show video -->
      <video
        id="video"
        controls
        autoplay
        style="flex-grow: 1;max-height: 100%;{transfer_status != 0 ||
        (selected != -1 &&
          now_playing == -1 &&
          video_list.length != 0 &&
          show_grid == true)
          ? 'display: none;'
          : ''}"
      ></video>
      {#if transfer_status != 0}
        <div
          style="height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;"
        >
          <div
            class="alert alert-{transfer_status == 3 ? 'danger' : 'warning'}"
            role="alert"
            style="width: 300px;"
          >
            <div class="d-flex justify-content-between">
              <div style="align-self: center;">
                <i class="material-icons md-24"
                  >{transfer_status == 1
                    ? "cloud_upload"
                    : transfer_status == 3
                      ? "error"
                      : "cloud_download"}</i
                >
                {transfer_status == 1
                  ? "Enviando solicitud"
                  : transfer_status == 3
                    ? "Video dañado o no disponible"
                    : "Recibiendo video"}
              </div>
              <div>
                {#if transfer_status != 3}
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      {/if}
    </div>
  </div>
</main>

<style>
  .btn {
    font-size: 0.75rem;
    padding: 0.1rem, 0.1rem;
    line-height: 0.7;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .c100.big {
    font-size: 11vw;
  }
  .c100 {
    margin: 0;
  }
  .c100 > span {
    width: 2.5em;
    line-height: 2.5em;
    font-size: 0.4em;
  }
  .badge {
    font-size: 1.5rem;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
  th.dato-general,
  td.dato-general {
    text-align: start;
    vertical-align: middle;
    font-size: 0.9rem;
    padding-left: 0.75rem;
  }
  /* HOS */
  .charts-container {
    height: 50%;
    display: flex;
    max-height: 200px;
  }
  .chart-item {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .chart-label {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 21px;
  }
  .chart-label-container {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  th,
  td {
    vertical-align: middle;
  }
  .table thead th {
    vertical-align: middle;
  }
  /* Contenedor para la línea de progreso */
  .progress-line-container {
    position: relative; /* Necesario para posicionar la línea internamente */
    padding-bottom: 12px; /* Espacio al final para que se vea la línea */
  }

  /* Línea de progreso */
  .progress-line {
    position: absolute;
    bottom: 0; /* Se sitúa al fondo del contenedor */
    left: 0;
    width: 0; /* Inicia en 0 */
    height: 4px; /* Grosor de la línea */
    background-color: #007bff; /* Color principal de Bootstrap */
    animation: fillLine 10s forwards; /* La animación dura 5s y se mantiene */
  }

  /* Definición de la animación */
  @keyframes fillLine {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
</style>
